/* eslint-disable react/jsx-props-no-spreading */
// @ts-nocheck
import React, { useMemo } from "react";
import { useParams, useMatch } from "react-router-dom";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import { useGetAfrica360ViewBanjulPropertiesQuery } from "graphql/hasura/types-and-hooks";
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";

// tabs

// Utils
import { UIError, UIErrorCodes, useAppErrorHandler } from "errors/app.errors";
import PropertiesTab from "./properties-tab.component";

export interface IndividualTabRouteParams {
  repId: string;
}

const PropertiesTabPage: React.FC = () => {
  const { repId } = useParams<{ repId: string }>();
  const match = useMatch("*");
  const path = match?.pathname ?? "";
  const pathArr = path.split("/");
  pathArr.pop();

  const queryVariablesRMatchProperties: Query_RootRtcs_Db_Rpt_PropertiesCompositeArgs =
    {
      where: repId?.startsWith("PRP")
        ? { Rnid: { _eq: repId } }
        : { Uuid: { _like: `%${repId}%` } },
    };

  const { data: rMatchPropertiesData, error: rMatchPropertiesError } =
    useGetAfrica360ViewBanjulPropertiesQuery({
      context: { clientName: CLIENT_NAME.HASURA },
      variables: {
        params: jsonToStringQueryVariables(
          queryVariablesRMatchProperties ?? {},
        ),
      },
    });

  const rMatchProperties = useMemo(() => {
    return rMatchPropertiesData &&
      rMatchPropertiesData.rtcs_gm_dev_Fnafrica360view_Africa360ViewBanjul
        .length > 0
      ? rMatchPropertiesData.rtcs_gm_dev_Fnafrica360view_Africa360ViewBanjul[0]
      : undefined;
  }, [rMatchPropertiesData]);

  const handleError = useAppErrorHandler(rMatchPropertiesError);

  React.useEffect(() => {
    if (
      rMatchPropertiesData &&
      rMatchPropertiesData.rtcs_gm_dev_Fnafrica360view_Africa360ViewBanjul
        .length === 0
    ) {
      handleError(
        new UIError(
          UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
          `Selection xx doesn't exist.`,
        ),
      );
    }
  }, [handleError, rMatchPropertiesData]);

  if (rMatchProperties) {
    return <PropertiesTab rMatchdata={rMatchProperties} />;
  }
  return <></>;
};

export default PropertiesTabPage;
