/* eslint-disable import/no-extraneous-dependencies */
// Dependencies
import React from "react";
import { Snackbar, Box, SnackbarCloseReason } from "@mui/material";
import { Reference, StoreObject } from "@apollo/client";
import _ from "lodash";

// Components
import RoleForm from "components/role-form/role-form.component";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import Table, { TableColumnDef } from "components/table/table.component";
import { Alert, AlertProps } from "components/alert/alert.component";
import { TableToolbarAction } from "components/table-toolbar-actions/table-toolbar-actions.component";
import ActionButton from "components/action-button/action-button.component";
import RoleFilteringLeftPane from "components/role-filtering-left-pane/role-filtering-left-pane.component";
import Breadcrumb from "components/breadcrumb/breadcrumb.component";
import {
  useAppPermissionValidator,
  AppPermissionValidator,
  APP_PERMISSION,
} from "components/app-permission-validator/app-permission-validator.component";
import DataPermissionsApplicator from "components/data-permissions-applicator/data-permissions-applicator.component";

// GraphQL
import {
  RoleData,
  useGetRoleQuery,
  useAddRoleMutation,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
  Maybe,
  PermissionInput,
  Job,
  DagsterPipelineRunStatus,
} from "graphql/types-and-hooks";
import { createEmptyRoleData, NEW_ROLE_FRAGMENT_GQL } from "graphql/rbac.utils";
import useJobStatus from "jobs/useJobStatus";

// Utils
import FormattedValue from "components/formatted-value/formatted-value";
import {
  useAppErrorHandler,
  isServerError,
  UIError,
  UIErrorCodes,
} from "errors/app.errors";

// Assets
import { Delete as DeleteIcon } from "@mui/icons-material";

import SC from "./role-management.styles";

const RoleManagementPage: React.FC = () => {
  const [openAddRoleForm, setOpenAddRoleForm] = React.useState(false);
  const {
    data: rolesList,
    loading: rolesDataLoading,
    error: errorRolesDataLoading,
  } = useGetRoleQuery({
    variables: {},
  });
  const roles = React.useMemo(() => rolesList?.GetRole ?? [], [rolesList]);
  const [filteredRoles, setFilteredRoles] = React.useState<RoleData[]>();

  const [addRole, { loading: addRoleLoading }] = useAddRoleMutation({
    update(cache, { data }) {
      const newRole = data?.AddRole[0];
      if (newRole) {
        cache.modify({
          fields: {
            GetRole(existingRoles = []) {
              const newRoleRef = cache.writeFragment({
                id: cache.identify(newRole),
                data: newRole,
                fragment: NEW_ROLE_FRAGMENT_GQL,
              });
              return [...existingRoles, newRoleRef];
            },
          },
        });
      }
    },
  });

  const [updateRole, { loading: updateRoleLoading }] = useUpdateRoleMutation();
  const [deleteRole, { loading: deleteRoleLoading }] = useDeleteRoleMutation({
    update(cache, { data }) {
      const roleToDeleteId = data?.DeleteRole;
      cache.modify({
        fields: {
          GetRole(existingRoles, { readField }) {
            return existingRoles.filter(
              (role: Reference | StoreObject | undefined) =>
                roleToDeleteId !== readField("id", role),
            );
          },
        },
      });
    },
  });

  const [runUpdateUsersJob, { jobStatus, jobRunning }] = useJobStatus(
    Job.UpdateUsers,
  );

  const errorHandler = useAppErrorHandler(errorRolesDataLoading);
  const loading =
    rolesDataLoading ||
    addRoleLoading ||
    deleteRoleLoading ||
    updateRoleLoading ||
    jobRunning ||
    jobStatus === DagsterPipelineRunStatus.Starting ||
    jobStatus === DagsterPipelineRunStatus.Started;
  const [snackBarMessage, setSnackBarMessage] = React.useState<AlertProps>();

  const handlerAddRoleOnSubmit = React.useCallback(
    async (roleData: RoleData, permissionsChangedList: PermissionInput[]) => {
      try {
        await addRole({
          variables: {
            name: roleData.name ?? "",
            description: roleData.description ?? "",
            visibleLevel: roleData.visibleLevel as number,
            permissionInputs: permissionsChangedList,
          },
        });

        await runUpdateUsersJob();

        setOpenAddRoleForm(false);
        setSnackBarMessage({
          message: "The role has been created successfully",
          severity: "success",
        });
      } catch (error) {
        if (isServerError(error as Error)) {
          errorHandler(error as Error);
        } else {
          errorHandler(
            new UIError(
              UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
              "An error has ocurred while creating the role",
            ),
          );
        }
      }
    },
    [addRole, errorHandler, runUpdateUsersJob],
  );

  const handlerEditRoleOnSubmit = React.useCallback(
    async (roleData: RoleData, permissionsChangedList: PermissionInput[]) => {
      try {
        await updateRole({
          variables: {
            id: roleData.id ?? "",
            name: roleData.name ?? "",
            description: roleData.description ?? "",
            visibleLevel: roleData.visibleLevel as number,
            permissionInputs: permissionsChangedList,
          },
        });

        await runUpdateUsersJob();

        setSnackBarMessage({
          message: "The role has been updated successfully",
          severity: "success",
        });
      } catch (error) {
        if (isServerError(error as Error)) {
          errorHandler(error as Error);
        } else {
          errorHandler(
            new UIError(
              UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
              "An error has ocurred while updating the role",
            ),
          );
        }
      }
    },
    [errorHandler, updateRole, runUpdateUsersJob],
  );
  const handlerDeleteRole = React.useCallback(
    async (roleId: Maybe<string> | undefined) => {
      try {
        await deleteRole({
          variables: {
            id: roleId ?? "",
          },
        });

        await runUpdateUsersJob();

        setSnackBarMessage({
          message: "The role has been deleted successfully",
          severity: "success",
        });
      } catch (error) {
        if (isServerError(error as Error)) {
          errorHandler(error as Error);
        } else {
          errorHandler(
            new UIError(
              UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
              "An error has ocurred while deleting the role",
            ),
          );
        }
      }
    },
    [deleteRole, errorHandler, runUpdateUsersJob],
  );

  const handleTableActions = (action: TableToolbarAction) => {
    if (action === "add") {
      if (openAddRoleForm) {
        setOpenAddRoleForm(false);
      } else setOpenAddRoleForm(true);
    }
  };

  const handleCloseSnack = React.useCallback(
    (
      event?: React.SyntheticEvent<any, Event> | Event,
      reason?: SnackbarCloseReason,
    ) => {
      if (reason === "clickaway") {
        return;
      }
      setSnackBarMessage(undefined);
    },
    [],
  );

  const handleCancelAddRole = () => {
    setOpenAddRoleForm(false);
  };
  const columns: TableColumnDef<RoleData>[] = React.useMemo(
    () => [
      { header: "Name", accessorKey: "name", align: "left" },
      { header: "Description", accessorKey: "description" },
      {
        header: "Creation Date",
        accessorKey: "creationDate",
        accessorFn: ({ creationDate }) => (
          <FormattedValue
            format="date"
            value={_.isNil(creationDate) ? undefined : creationDate}
          />
        ),
        align: "right",
      },
      {
        id: "actions",
        header: "Actions",
        accessorKey: "id",
        cell: ({ getValue }) => (
          <>
            <ActionButton
              key="Delete"
              title="Delete"
              icon={<DeleteIcon />}
              handler={() => handlerDeleteRole(`${getValue() as string}`)}
              displayPopover
              popoverMessage="Are you sure you want to delete this item??
You won't be able to recover them"
              popoverButtons
            />
          </>
        ),
        align: "center",
      },
    ],
    [handlerDeleteRole],
  );

  const appPermissionValidator = useAppPermissionValidator();
  const addRoleForm = openAddRoleForm ? (
    <RoleForm
      initialRoleData={createEmptyRoleData()}
      initialEditable
      onSubmit={handlerAddRoleOnSubmit}
      onCancel={handleCancelAddRole}
    />
  ) : undefined;

  return (
    <Box>
      <Breadcrumb isSimple />
      <Snackbar
        open={!!snackBarMessage}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={snackBarMessage?.severity}
          message={snackBarMessage?.message}
        />
      </Snackbar>

      <ProgressIndicator open={loading} />

      <AppPermissionValidator
        appPermission={APP_PERMISSION.ADMIN_EDIT_DATA_PERMISSIONS}
      >
        <SC.ContainerApplicator>
          <DataPermissionsApplicator />
        </SC.ContainerApplicator>
      </AppPermissionValidator>

      <Table
        title="Roles"
        persistenceId="c1e230cd-b0ef-4ecd-82d7-0b63766480c5"
        data={filteredRoles ?? (roles as RoleData[])}
        columns={columns}
        onAction={handleTableActions}
        actionsOnLeft={
          appPermissionValidator?.(APP_PERMISSION.ADMIN_ADDEDIT_USERS)
            ? ["add"]
            : undefined
        }
        actionsOnRight={["filter-results"]}
        leftPanel={
          <RoleFilteringLeftPane
            roles={(roles as RoleData[]) ?? []}
            onFilter={setFilteredRoles}
          />
        }
        topPanel={addRoleForm}
        renderExpandedRowSubComponent={(row) => (
          <RoleForm
            initialRoleData={row.original}
            initialEditable={false}
            onSubmit={handlerEditRoleOnSubmit}
            onCancel={() => null}
          />
        )}
      />
    </Box>
  );
};

export default RoleManagementPage;
