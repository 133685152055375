import React from "react";

// Components
import { WaterAndElectricityCustomersTable } from "pages/reports/nawec/water-and-electricity-customers-table/water-and-electricity-customers-table.component";

// Assets
import AccordionList from "components/accordion-list/accordion-list.component";

interface Filters {
  address: string;
  businessName: string;
  email: string;
  firstName: string;
  idNumber: string;
  phoneNumber: string;
  plusCode: string;
  surname: string;
}

interface NawecTabProps {
  defaultFilters?: Filters;
  initialRowsPerPage?: number;
  maxHeight?: number;
  noDataComponent?: JSX.Element;
}

export const NawecTab: React.FC<NawecTabProps> = React.memo(
  ({ defaultFilters, initialRowsPerPage, maxHeight, noDataComponent }) => {
    const reportSections = [
      {
        title: "National Water & Electricity Company",
        accordions: [
          {
            content: (
              <WaterAndElectricityCustomersTable
                defaultFilters={defaultFilters}
                initialRowsPerPage={initialRowsPerPage}
                maxHeight={maxHeight}
                noDataComponent={noDataComponent}
                persistenceId="9fad319e-b98c-4a8e-8158-db002c1ac7e0"
              />
            ),
            subtitle: "Water and Electricity Customers",
          },
        ],
      },
    ];

    return <AccordionList defaultExpanded sections={reportSections} />;
  }
);
