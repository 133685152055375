/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
// Dependencies
import React from "react";

// Components
import GroupFormFields from "components/group-form-fields/group-form-fields.component";
import {
  APP_PERMISSION,
  useAppPermissionValidator,
} from "components/app-permission-validator/app-permission-validator.component";

// GraphQL
import { GroupData, UserData, RoleData } from "graphql/types-and-hooks";
import { createEmptyGroupData } from "graphql/rbac.utils";

// Assets
import SC from "./group-form.styles";

type ButtonVariant = "contained" | "outlined" | "text" | undefined;
type ButtonType = "button" | "reset" | "submit" | undefined;

export interface GroupFormProps {
  initialGroupData?: GroupData;
  initialEditable: boolean;
  initialSaveActive?: boolean;
  usersList: UserData[];
  rolesList: RoleData[];
  onSubmit: (
    groupData: GroupData,
    initialUsersBelongToGroup: UserData[],
    usersBelongToGroup: UserData[],
  ) => void;
  onCancel: () => void;
}

export const GroupForm: React.FC<GroupFormProps> = ({
  initialGroupData = createEmptyGroupData,
  initialEditable,
  initialSaveActive,
  usersList,
  rolesList,
  onSubmit,
  onCancel,
}) => {
  const [groupData, setGroupData] = React.useState(initialGroupData);
  const initialUsersBelongToGroup = usersList.filter((user) =>
    user?.groups?.some((g) => g?.id === groupData?.id),
  );
  const [usersBelongToGroup, setUsersBelongToGroup] = React.useState(
    initialUsersBelongToGroup,
  );

  const [isEditable, setIsEditable] = React.useState(initialEditable);

  const handlerEditOnClick = React.useCallback(() => {
    setIsEditable(true);
  }, []);

  const handlerCancelOnClick = React.useCallback(() => {
    setIsEditable(false);
    setGroupData(initialGroupData);
    onCancel();
  }, [initialGroupData, onCancel]);

  const handleOnChange = React.useCallback((newGroupData: GroupData) => {
    setGroupData(newGroupData);
  }, []);

  const handlerOnSubmit: React.FormEventHandler = React.useCallback(
    (event) => {
      event.preventDefault();

      setIsEditable(initialEditable);
      if (initialUsersBelongToGroup !== usersBelongToGroup)
        onSubmit(groupData, initialUsersBelongToGroup, usersBelongToGroup);
    },
    [
      initialEditable,
      onSubmit,
      groupData,
      initialUsersBelongToGroup,
      usersBelongToGroup,
    ],
  );

  const handleOnUsersChange = React.useCallback(
    (newUsersBelongToGroup: UserData[]) => {
      setUsersBelongToGroup(newUsersBelongToGroup);
    },
    [],
  );

  const buttons = [
    {
      title: "Save",
      variant: "contained",
      type: "submit",
      isShown: isEditable || initialSaveActive,
    },
    {
      title: "Cancel",
      variant: "outlined",
      handler: handlerCancelOnClick,
      isShown: isEditable,
    },
  ];

  const appPermissionValidator = useAppPermissionValidator();

  if (appPermissionValidator?.(APP_PERMISSION.ADMIN_ADDEDIT_USERS)) {
    buttons.push({
      title: "Edit",
      variant: "outlined",
      handler: handlerEditOnClick,
      isShown: !isEditable,
    });
  }

  return (
    <SC.GroupForm component="form" onSubmit={handlerOnSubmit}>
      <GroupFormFields
        groupData={groupData}
        isEditable={isEditable}
        usersList={usersList}
        rolesList={rolesList}
        usersBelongToGroup={usersBelongToGroup as UserData[]}
        onChange={handleOnChange}
        onUsersChange={handleOnUsersChange}
      />
      <SC.FormActions>
        {buttons.map(
          ({ isShown, variant, type, handler, title }) =>
            isShown && (
              <SC.Button
                key={title}
                color="primary"
                variant={variant as ButtonVariant}
                type={type as ButtonType}
                onClick={handler}
              >
                {title}
              </SC.Button>
            ),
        )}
      </SC.FormActions>
    </SC.GroupForm>
  );
};

export default GroupForm;
