// Dependencies
import React from "react";

// Components
import UserFormFields from "components/user-form-fields/user-form-fields.component";
import {
  useAppPermissionValidator,
  APP_PERMISSION,
} from "components/app-permission-validator/app-permission-validator.component";

// GraphQL
import {
  DepartmentData,
  GroupData,
  OrganisationData,
  UserData,
} from "graphql/types-and-hooks";
import { createEmptyUserData } from "graphql/rbac.utils";

// Assets
import SC from "./user-form.styles";

type ButtonVariant = "contained" | "outlined" | "text" | undefined;
type ButtonType = "button" | "reset" | "submit" | undefined;
export type UserFormState = UserData & { password?: string };

export interface UserFormProps {
  initialUserData?: UserData;
  initialEditable: boolean;
  saveActive?: boolean;
  isProfile?: boolean;
  isCreatingUser?: boolean;
  organisations: OrganisationData[];
  departments: DepartmentData[];
  groups: GroupData[];
  onSubmit: (userData: UserFormState) => void;
  onCancel: () => void;
  onClickResendEmail: (userEmail: string) => void;
}

export const UserForm: React.FC<UserFormProps> = ({
  initialUserData,
  initialEditable,
  isProfile = false,
  isCreatingUser = false,
  organisations,
  departments,
  groups,
  onSubmit,
  onCancel,
  onClickResendEmail,
}) => {
  const [userData, setUserData] = React.useState<UserFormState>(
    () => initialUserData ?? createEmptyUserData(),
  );
  const [isEditable, setIsEditable] = React.useState(initialEditable);
  const isVerified = userData.emailVerified;

  React.useEffect(() => {
    if (initialUserData) setUserData(initialUserData);
  }, [initialUserData]);

  const handlerEditOnClick = React.useCallback(() => {
    setIsEditable(true);
  }, []);

  const handlerCancelOnClick = React.useCallback(() => {
    setIsEditable(false);
    if (initialUserData) setUserData(initialUserData);
    onCancel();
  }, [initialUserData, onCancel]);

  const handlerOnSubmit: React.FormEventHandler = React.useCallback(
    (event) => {
      event.preventDefault();
      setIsEditable(initialEditable);
      if (initialUserData !== userData) onSubmit(userData);
    },
    [initialEditable, initialUserData, onSubmit, userData],
  );

  const handleOnChange = React.useCallback((newUserData: UserData) => {
    setUserData(newUserData);
  }, []);

  const handleResendVerificationEmail = React.useCallback(() => {
    onClickResendEmail(userData.email ?? "");
  }, [onClickResendEmail, userData.email]);

  const buttons = [
    {
      title: "Save",
      variant: "contained",
      type: "submit",
      isShown: isEditable,
    },
    {
      title: "Cancel",
      variant: "outlined",
      handler: handlerCancelOnClick,
      isShown: isEditable,
    },
    {
      title: "Resend Email Verification",
      variant: "outlined",
      handler: handleResendVerificationEmail,
      isShown: isEditable && !isCreatingUser && !isProfile && !isVerified,
    },
  ];

  const appPermissionValidator = useAppPermissionValidator();

  if (appPermissionValidator?.(APP_PERMISSION.ADMIN_ADDEDIT_USERS)) {
    buttons.push({
      title: "Edit",
      variant: "outlined",
      handler: handlerEditOnClick,
      isShown: !isEditable,
    });
  }

  return (
    <SC.UserForm component="form" onSubmit={handlerOnSubmit}>
      <UserFormFields
        userData={userData}
        isEditable={isEditable}
        isProfile={isProfile}
        isCreatingUser={isCreatingUser}
        organisations={organisations}
        departments={departments}
        groups={groups}
        onChange={handleOnChange}
      />
      <SC.FormActions>
        {buttons.map(
          ({ isShown, variant, type, handler, title }) =>
            isShown && (
              <SC.Button
                key={title}
                color="primary"
                variant={variant as ButtonVariant}
                type={type as ButtonType}
                onClick={handler}
              >
                {title}
              </SC.Button>
            ),
        )}
      </SC.FormActions>
    </SC.UserForm>
  );
};

export default UserForm;
