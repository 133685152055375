/* eslint-disable no-alert */
// Dependencies
import React, { useCallback } from "react";
// GraphQL
import { ServerDataToLayer } from "rView/home/utils/layer";

// Components
import Breadcrumb from "components/breadcrumb/breadcrumb.component";
import { BanjulPropertiesTable } from "../banjul-properties-table/banjul-properties-table.component";

// Assets
import SC from "./banjul-properties.styles";

export const BanjulPropertiesViews: React.FC = React.memo(() => {
  const [rowsPerPageData, setRowsPerPageData] = React.useState<[]>([]);
  const handleOnDataPerPageChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (data: any) => {
      setRowsPerPageData(data);
    },
    [setRowsPerPageData],
  );

  return (
    <>
      <SC.SwitchViews
        LeftView={
          <SC.MapTableContainer>
            <BanjulPropertiesTable
              displayTitle
              onDataPerPageChange={handleOnDataPerPageChange}
            />
          </SC.MapTableContainer>
        }
        leftViewName="Table"
        RightView={
          <SC.MapContainer properties={ServerDataToLayer(rowsPerPageData)} />
        }
        rightViewName="Map"
      />
    </>
  );
});

const BanjulProperties: React.FC = () => {
  return (
    <SC.Box pl={2} pr={2} pt={2}>
      <Breadcrumb />
      <BanjulPropertiesViews />
    </SC.Box>
  );
};

export default BanjulProperties;
