import React from "react";

// Components
import Breadcrumb from "components/breadcrumb/breadcrumb.component";
import { CorporateTaxPayerTable } from "../corporate-tax-payer-table/corporate-tax-payer-table.component";

// Assets
import SC from "./corporate-tax-payer.styles";

const CorporateTaxPayer: React.FC = () => {
  return (
    <SC.Box pl={2} pr={2} pt={2}>
      <Breadcrumb />
      <CorporateTaxPayerTable
        displayHeader
        displayTitle
      />
    </SC.Box>
  );
};

export default CorporateTaxPayer;
