// Dependencies
import React from "react";
import { SvgIcon } from "@mui/material";
import {
  Add,
  Fullscreen,
  ViewColumn,
  SaveAlt,
  FullscreenExit,
} from "@mui/icons-material";
import ExportTablePopover from "components/export-table/export-table.component";

// Components
import {
  useAppPermissionValidator,
  APP_PERMISSION,
} from "components/app-permission-validator/app-permission-validator.component";

// Assets
// FilterAlt icon will be available in next version of @material-ui/core
import { ReactComponent as FilterAlt } from "assets/icons/filter_alt-black-24dp.svg";
import SC from "./table-toolbar-actions.styles";

export type TableToolbarAction =
  | "add"
  | "fullscreen-expand"
  | "hide/show-columns"
  | "filter-results"
  | "export-to-excel-sheet/csv";

const ActionIcon: Record<
  TableToolbarAction,
  (activated?: boolean) => JSX.Element
> = {
  add: () => <Add />,
  "fullscreen-expand": (activated) =>
    activated ? <FullscreenExit /> : <Fullscreen />,
  "hide/show-columns": () => <ViewColumn />,
  "filter-results": () => <SvgIcon component={FilterAlt} />,
  "export-to-excel-sheet/csv": () => <SaveAlt />,
};

const ActionTitle: Record<TableToolbarAction, (activated?: boolean) => string> =
  {
    add: () => "Add",
    "fullscreen-expand": (activated) =>
      activated ? "Fullscreen Exit" : "Fullscreen Expand",
    "hide/show-columns": () => "Hide/Show Columns",
    "filter-results": () => "Filter Results",
    "export-to-excel-sheet/csv": () => "Export to Excel/Sheets/CSV",
  };

interface Props {
  actions?: TableToolbarAction[];
  onAction?: (action: TableToolbarAction) => void;
  actionsStates?: Record<TableToolbarAction, boolean | undefined>;
}

const TableToolbarActions: React.FC<Props> = ({
  actions,
  onAction,
  actionsStates,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const appPermissionValidator = useAppPermissionValidator();
  const open = Boolean(anchorEl);
  const id = open ? "table-filter-popover" : undefined;

  const filteredActions = React.useMemo<TableToolbarAction[] | undefined>(
    () =>
      appPermissionValidator?.(APP_PERMISSION.ADMIN_EXPORT)
        ? actions
        : actions?.filter((action) => action !== "export-to-excel-sheet/csv"),
    [actions, appPermissionValidator],
  );

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    action: TableToolbarAction,
  ) => {
    if (action !== "export-to-excel-sheet/csv") {
      if (onAction) {
        onAction(action);
      }
      return;
    }
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const onExportFunc = () => {
    if (onAction) {
      onAction("export-to-excel-sheet/csv");
    }
  };

  if (!filteredActions) return null;

  return (
    <SC.ToolbarActions aria-describedby={id}>
      {filteredActions.map((action) => {
        const actionState = actionsStates?.[action];
        const title = ActionTitle[action](actionState);
        const icon = ActionIcon[action](actionState);

        return (
          <SC.Tooltip key={action} title={title}>
            <SC.IconButton
              aria-label={title}
              onClick={(event: React.MouseEvent<HTMLElement>) =>
                handleClick(event, action)
              }
            >
              {icon}
            </SC.IconButton>
          </SC.Tooltip>
        );
      })}
      <ExportTablePopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onButtonClick={onExportFunc}
      />
    </SC.ToolbarActions>
  );
};

export default TableToolbarActions;
