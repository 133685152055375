// Dependencies
import React from "react";

// Components
import Breadcrumb from "components/breadcrumb/breadcrumb.component";
import { RegisteredAssociationsTable } from "../registered-associations-table/registered-associations-table.component";

// Assets
import SC from "./registered-associations.styles";

const RegisteredAssociations: React.FC = () => {
  return (
    <SC.Box pl={2} pr={2} pt={2}>
      <Breadcrumb />
      <RegisteredAssociationsTable displayHeader displayTitle />
    </SC.Box>
  );
};

export default RegisteredAssociations;
