/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import {
  Box as ToolbarActions,
  Tooltip,
  IconButton as IconButtonBase,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const IconButton = styled(IconButtonBase)(({ theme }) => ({
  padding: theme.spacing(1),
  "& .MuiSvgIcon-root": {
    fontSize: theme.table.toolbarActions.fontSize,
  },
  "&:not(.Mui-disabled) .MuiSvgIcon-root": {
    color: theme.table.toolbarActions.iconColor,
  },
}));

export default {
  ToolbarActions,
  Tooltip,
  IconButton,
};
