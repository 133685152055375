/* eslint-disable @typescript-eslint/no-non-null-assertion */
// Dependencies
import React from "react";

// Components
import RoleFormFields from "components/role-form-fields/role-form-fields.container";
import {
  APP_PERMISSION,
  useAppPermissionValidator,
} from "components/app-permission-validator/app-permission-validator.component";

// GraphQL
import {
  PermissionInput,
  PermissionType,
  RoleData,
} from "graphql/types-and-hooks";

// Assets
import SC from "./role-form.styles";

type ButtonVariant = "contained" | "outlined" | "text" | undefined;
type ButtonType = "button" | "reset" | "submit" | undefined;

export interface RoleFormProps {
  initialRoleData: RoleData;
  initialEditable: boolean;
  onSubmit: (roleData: RoleData, permissionData: PermissionInput[]) => void;
  onCancel: () => void;
}

export const RoleForm: React.FC<RoleFormProps> = ({
  initialRoleData,
  initialEditable,
  onSubmit,
  onCancel,
}) => {
  const [roleData, setRoleData] = React.useState(initialRoleData);
  const [cleanPermissionsChangedList, setCleanPermissionsChangedList] =
    React.useState(false);
  const [isEditable, setIsEditable] = React.useState(initialEditable);
  const [resetData, setResetData] = React.useState(false);
  const [permissionsDataChangeList, setPermissionsDataChangeList] =
    React.useState<PermissionInput[]>([]);
  const [permissionsAppChangeList, setPermissionsAppChangeList] =
    React.useState<PermissionInput[]>([]);

  const handlerEditOnClick = React.useCallback(() => {
    setIsEditable(true);
    setResetData(false);
    setCleanPermissionsChangedList(false);
  }, []);

  const handlerCancelOnClick = React.useCallback(() => {
    setIsEditable(false);
    setRoleData(initialRoleData);
    setResetData(true);
    onCancel();
    setCleanPermissionsChangedList(true);
  }, [initialRoleData, onCancel]);

  const handleRoleOnChange = React.useCallback((newRoleData: RoleData) => {
    setRoleData(newRoleData);
  }, []);

  const handlePermissionOnChange = React.useCallback(
    (
      newPermissionsChangedList: PermissionInput[],
      permissionType: PermissionType,
    ) => {
      if (permissionType === PermissionType.Data)
        setPermissionsDataChangeList(newPermissionsChangedList);
      else {
        setPermissionsAppChangeList(newPermissionsChangedList);
      }
    },
    [],
  );

  const handlerOnSubmit: React.FormEventHandler = React.useCallback(
    (event) => {
      event.preventDefault();
      const newPermissionsChangedList = [
        ...permissionsDataChangeList,
        ...permissionsAppChangeList,
      ];
      onSubmit(roleData, newPermissionsChangedList);

      setIsEditable(false);
      setCleanPermissionsChangedList(true);
    },
    [onSubmit, permissionsAppChangeList, permissionsDataChangeList, roleData],
  );

  const buttons = [
    {
      title: "Save",
      variant: "contained",
      type: "submit",
      isShown: isEditable,
    },
    {
      title: "Cancel",
      variant: "outlined",
      handler: handlerCancelOnClick,
      isShown: isEditable,
    },
  ];

  const appPermissionValidator = useAppPermissionValidator();

  if (appPermissionValidator?.(APP_PERMISSION.ADMIN_ADDEDIT_USERS)) {
    buttons.push({
      title: "Edit",
      variant: "outlined",
      handler: handlerEditOnClick,
      isShown: !isEditable,
    });
  }

  return (
    <SC.RoleForm component="form" onSubmit={handlerOnSubmit}>
      <RoleFormFields
        roleData={roleData}
        isEditable={isEditable}
        resetData={resetData}
        onRoleChange={handleRoleOnChange}
        onPermissionsChange={handlePermissionOnChange}
        cleanPermissionsChangedList={cleanPermissionsChangedList}
      />
      <SC.FormActions>
        {buttons.map(
          ({ isShown, title, variant, type, handler }) =>
            isShown && (
              <SC.Button
                key={title}
                color="primary"
                variant={variant as ButtonVariant}
                type={type as ButtonType}
                onClick={handler}
              >
                {title}
              </SC.Button>
            ),
        )}
      </SC.FormActions>
    </SC.RoleForm>
  );
};

export default RoleForm;
