// Dependencies
import PropTypes from "prop-types";

export const coordinateShape = PropTypes.shape({
  latitude: PropTypes.number,
  longitude: PropTypes.number,
});

export const gMapsCoordinateShape = PropTypes.shape({
  lat: PropTypes.number,
  lng: PropTypes.number,
});

export const projectCategoryShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  isDeleted: PropTypes.bool,
  organizationId: PropTypes.string,
  zoneId: PropTypes.string,
  project: PropTypes.string,
  category: PropTypes.string,
});

export const categoriesByProjectShape = PropTypes.shape({
  projectName: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(projectCategoryShape),
});

export const zoneShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  isDeleted: PropTypes.bool,
  organizationId: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  zoneType: PropTypes.number,
  defaultZoomLevel: PropTypes.number,
  primaryCity: PropTypes.string,
  primaryState: PropTypes.string,
  primaryCountry: PropTypes.string,
  primaryZip: PropTypes.string,
  parentZoneId: PropTypes.string,
  bounds: PropTypes.string,
  center: coordinateShape,
  zoneTypeShortDesc: PropTypes.string,
});

export const mapLayerShape = PropTypes.shape({
  id: PropTypes.string,
  isDeleted: PropTypes.bool,
  name: PropTypes.string,
  description: PropTypes.string,
  organizationId: PropTypes.string,
  mapSourceType: PropTypes.number,
  source: PropTypes.string,
  isSnapshot: PropTypes.bool,
  isActive: PropTypes.bool,
  isPrivate: PropTypes.bool,
  isShareable: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  features: PropTypes.arrayOf(PropTypes.object), // It seemed me that it is a array, need review
  geocodeLayerId: PropTypes.string,
  dynamickmlurl: PropTypes.string,
  zoneType: PropTypes.number,
  projectCategoryId: PropTypes.string,
  projectName: PropTypes.string,
  zoneId: PropTypes.string,
  boundingBox: PropTypes.string,
  categoryName: PropTypes.string,
  zoneName: PropTypes.string,
});

export const mapLayersByProjCatTreeType = PropTypes.objectOf(
  // Projects
  PropTypes.objectOf(
    // Categories
    PropTypes.arrayOf(mapLayerShape), // Layers
  ),
);

export const propertiesShape = PropTypes.shape({
  name: PropTypes.string,
  icon: PropTypes.string,
  Website: PropTypes.string,
  Telephone: PropTypes.string,
  Organisation: PropTypes.string,
  Email: PropTypes.string,
  Description: PropTypes.string,
  "Cobseo Registered?": PropTypes.string,
  Address: PropTypes.string,
  Postcode: PropTypes.string,
});

export const geometryShape = PropTypes.shape({
  type: PropTypes.oneOf(["Point"]),
  coordinates: PropTypes.arrayOf(PropTypes.number),
});

export const featureShape = PropTypes.shape({
  id: PropTypes.string, // Generated ID
  type: PropTypes.oneOf(["Feature"]),
  geometry: geometryShape,
  properties: propertiesShape,
  // Calculated fields
  position: gMapsCoordinateShape,
  distanceFromUser: PropTypes.number,
});

export const GeoJSONDataLayerShape = PropTypes.shape({
  type: PropTypes.string,
  features: PropTypes.arrayOf(featureShape),
});

export const gMapsBoundsShape = PropTypes.shape({
  ne: gMapsCoordinateShape,
  nw: gMapsCoordinateShape,
  se: gMapsCoordinateShape,
  sw: gMapsCoordinateShape,
});

export const gMapsSizeShape = PropTypes.shape({
  height: PropTypes.number,
  width: PropTypes.number,
});

export const gMapsViewportPropertiesShape = PropTypes.shape({
  bounds: gMapsBoundsShape,
  center: gMapsCoordinateShape,
  marginBounds: gMapsBoundsShape,
  size: gMapsSizeShape,
  zoom: PropTypes.number,
});

export const layerShape = PropTypes.shape({
  dataLayer: GeoJSONDataLayerShape,
  mapLayer: mapLayerShape,
});

export const searchShape = PropTypes.shape({
  filter: PropTypes.string,
  mapLayer: mapLayerShape,
});

export const searchSuggestionsShape = PropTypes.shape({
  criteria: PropTypes.string,
  matches: PropTypes.arrayOf(
    PropTypes.shape({
      match: PropTypes.string,
    }),
  ),
});

export const projectCategoryFromCSSShape = PropTypes.shape({
  cssFileName: PropTypes.string,
  project: PropTypes.string,
  category: PropTypes.string,
  layerIds: PropTypes.string,
});
