import React, { PropsWithChildren } from "react";
import { useWindowHeight } from "@react-hook/window-size";

import SC from "./map-table-container.styles";

interface MapTableContainerProps {}

const MapTableContainer: React.FC<
  PropsWithChildren<MapTableContainerProps>
> = ({ children }) => {
  const mainContainerRef = React.useRef<HTMLDivElement>(null);
  const mainContainerPosition =
    mainContainerRef.current?.getBoundingClientRect();

  const mainContainerPositionY = mainContainerPosition?.y ?? 0;
  const windowHeight = useWindowHeight();
  const containerHeight = windowHeight - mainContainerPositionY;

  return (
    <SC.Container
      ref={mainContainerRef}
      maxHeight={containerHeight}
      maxWidth={false}
    >
      {children}
    </SC.Container>
  );
};

export default MapTableContainer;
