import { TableColumnDef } from "components/table/table.component";
import { Link, useLocation } from "react-router-dom";
import { AccessorFn } from "@tanstack/react-table";
import _ from "lodash";
import { Africa360ViewKanifingFragment } from "graphql/hasura/types-and-hooks";
import { FilterAccessorValue } from "pages/reports/utils";
import { getSourcePath } from "utils/route-patterns";

// Define the schema type for Kanifing Properties
export type KanifingPropertiesTableSchema = Omit<Africa360ViewKanifingFragment, "__typename">;

// Define the filter interface
export interface KanifingPropertiesTableFilters {
  address: string;
  businessName: string;
  email: string;
  firstName: string;
  idNumber: string;
  phoneNumber: string;
  plusCode: string;
  surname: string;
}

// Map the schema data types
export const KANIFING_PROPERTIES_SCHEMA_DATA_TYPE_MAP: Record<
  keyof KanifingPropertiesTableSchema,
  "string" | "number"
> = {
  BuildingCentroidGps: "string",
  BuildingFootprintArea: "number",
  BuildingTotalArea: "number",
  BuildingTypeObservedIn2024: "string",
  PlusCode1: "string",
  PlusCode2: "string",
  BusinessName: "string",
  Latitude: "number",
  Longitude: "number",
  NumberOfStoreys: "number",
  Phone1: "string",
  Phone2: "string",
  RoofMaterial: "string",
  Settlement: "string",
  Lga: "string",
  OfficialBuildingType: "string",
  StreetName: "string",
  Uuid: "string",
  WallMaterial: "string",
};

// Define filter accessors
export const DEFAULT_FILTER_ACCESSOR: Record<
  keyof KanifingPropertiesTableFilters,
  FilterAccessorValue<KanifingPropertiesTableSchema>
> = {
  address: ["StreetName", "Settlement", "Lga", "Latitude", "Longitude"],
  businessName: "BusinessName",
  email: "BusinessName",
  firstName: "BusinessName",
  idNumber: "Uuid",
  phoneNumber: ["Phone1", "Phone2"],
  plusCode: ["PlusCode1", "PlusCode2"],
  surname: "BusinessName",
};

// Helper function to get the accessor type
export const getAccessorType = (
  accessor:
    | keyof KanifingPropertiesTableSchema
    | AccessorFn<KanifingPropertiesTableSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return KANIFING_PROPERTIES_SCHEMA_DATA_TYPE_MAP[accessor];
};

// Define the table columns
export const tableColumns: TableColumnDef<
  KanifingPropertiesTableSchema | Record<string, unknown>
>[] = [
  {
    header: "UUID",
    accessorKey: "Uuid",
    align: "left",
    cell: ({
      getValue,
      row: {
        original: { Uuid },
      },
    }) => {
      const location = useLocation();
      const sourcePath = getSourcePath(location);

      return (
        <Link 
          to={`/properties/africa-360-kanifing/${Uuid}/details`} 
          state={{ from: sourcePath }}
          color="inherit"
        >
          <>{Uuid}</>
        </Link>
      );
    },
  },
  {
    header: "Official Building Type",
    accessorKey: "OfficialBuildingType",
    align: "left",
    format: "string",
  },
  {
    header: "Building Type Observed in 2024",
    accessorKey: "BuildingTypeObservedIn2024",
    align: "left",
    format: "string",
  },
  {
    header: "Plus Code 1",
    accessorKey: "PlusCode1",
    align: "left",
    format: "string",
  },
  {
    header: "Plus Code 2",
    accessorKey: "PlusCode2",
    align: "left",
    format: "string",
  },
  {
    header: "Street Name",
    accessorKey: "StreetName",
    align: "left",
    format: "string",
  },
  {
    header: "Settlement",
    accessorKey: "Settlement",
    align: "left",
    format: "string",
  },
  {
    header: "LGA",
    accessorKey: "Lga",
    align: "left",
    format: "string",
  },
  {
    header: "Number of Storeys",
    accessorKey: "NumberOfStoreys",
    align: "left",
    format: "number",
  },
  {
    header: "Building Footprint Area M2",
    accessorKey: "BuildingFootprintArea",
    align: "left",
    format: "string",
  },
  {
    header: "Total Building Area M2",
    accessorKey: "BuildingTotalArea",
    align: "left",
    format: "string",
  },
  {
    header: "Wall Material",
    accessorKey: "WallMaterial",
    align: "left",
    format: "string",
  },
  {
    header: "Roof Material",
    accessorKey: "RoofMaterial",
    align: "left",
    format: "string",
  },
  {
    header: "Building Centroid GPS",
    accessorKey: "BuildingCentroidGps",
    align: "left",
    format: "string",
  },
  {
    header: "Latitude",
    accessorKey: "Latitude",
    align: "left",
    format: "string",
  },
  {
    header: "Longitude",
    accessorKey: "Longitude",
    align: "left",
    format: "string",
  },
  {
    header: "Business Name Observed in 2024",
    accessorKey: "BusinessName",
    align: "left",
    format: "string",
  },
  {
    header: "Phone 1 Observed in 2024",
    accessorKey: "Phone1",
    align: "left",
    format: "string",
  },
  {
    header: "Phone 2 Observed in 2024",
    accessorKey: "Phone2",
    align: "left",
    format: "string",
  },
]; 