import { TableColumnDef } from "components/table/table.component";
import {
  Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers,
  NawecWaterAndElectricityCustomersFragment,
} from "graphql/hasura/types-and-hooks";
import _ from "lodash";
import { AccessorFn } from "@tanstack/react-table";
import { FilterAccessorValue } from "pages/reports/utils";
export type WaterAndElectricityCustomersTableSchema = Omit<
  NawecWaterAndElectricityCustomersFragment,
  "__typename"
>;
export interface WaterAndElectricityCustomersTableFilters {
    phoneNumber: string;
    email: string;
    surname: string;
    firstName: string;
    businessName: string;
    address: string;
}
export const DEFAULT_FILTER_ACCESSOR: Record<
  keyof WaterAndElectricityCustomersTableFilters,
  FilterAccessorValue<Rtcs_Gm_Dev_Ph_Nawec_WaterAndElectricityCustomers>
> = {
  phoneNumber: ["Sms"],
  email: ["Email"],
  surname: ["Customer"],
  firstName: ["Customer"],
  businessName: ["Customer"],
  address: ["Addressreference", "Supplyzone", "Accountbranch", "Accountregion", "Contractaddress"],
};
export const WaterAndElectricityCustomersTableSchemaMap: Record<
  keyof WaterAndElectricityCustomersTableSchema,
  "string" | "date"
> = {
  Accountno: "string",
  Sector: "string",
  Serviceratetype: "string",
  Customer: "string",
  Sms: "string",
  Email: "string",
  Customertype: "string",
  Premisetype: "string",
  Supplytype: "string",
  Activity: "string",
  Addressreference: "string",
  Supplyzone: "string",
  Accountbranch: "string",
  Meternumber: "string",
  Contractstatus: "string",
  Serviceregistrationdate: "date",
  Serviceterminationdate: "date",
  Segment: "string",
  Tariff: "string",
  Contractreference: "string",
  Groupcode: "string",
  Contractaddress: "string",
  Source: "string",
  Polenumber: "string",
};
export const getAccessorType = (
  accessor:
    | keyof WaterAndElectricityCustomersTableSchema
    | AccessorFn<WaterAndElectricityCustomersTableSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;
  return WaterAndElectricityCustomersTableSchemaMap[accessor];
};
export const columns: TableColumnDef<WaterAndElectricityCustomersTableSchema>[] = [
  {
    header: "Account Number",
    accessorKey: "Accountno",
    align: "left",
    format: "string",
  },
  {
    header: "Account Type",
    accessorKey: "Sector",
    align: "left",
    format: "string",
  },
  {
    header: "Service Type",
    accessorKey: "Serviceratetype",
    align: "left",
    format: "string",
  },
  {
    header: "Customer Name",
    accessorKey: "Customer",
    align: "left",
    format: "string",
  },
  {
    header: "SMS Number",
    accessorKey: "Sms",
    align: "left",
    format: "string",
  },
  {
    header: "Email",
    accessorKey: "Email",
    align: "left",
    format: "string",
  },
  {
    header: "Customer Type",
    accessorKey: "Customertype",
    align: "left",
    format: "string",
  },
  {
    header: "Premises Type",
    accessorKey: "Premisetype",
    align: "left",
    format: "string",
  },
  {
    header: "Supply Type",
    accessorKey: "Supplytype",
    align: "left",
    format: "string",
  },
  {
    header: "Economic Activity",
    accessorKey: "Activity",
    align: "left",
    format: "string",
  },
  {
    header: "Address Line 1",
    accessorKey: "Addressreference",
    align: "left",
    format: "string",
  },
  {
    header: "Supply Zone",
    accessorKey: "Supplyzone",
    align: "left",
    format: "string",
  },
  {
    header: "Account Branch",
    accessorKey: "Accountbranch",
    align: "left",
    format: "string",
  },
  {
    header: "Meter Number",
    accessorKey: "Meternumber",
    align: "left",
    format: "string",
  },
  {
    header: "Status",
    accessorKey: "Contractstatus",
    align: "left",
    format: "string",
  },
  {
    header: "Service Registration Date",
    accessorKey: "Serviceregistrationdate",
    align: "left",
    format: "date",
  },
  {
    header: "Service Termination Date",
    accessorKey: "Serviceterminationdate",
    align: "left",
    format: "date",
  },
  {
    header: "Segment",
    accessorKey: "Segment",
    align: "left",
    format: "string",
  },
  {
    header: "Tariff",
    accessorKey: "Tariff",
    align: "left",
    format: "string",
  },
  {
    header: "Contract Reference Number",
    accessorKey: "Contractreference",
    align: "left",
    format: "string",
  },
  {
    header: "Group Code",
    accessorKey: "Groupcode",
    align: "left",
    format: "string",
  },
  {
    header: "Contract Address",
    accessorKey: "Contractaddress",
    align: "left",
    format: "string",
  },
  {
    header: "Source",
    accessorKey: "Source",
    align: "left",
    format: "string",
  },
  {
    header: "Pole Number",
    accessorKey: "Polenumber",
    align: "left",
    format: "string",
  },
];