import { TableColumnDef } from "components/table/table.component";
import { AccessorFn } from "@tanstack/react-table";
import _ from "lodash";
import {
  GraIndividualReturnRentFragment,
  Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent,
} from "graphql/hasura/types-and-hooks";
import { FilterAccessorValue } from "pages/reports/utils";

export type GraIndividualReturnRentSchema = Omit<
  GraIndividualReturnRentFragment,
  "__typename"
>;

export interface IndividualReturnRentTableFilters {}

export const DEFAULT_FILTER_ACCESSOR: Record<
  keyof IndividualReturnRentTableFilters,
  FilterAccessorValue<Rtcs_Gm_Dev_Ph_Gra_IndividualReturnRent>
> = {};

export const GRA_INDIVIDUAL_RETURN_RENT_SCHEMA_MAP: Record<
  keyof GraIndividualReturnRentSchema,
  "string" | "number" | "Date"
> = {
  Alloweddeduction: "number",
  Commercialused: "string",
  Grossrent: "number",
  Landlordname: "string",
  Landlordtin: "string",
  Netincome: "number",
  Propertyaddress: "string",
  Propertycost: "number",
  Propertyused: "string",
  Rentid: "string",
  Residentialused: "string",
  Returnid: "string",
  Telephone: "string",
};

export const getAccessorType = (
  accessor:
    | keyof GraIndividualReturnRentSchema
    | AccessorFn<GraIndividualReturnRentSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;
  return GRA_INDIVIDUAL_RETURN_RENT_SCHEMA_MAP[accessor];
};

export const tableColumns: TableColumnDef<GraIndividualReturnRentSchema>[] = [
  {
    header: "Rent ID",
    accessorKey: "Rentid",
    align: "left",
    format: "string",
  },
  {
    header: "Return ID",
    accessorKey: "Returnid",
    align: "left",
    format: "string",
  },
  {
    header: "Property Address",
    accessorKey: "Propertyaddress",
    align: "left",
    format: "string",
  },
  {
    header: "Landlord Name",
    accessorKey: "Landlordname",
    align: "left",
    format: "string",
  },
  {
    header: "Landlord TIN",
    accessorKey: "Landlordtin",
    align: "left",
    format: "string",
  },
  {
    header: "Property Cost",
    accessorKey: "Propertycost",
    align: "right",
    format: "number",
  },
  {
    header: "Gross Rent",
    accessorKey: "Grossrent",
    align: "right",
    format: "number",
  },
  {
    header: "Allowed Deduction",
    accessorKey: "Alloweddeduction",
    align: "right",
    format: "number",
  },
  {
    header: "Net Income",
    accessorKey: "Netincome",
    align: "right",
    format: "number",
  },
  {
    header: "Property Used",
    accessorKey: "Propertyused",
    align: "left",
    format: "string",
  },
  {
    header: "Commercial Used",
    accessorKey: "Commercialused",
    align: "left",
    format: "string",
  },
  {
    header: "Residential Used",
    accessorKey: "Residentialused",
    align: "left",
    format: "string",
  },
  {
    header: "Telephone",
    accessorKey: "Telephone",
    align: "left",
    format: "string",
  },
];
