// Dependencies
import React, { useEffect, useMemo } from "react";
import { useAppErrorHandler } from "errors/app.errors";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Query_RootRtcs_Gm_Dev_Ph_Grc_OfficerOwnerArgs,
  useGrcOfficerOwnerPaginatedQuery,
} from "graphql/hasura/types-and-hooks";
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";
import useExportData from "exports/useExportData";
import { columns as TableColumns } from "./corporate.schema";

// Assets
import SC from "./corporate.styles";
import generatePersistentId from "utils/packageJson";

export interface CorporateProps {
  personID: string;
}

const TABLE_NAME = "rtcs_gm_dev_Fngrc_OfficerOwner";

const Corporate: React.FC<CorporateProps> = ({ personID }) => {
  if (!personID) {
    return null;
  }

  const [pageIndexResetSignal, setPageIndexResetSignal] = React.useState(false);
  const [queryVariables, setQueryVariables] =
    React.useState<Query_RootRtcs_Gm_Dev_Ph_Grc_OfficerOwnerArgs>({
      where: {
        _and: [
          {
            _or: [
              { Personid: { _eq: personID.toLowerCase() } },
              { Personid: { _eq: personID.toUpperCase() } },
            ],
          },
        ],
      },
    });

  const {
    data: responseData,
    loading,
    error,
  } = useGrcOfficerOwnerPaginatedQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !queryVariables,
    variables: {
      params: jsonToStringQueryVariables(queryVariables ?? {}),
      aggregateParams: jsonToStringQueryVariables({
        where: queryVariables?.where,
      }),
    },
  });

  const [exportDataFn, { loading: exportLoading, error: exportError }] =
    useExportData(TABLE_NAME, queryVariables);

  useAppErrorHandler(error || exportError);

  const officersOwnersdata = React.useMemo(
    () => responseData?.rtcs_gm_dev_Fngrc_OfficerOwner ?? [],
    [responseData],
  );

  const columns = useMemo(() => TableColumns, []);

  return (
    <>
      <SC.ProgressIndicator open={loading || exportLoading} />
      <SC.Table
        title="Businesses"
        actionsOnRight={[
          "fullscreen-expand",
          "hide/show-columns",
          "export-to-excel-sheet/csv",
        ]}
        // eslint-disable-next-line no-alert
        onAction={() => alert("under construction")}
        columns={columns}
        data={officersOwnersdata}
        stickyHeader
        persistenceId={generatePersistentId(
          "8e7fbcc0-54a5-471f-aa1a-971a9ce631d2",
        )}
        exportData={exportDataFn}
        pageIndexResetSignal={pageIndexResetSignal}
      />
    </>
  );
};

export default Corporate;
