import React from "react";

// Components
import MobileCarrierSubscriberRegistrationsTable from "pages/reports/pura/mobile-carrier-subscriber-registrations-table/mobile-carrier-subscriber-registrations-table.component";

// Assets
import AccordionList from "components/accordion-list/accordion-list.component";

interface Filters {
  address: string;
  businessName: string;
  email: string;
  firstName: string;
  idNumber: string;
  phoneNumber: string;
  plusCode: string;
  surname: string;
}

interface PuraTabProps {
  defaultFilters?: Filters;
  initialRowsPerPage?: number;
  maxHeight?: number;
  noDataComponent?: JSX.Element;
}

export const PuraTab: React.FC<PuraTabProps> = React.memo(
  ({ defaultFilters, initialRowsPerPage, maxHeight, noDataComponent }) => {
    const reportSections = [
      {
        title: "Public Utilities Regulatory Authority",
        accordions: [
          {
            content: (
              <MobileCarrierSubscriberRegistrationsTable
                defaultFilters={defaultFilters}
                initialRowsPerPage={initialRowsPerPage}
                maxHeight={maxHeight}
                noDataComponent={noDataComponent}
                persistenceId="88de6e3a-2d77-494e-bb89-29c2163d522f"
              />
            ),
            subtitle: "Mobile Carrier Subscriber Registrations",
          },
        ],
      },
    ];

    return <AccordionList defaultExpanded sections={reportSections} />;
  },
);
