/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Popper,
  Box,
  Paper as PaperBase,
  Button as ButtonBase,
  Typography,
  Select as SelectBase,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const Paper = styled(PaperBase)(({ theme }) => ({
  width: "20rem",
  height: "15rem",
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(3),
}));

const SelectField = styled(SelectBase)(({ theme }) => ({
  width: "100%",
  borderWidth: "0px",
  ".MuiInputBase-root": {
    width: "100%",
    paddingBottom: theme.spacing(3),
  },
  select: {
    width: "100%",
  },
  selectMenu: {
    width: "100%",
  },
}));

const Label = styled(Typography)(({ theme }) => ({
  fontSize: "1.4286rem",
  width: "100%",
  paddingBottom: theme.spacing(3),
}));

const Button = styled(ButtonBase)(({ theme }) => ({
  alignSelf: "flex-end",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.background.default,
  textTransform: "none",
  marginTop: theme.spacing(4),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  margin: theme.spacing(2),
  textAlign: "center",
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
  },
}));

export default { Box, Popper, Paper, Label, SelectField, MenuItem, Button };
