// Dependencies
import React, { useEffect, useMemo } from "react";
import { useAppErrorHandler } from "errors/app.errors";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Query_RootRtcs_Gm_Dev_Ph_Grc_OfficerOwnerArgs,
  useGrcOfficerOwnerPaginatedQuery,
  Rtcs_Gm_Dev_Ph_Grc_OfficerOwner,
} from "graphql/hasura/types-and-hooks";
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";
import useExportData from "exports/useExportData";
import { columns as TableColumns } from "./officers-owners.schema";

// Assets
import SC from "./officers-owners.styles";
import generatePersistentId from "utils/packageJson";

export interface OwnersOfficersProps {
  corporateID: string;
}

const TABLE_NAME = "rtcs_gm_dev_Fngrc_OfficerOwner";

const OwnersOfficersTab: React.FC<OwnersOfficersProps> = ({ corporateID }) => {
  if (!corporateID) {
    return null;
  }
  const [pageIndexResetSignal, setPageIndexResetSignal] = React.useState(false);
  const queryVariables: Query_RootRtcs_Gm_Dev_Ph_Grc_OfficerOwnerArgs = {
    where: {
      _and: [
        {
          _or: [
            { Companyid: { _eq: corporateID.toLowerCase() } },
            { Companyid: { _eq: corporateID.toUpperCase() } },
          ],
        },
      ],
    },
  };

  const {
    data: responseData,
    loading,
    error,
  } = useGrcOfficerOwnerPaginatedQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !queryVariables,
    variables: {
      params: jsonToStringQueryVariables(queryVariables ?? {}),
      aggregateParams: jsonToStringQueryVariables({
        where: queryVariables?.where,
      }),
    },
  });

  const [exportDataFn, { loading: exportLoading, error: exportError }] =
    useExportData(TABLE_NAME, queryVariables);

  useAppErrorHandler(error || exportError);

  const officersOwnersdata = React.useMemo(
    () => responseData?.rtcs_gm_dev_Fngrc_OfficerOwner ?? [],
    [responseData],
  );

  const columns = useMemo(() => TableColumns, []);

  return (
    <>
      <SC.ProgressIndicator open={loading} />
      <SC.Table
        title="Owners/Officers"
        actionsOnRight={[
          "fullscreen-expand",
          "hide/show-columns",
          "export-to-excel-sheet/csv",
        ]}
        // eslint-disable-next-line no-alert
        onAction={() => alert("under construction")}
        columns={columns}
        data={officersOwnersdata}
        stickyHeader
        persistenceId={generatePersistentId(
          "0a74e9fc-b70c-44c4-a3fd-6c4b193cd68b",
        )}
        exportData={exportDataFn}
        pageIndexResetSignal={pageIndexResetSignal}
      />
    </>
  );
};

export default OwnersOfficersTab;
