import { TableColumnDef } from "components/table/table.component";
import {
  PropertyRatesRegisterFragment,
  Rtcs_Gm_Dev_Ph_Bcc_PropertyRatesRegister,
} from "graphql/hasura/types-and-hooks";
import _ from "lodash";
import { AccessorFn } from "@tanstack/react-table";
import { FilterAccessorValue } from "pages/reports/utils";

export type PropertyRatesRegisterSchema = Omit<
  PropertyRatesRegisterFragment,
  "__typename"
>;

export interface PropertyRatesRegisterTableFilters {
  address: string;
  businessName: string;
  firstName: string;
  phoneNumber: string;
  surname: string;
}

export const DEFAULT_FILTER_ACCESSOR: Record<
  keyof PropertyRatesRegisterTableFilters,
  FilterAccessorValue<Rtcs_Gm_Dev_Ph_Bcc_PropertyRatesRegister>
> = {
  address: ["Streetname", "Streetnumber"],
  businessName: ["Currentowner", "Particulars"],
  firstName: ["Currentowner", "Particulars"],
  phoneNumber: ["Contactno", "Sm"],
  surname: ["Currentowner", "Particulars"],
};

export const PropertyRatesRegisterSchemaMap: Record<
  keyof PropertyRatesRegisterSchema,
  "string" | "number" | "Date"
> = {
  Contactno: "string",
  Currentowner: "string",
  Particulars: "string",
  Streetname: "string",
  Streetnumber: "string",
  Sm: "string",
};

export const getAccessorType = (
  accessor:
    | keyof PropertyRatesRegisterSchema
    | AccessorFn<PropertyRatesRegisterSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return PropertyRatesRegisterSchemaMap[accessor];
};

export const columns: TableColumnDef<PropertyRatesRegisterSchema>[] = [
  {
    header: "Property Owner",
    accessorKey: "Particulars",
    align: "left",
    format: "string",
  },
  {
    header: "Property Manager",
    accessorKey: "Currentowner",
    align: "left",
    format: "string",
  },
  {
    header: "Property Owner Phone Number",
    accessorKey: "Contactno",
    align: "left",
    format: "string",
  },
  {
    header: "Street Number",
    accessorKey: "Streetnumber",
    align: "left",
    format: "string",
  },
  {
    header: "Street Name",
    accessorKey: "Streetname",
    align: "left",
    format: "string",
  },
  {
    header: "Rates Payer Phone Number",
    accessorKey: "Sm",
    align: "left",
    format: "string",
  },
];
