import React from "react";

// Components
import { NationalIdAndResidentialPermitRegistrationsTable } from "pages/reports/gid/national-id-and-residential-permit-registrations-table/national-id-and-residential-permit-registrations-table.component";

// Assets
import AccordionList from "components/accordion-list/accordion-list.component";

interface Filters {
  address: string;
  businessName: string;
  email: string;
  firstName: string;
  idNumber: string;
  phoneNumber: string;
  plusCode: string;
  surname: string;
}

interface GidTabProps {
  defaultFilters?: Filters;
  initialRowsPerPage?: number;
  maxHeight?: number;
  noDataComponent?: JSX.Element;
}

export const GidTab: React.FC<GidTabProps> = React.memo(
  ({ defaultFilters, initialRowsPerPage, maxHeight, noDataComponent }) => {
    const reportSections = [
      {
        title: "Gambia Immigration Department",
        accordions: [
          {
            content: (
              <NationalIdAndResidentialPermitRegistrationsTable
                defaultFilters={defaultFilters}
                initialRowsPerPage={initialRowsPerPage}
                maxHeight={maxHeight}
                noDataComponent={noDataComponent}
                persistenceId="ba5dd205-36a0-4cbc-a305-3a27e913489f"
              />
            ),
            subtitle: "National ID and Residential Permit Registrations",
          },
        ],
      },
    ];

    return <AccordionList defaultExpanded sections={reportSections} />;
  },
);
