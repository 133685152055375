import { TableColumnDef } from "components/table/table.component";
import { AccessorFn } from "@tanstack/react-table";
import _ from "lodash";
import { GraCommercialResidentialTaxFragment } from "graphql/hasura/types-and-hooks";

export type CommercialResidentialTaxSchema = Omit<
  GraCommercialResidentialTaxFragment,
  "__typename"
>;

export const COMMERCIAL_RESIDENTIAL_TAX_SCHEMA_MAP: Record<
  keyof CommercialResidentialTaxSchema,
  "string" | "number" | "Date"
> = {
  Fname: "string",
  Lname: "string",
  Paidamount: "number",
  Taxcode: "number",
  Taxmonth: "number",
  Taxname: "string",
  Taxyear: "number",
  Tin: "number",
  Uuid: "string",
};

export const getAccessorType = (
  accessor:
    | keyof CommercialResidentialTaxSchema
    | AccessorFn<CommercialResidentialTaxSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return COMMERCIAL_RESIDENTIAL_TAX_SCHEMA_MAP[accessor];
};

export const tableColumns: TableColumnDef<CommercialResidentialTaxSchema>[] = [
  {
    header: "UUID",
    accessorKey: "Uuid",
    align: "left",
    format: "string",
  },
  {
    header: "TIN No.",
    accessorKey: "Tin",
    align: "left",
    format: "string",
  },
  {
    header: "First Name",
    accessorKey: "Fname",
    align: "left",
    format: "string",
  },
  {
    header: "Last Name",
    accessorKey: "Lname",
    align: "left",
    format: "string",
  },
  {
    header: "Tax Name",
    accessorKey: "Taxname",
    align: "left",
    format: "string",
  },
  {
    header: "Tax Code",
    accessorKey: "Taxcode",
    align: "left",
    format: "string",
  },
  {
    header: "Paid Amount",
    accessorKey: "Paidamount",
    align: "right",
    format: "number",
  },
  {
    header: "Tax Year",
    accessorKey: "Taxyear",
    align: "left",
    format: "string",
  },
  {
    header: "Tax Month",
    accessorKey: "Taxmonth",
    align: "left",
    format: "string",
  },
];
