import { TableColumnDef } from "components/table/table.component";
import { AccessorFn } from "@tanstack/react-table";
import _ from "lodash";
import {
  GraRentalOfficeFragment,
  Rtcs_Gm_Dev_Ph_Gra_RentalOffice,
} from "graphql/hasura/types-and-hooks";
import { FilterAccessorValue } from "pages/reports/utils";

export type GraRentalOfficeSchema = Omit<GraRentalOfficeFragment, "__typename">;

export interface RentalOfficeTableFilters {
  address: string;
  businessName: string;
  firstName: string;
  idNumber: string;
  phoneNumber: string;
  surname: string;
}

export const DEFAULT_FILTER_ACCESSOR: Record<
  keyof RentalOfficeTableFilters,
  FilterAccessorValue<Rtcs_Gm_Dev_Ph_Gra_RentalOffice>
> = {
  address: ["Address", "Location"],
  businessName: ["Taxpayerfname", "Taxpayermname", "Taxpayerlname"],
  firstName: ["Taxpayerfname"],
  idNumber: ["Taxpayertin"],
  phoneNumber: ["Phone1", "Phone2"],
  surname: ["Taxpayerlname"],
};

export const GRA_RENTAL_OFFICE_SCHEMA_MAP: Record<
  keyof GraRentalOfficeSchema,
  "string" | "number" | "Date"
> = {
  Taxpayertin: "string",
  Taxpayerfname: "string",
  Taxpayermname: "string",
  Taxpayerlname: "string",
  Phone1: "string",
  Phone2: "string",
  Address: "string",
  Location: "string",
  Property: "string",
  Taxtype: "string",
  Status: "string",
};

export const getAccessorType = (
  accessor:
    | keyof GraRentalOfficeSchema
    | AccessorFn<GraRentalOfficeSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;
  return GRA_RENTAL_OFFICE_SCHEMA_MAP[accessor];
};

export const tableColumns: TableColumnDef<GraRentalOfficeSchema>[] = [
  {
    header: "TIN",
    accessorKey: "Taxpayertin",
    align: "left",
    format: "string",
  },
  {
    header: "First Name",
    accessorKey: "Taxpayerfname",
    align: "left",
    format: "string",
  },
  {
    header: "Middle Name",
    accessorKey: "Taxpayermname",
    align: "left",
    format: "string",
  },
  {
    header: "Last Name",
    accessorKey: "Taxpayerlname",
    align: "left",
    format: "string",
  },
  {
    header: "Phone 1",
    accessorKey: "Phone1",
    align: "left",
    format: "string",
  },
  {
    header: "Phone 2",
    accessorKey: "Phone2",
    align: "left",
    format: "string",
  },
  {
    header: "Address",
    accessorKey: "Address",
    align: "left",
    format: "string",
  },
  {
    header: "Location",
    accessorKey: "Location",
    align: "left",
    format: "string",
  },
  {
    header: "Property Type",
    accessorKey: "Property",
    align: "left",
    format: "string",
  },
  {
    header: "Tax Type",
    accessorKey: "Taxtype",
    align: "left",
    format: "string",
  },
  {
    header: "Status",
    accessorKey: "Status",
    align: "left",
    format: "string",
  },
];
