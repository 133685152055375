import { TableColumnDef } from "components/table/table.component";
import { AccessorFn } from "@tanstack/react-table";
import _ from "lodash";
import { GraIndividualReturnFragment } from "graphql/hasura/types-and-hooks";

export type IndividualReturnSchema = Omit<
  GraIndividualReturnFragment,
  "__typename"
>;

export const INDIVIDUAL_RETURNS_SCHEMA_MAP: Record<
  keyof IndividualReturnSchema,
  "string" | "number" | "Date"
> = {
  Accountingmethod: "string",
  Allowance: "number",
  Bonus: "number",
  Businessactivity: "string",
  Businessaddress: "string",
  Businessnature: "string",
  Capitalallowance: "number",
  Closingstock: "number",
  Commercialrent: "number",
  Commission: "number",
  Costofsale: "number",
  Directtaxpaid: "number",
  Employeraddress: "string",
  Employertin: "string",
  Employertin1: "string",
  Employertin2: "string",
  Exemptincome: "number",
  Exporter: "number",
  Foreigntaxcredit: "number",
  Fringebenefit: "number",
  Fringebenefittax: "number",
  Grossemolument: "number",
  Importer: "number",
  Instalmentpayment: "number",
  Lossbroughtforward: "number",
  Netbusinessincome: "number",
  Openingstock: "number",
  Other: "number",
  Otherdeduction: "number",
  Otherexepense: "number",
  Otherincome: "number",
  Overpayment: "number",
  Overtime: "number",
  Pension: "number",
  Positionheld: "string",
  Principalbusiness: "string",
  Purchase: "number",
  Returngroup: "number",
  Returnid: "string",
  Salarywages: "number",
  Sicexemption: "number",
  Subtotal: "number",
  Subtotal1: "number",
  Tax1stschedule: "number",
  Taxpayerfname: "string",
  Taxpayerlname: "string",
  Taxpayermname: "string",
  Taxpayertin: "number",
  Totalnetincome: "number",
  Turnovertax: "number",
  Unallowwablededuction: "number",
  Withholdingtaxcredit: "number",
  Turnover: "number",
  Returnsbasetin: "string",
};

export const getAccessorType = (
  accessor:
    | keyof IndividualReturnSchema
    | AccessorFn<IndividualReturnSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return INDIVIDUAL_RETURNS_SCHEMA_MAP[accessor];
};

export const tableColumns: TableColumnDef<IndividualReturnSchema>[] = [
  {
    header: "Return ID",
    accessorKey: "Returnid",
    align: "left",
    format: "string",
  },
  {
    header: "Position Held",
    accessorKey: "Positionheld",
    align: "left",
    format: "string",
  },
  {
    header: "Taxpayer First Name",
    accessorKey: "Taxpayerfname",
    align: "left",
    format: "string",
  },
  {
    header: "Taxpayer Middle Name",
    accessorKey: "Taxpayermname",
    align: "left",
    format: "string",
  },
  {
    header: "Taxpayer Last Name",
    accessorKey: "Taxpayerlname",
    align: "left",
    format: "string",
  },
  {
    header: "Taxpayer TIN",
    accessorKey: "Taxpayertin",
    align: "left",
    format: "string",
  },
  {
    header: "Business Nature",
    accessorKey: "Businessnature",
    align: "left",
    format: "string",
  },
  {
    header: "Employer Address",
    accessorKey: "Employeraddress",
    align: "left",
    format: "string",
  },
  {
    header: "Employer TIN",
    accessorKey: "Employertin",
    align: "left",
    format: "string",
  },
  {
    header: "Business Address",
    accessorKey: "Businessaddress",
    align: "left",
    format: "string",
  },
  {
    header: "Business Activity",
    accessorKey: "Businessactivity",
    align: "left",
    format: "string",
  },
  {
    header: "Principal Business",
    accessorKey: "Principalbusiness",
    align: "left",
    format: "string",
  },
  {
    header: "Accounting Method",
    accessorKey: "Accountingmethod",
    align: "left",
    format: "string",
  },
  {
    header: "Salary Wages",
    accessorKey: "Salarywages",
    align: "right",
    format: "number",
  },
  {
    header: "Allowance",
    accessorKey: "Allowance",
    align: "right",
    format: "number",
  },
  {
    header: "Fringe Benefit",
    accessorKey: "Fringebenefit",
    align: "right",
    format: "number",
  },
  {
    header: "Overtime",
    accessorKey: "Overtime",
    align: "right",
    format: "number",
  },
  {
    header: "Bonus",
    accessorKey: "Bonus",
    align: "right",
    format: "number",
  },
  {
    header: "Commission",
    accessorKey: "Commission",
    align: "right",
    format: "number",
  },
  {
    header: "Other",
    accessorKey: "Other",
    align: "right",
    format: "number",
  },
  {
    header: "Gross Emolument",
    accessorKey: "Grossemolument",
    align: "right",
    format: "number",
  },
  {
    header: "Turn Over",
    accessorKey: "Turnover",
    align: "right",
    format: "number",
  },
  {
    header: "Fringe Benefit Tax",
    accessorKey: "Fringebenefittax",
    align: "right",
    format: "number",
  },
  {
    header: "Other Expense",
    accessorKey: "Otherexepense",
    align: "right",
    format: "number",
  },
  {
    header: "Net Business Income",
    accessorKey: "Netbusinessincome",
    align: "right",
    format: "number",
  },
  {
    header: "Sub Total 1",
    accessorKey: "Subtotal1",
    align: "right",
    format: "number",
  },
  {
    header: "Direct Tax Paid",
    accessorKey: "Directtaxpaid",
    align: "right",
    format: "number",
  },
  {
    header: "Pension",
    accessorKey: "Pension",
    align: "right",
    format: "number",
  },
  {
    header: "Commercial Rent",
    accessorKey: "Commercialrent",
    align: "right",
    format: "number",
  },
  {
    header: "Other Income",
    accessorKey: "Otherincome",
    align: "right",
    format: "number",
  },
  {
    header: "Sub Total",
    accessorKey: "Subtotal",
    align: "right",
    format: "number",
  },
  {
    header: "Unallowable Deduction",
    accessorKey: "Unallowwablededuction",
    align: "right",
    format: "number",
  },
  {
    header: "Exempt Income",
    accessorKey: "Exemptincome",
    align: "right",
    format: "number",
  },
  {
    header: "Capital Allowance",
    accessorKey: "Capitalallowance",
    align: "right",
    format: "number",
  },
  {
    header: "Other Deduction",
    accessorKey: "Otherdeduction",
    align: "right",
    format: "number",
  },
  {
    header: "Loss Brought Forward",
    accessorKey: "Lossbroughtforward",
    align: "right",
    format: "number",
  },
  {
    header: "Tax 1st Schedule",
    accessorKey: "Tax1stschedule",
    align: "right",
    format: "number",
  },
  {
    header: "Turn Over Tax",
    accessorKey: "Turnovertax",
    align: "right",
    format: "number",
  },
  {
    header: "Over Payment",
    accessorKey: "Overpayment",
    align: "right",
    format: "number",
  },
  {
    header: "Foreign Tax Credit",
    accessorKey: "Foreigntaxcredit",
    align: "right",
    format: "number",
  },
  {
    header: "Withholding Tax Credit",
    accessorKey: "Withholdingtaxcredit",
    align: "right",
    format: "number",
  },
  {
    header: "Instalment Payment",
    accessorKey: "Instalmentpayment",
    align: "right",
    format: "number",
  },
  {
    header: "Importer",
    accessorKey: "Importer",
    align: "right",
    format: "number",
  },
  {
    header: "Exporter",
    accessorKey: "Exporter",
    align: "right",
    format: "number",
  },
  {
    header: "Employer TIN 1",
    accessorKey: "Employertin1",
    align: "left",
    format: "string",
  },
  {
    header: "Employer TIN 2",
    accessorKey: "Employertin2",
    align: "left",
    format: "string",
  },
  {
    header: "Return Group",
    accessorKey: "Returngroup",
    align: "right",
    format: "number",
  },
  {
    header: "SIC Exemption",
    accessorKey: "Sicexemption",
    align: "right",
    format: "number",
  },
  {
    header: "Opening Stock",
    accessorKey: "Openingstock",
    align: "right",
    format: "number",
  },
  {
    header: "Purchase",
    accessorKey: "Purchase",
    align: "right",
    format: "number",
  },
  {
    header: "Closing Stock",
    accessorKey: "Closingstock",
    align: "right",
    format: "number",
  },
  {
    header: "Cost Of Sale",
    accessorKey: "Costofsale",
    align: "right",
    format: "number",
  },
  {
    header: "Total Net Income",
    accessorKey: "Totalnetincome",
    align: "right",
    format: "number",
  },
];
