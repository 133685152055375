import { Paper, Grid, Container as ContainerBase } from "@mui/material";

import { styled } from "@mui/material/styles";

import PropertyDetailsBase from "components/property-details/property-details.component";
import ImageList from "components/image-list/image-list.component";

const Container = styled(ContainerBase)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: "0px",
  overflowY: "auto",
  height: "100%",
  fontWeight: theme.typography.fontWeightMedium,
}));

const PropertyDetails = styled(PropertyDetailsBase)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
}));

export default {
  Paper,
  Grid,
  PropertyDetails,
  ImageList,
  Container,
};
