/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState } from "react";
import {
  Routes,
  Route,
  RouteProps,
  useNavigate,
  useLocation,
  useResolvedPath,
} from "react-router-dom";
import { useWindowHeight } from "@react-hook/window-size";
import _ from "lodash";

// Components
import Breadcrumb from "components/breadcrumb/breadcrumb.component";
import NoRecordsStatement from "components/table-no-records-statement/table-no-records-statement.component";
import TextField from "components/text-field/text-field.component";
import TabSubHeader from "components/tab-sub-header/tab-sub-header.component";
import { INITIAL_ROWS_PER_PAGE } from "components/table/table.component";
import { Africa360ViewTab } from "../africa-360-view-tab/africa-360-view-tab.component";
import { BccTab } from "../bcc-tab/bcc-tab.component";
import { KmcTab } from "../kmc-tab/kmc-tab.component";
import { GbosTab } from "../gbos-tab/gbos-tab.component";
import { GidTab } from "../gid-tab/gid-tab.component";
import { GraTab } from "../gra-tab/gra-tab.component";
import { PuraTab } from "../pura-tab/pura-tab.component";
import { GrcTab } from "../grc-tab/grc-tab.component";
import { NawecTab } from "../nawec-tab/nawec-tab.component";

// Assets
import SC from "./main.styles";

export interface Filters {
  address: string;
  businessName: string;
  email: string;
  firstName: string;
  idNumber: string;
  phoneNumber: string;
  plusCode: string;
  surname: string;
}

interface DataByCriteriaFiltersProps {
  onChange: (filters: Filters) => void;
}

const emptyFilters: Filters = {
  phoneNumber: "",
  email: "",
  surname: "",
  firstName: "",
  businessName: "",
  plusCode: "",
  address: "",
  idNumber: "",
};

const TABLE_MAX_HEIGHT = 550;
const NO_RECORDS_STATEMENT = <NoRecordsStatement />;

const DataByCriteriaFilters: React.FC<DataByCriteriaFiltersProps> = ({
  onChange,
}) => {
  const [formData, setFormData] = useState<Filters>(emptyFilters);
  const [searchedFormData, setSearchedFormData] =
    useState<Filters>(emptyFilters);

  const handleOnChange = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      setFormData((currentData) => ({
        ...currentData,
        [event.target.name]: event.target.value,
      }));
      event.preventDefault();
    },
    [],
  );

  const handleSubmit = useCallback(
    (event: React.ChangeEvent<HTMLFormElement>): void => {
      onChange(formData);
      setSearchedFormData(formData);
      event.preventDefault();
    },
    [formData, onChange],
  );

  const isButtonDisabled =
    Object.values(formData).every((value) => value === "") ||
    (Object.keys(formData) as Array<keyof Filters>).every(
      (key) => formData[key] === searchedFormData[key],
    );

  return (
    <SC.FilterBox p={2}>
      <SC.FormControl component="form" onSubmit={handleSubmit}>
        <SC.Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="start"
        >
          <SC.Grid item xs={12} sm={4} md={2.4}>
            <TextField
              fullWidth
              id="phoneNumber"
              name="phoneNumber"
              label="Phone Number"
              onChange={handleOnChange}
            />
          </SC.Grid>
          <SC.Grid item xs={12} sm={4} md={2.4}>
            <TextField
              fullWidth
              id="email"
              name="email"
              label="Email"
              autoComplete="email"
              onChange={handleOnChange}
            />
          </SC.Grid>
          <SC.Grid item xs={12} sm={4} md={2.4}>
            <TextField
              fullWidth
              id="surname"
              name="surname"
              label="Surname"
              onChange={handleOnChange}
            />
          </SC.Grid>
          <SC.Grid item xs={12} sm={4} md={2.4}>
            <TextField
              fullWidth
              id="firstName"
              name="firstName"
              label="First Name"
              onChange={handleOnChange}
            />
          </SC.Grid>
          <SC.Grid item xs={12} sm={4} md={2.4}>
            <TextField
              fullWidth
              id="businessName"
              name="businessName"
              label="Business Name"
              onChange={handleOnChange}
            />
          </SC.Grid>
          <SC.Grid item xs={12} sm={4} md={2.4}>
            <TextField
              fullWidth
              id="plusCode"
              name="plusCode"
              label="Plus Code"
              onChange={handleOnChange}
            />
          </SC.Grid>
          <SC.Grid item xs={12} sm={4} md={2.4}>
            <TextField
              fullWidth
              id="address"
              name="address"
              label="Address"
              onChange={handleOnChange}
            />
          </SC.Grid>
          <SC.Grid item xs={12} sm={4} md={2.4}>
            <TextField
              fullWidth
              id="idNumber"
              name="idNumber"
              label="ID Number"
              onChange={handleOnChange}
            />
          </SC.Grid>
          <SC.Grid item xs={12} sm={4} md={2.4} marginLeft={"auto"}>
            <SC.FilterButton disabled={isButtonDisabled} type="submit">
              Search MDA Data
            </SC.FilterButton>
          </SC.Grid>
        </SC.Grid>
      </SC.FormControl>
    </SC.FilterBox>
  );
};

const DataByCriteria: React.FC = () => {
  const navigate = useNavigate();
  const defaultRoute = "africa-360-view";
  const [tabContainerHeight, setTabContainerHeight] = useState<
    number | undefined
  >(undefined);
  const listSubHeaderRef = React.useRef<HTMLLIElement>(null);
  const windowHeight = useWindowHeight();
  const [filters, setFilters] = useState<Filters>(emptyFilters);
  const basePath = useResolvedPath(".").pathname;
  const location = useLocation();
  const tabContainerPosition =
    listSubHeaderRef.current?.getBoundingClientRect();
  const tabContainerPositionY = tabContainerPosition?.bottom;

  const onChangeFilters = useCallback(
    (currentFilters: Filters): void => {
      setFilters(currentFilters);
    },
    [setFilters],
  );

  const areFiltersEmpty = Object.values(filters).every((value) => value === "");

  const tabsData = [
    { label: "GRA", path: "gra", value: 0 },
    { label: "Africa 360 View", path: "africa-360-view", value: 1 },
    { label: "BCC", path: "bcc", value: 2 },
    { label: "KMC", path: "kmc", value: 3 },
    { label: "NAWEC", path: "nawec", value: 4 },
    { label: "GBOS", path: "gbos", value: 5 },
    { label: "GID", path: "gid", value: 6 },
    { label: "PURA", path: "pura", value: 7 }, 
    { label: "GRC", path: "grc", value: 8 },
  ];

  const routes: RouteProps[] = [
    {
      path: "gra",
      element: (
        <GraTab
          defaultFilters={filters}
          initialRowsPerPage={INITIAL_ROWS_PER_PAGE.REPORTS}
          maxHeight={TABLE_MAX_HEIGHT}
          noDataComponent={NO_RECORDS_STATEMENT}
        />
      ),
    },
    {
      path: "africa-360-view",
      element: (
        <Africa360ViewTab
          defaultFilters={filters}
          initialRowsPerPage={INITIAL_ROWS_PER_PAGE.REPORTS}
          maxHeight={TABLE_MAX_HEIGHT}
          noDataComponent={NO_RECORDS_STATEMENT}
        />
      ),
    },
    {
      path: "bcc",
      element: (
        <BccTab
          defaultFilters={filters}
          initialRowsPerPage={INITIAL_ROWS_PER_PAGE.REPORTS}
          maxHeight={TABLE_MAX_HEIGHT}
          noDataComponent={NO_RECORDS_STATEMENT}
        />
      ),
    },
    {
      path: "kmc",
      element: (
        <KmcTab
          defaultFilters={filters}
          initialRowsPerPage={INITIAL_ROWS_PER_PAGE.REPORTS}
          maxHeight={TABLE_MAX_HEIGHT}
          noDataComponent={NO_RECORDS_STATEMENT}
        />
      ),
    },
    {
      path: "nawec",
      element: (
        <NawecTab
          defaultFilters={filters}
          initialRowsPerPage={INITIAL_ROWS_PER_PAGE.REPORTS}
          maxHeight={TABLE_MAX_HEIGHT}
          noDataComponent={NO_RECORDS_STATEMENT}
        />
      ),
    },
    {
      path: "gbos",
      element: (
        <GbosTab
          defaultFilters={filters}
          initialRowsPerPage={INITIAL_ROWS_PER_PAGE.REPORTS}
          maxHeight={TABLE_MAX_HEIGHT}
          noDataComponent={NO_RECORDS_STATEMENT}
        />
      ),
    },
    {
      path: "gid",
      element: (
        <GidTab
          defaultFilters={filters}
          initialRowsPerPage={INITIAL_ROWS_PER_PAGE.REPORTS}
          maxHeight={TABLE_MAX_HEIGHT}
          noDataComponent={NO_RECORDS_STATEMENT}
        />
      ),
    },
    {
      path: "pura",
      element: (
        <PuraTab
          defaultFilters={filters}
          initialRowsPerPage={INITIAL_ROWS_PER_PAGE.REPORTS}
          maxHeight={TABLE_MAX_HEIGHT}
          noDataComponent={NO_RECORDS_STATEMENT}
        />
      ),
    },
    {
      path: "grc",
      element: (
        <GrcTab
          defaultFilters={filters}
          initialRowsPerPage={INITIAL_ROWS_PER_PAGE.REPORTS}
          maxHeight={TABLE_MAX_HEIGHT}
          noDataComponent={NO_RECORDS_STATEMENT}
        />
      ),
    },
  ];
  useEffect(() => {
    const newPath = `${basePath}/${defaultRoute}`;
    if (!areFiltersEmpty && newPath !== location.pathname) {
      navigate(newPath);
    }
  }, [areFiltersEmpty, basePath]);
  useEffect(() => {
    if (areFiltersEmpty && basePath !== location.pathname) {
      navigate(basePath, { replace: true });
    } else if (!areFiltersEmpty && basePath === location.pathname) {
      const newPath = `${basePath}/${defaultRoute}`;
      navigate(newPath);
    }
  }, [areFiltersEmpty, basePath, location.pathname]);
  useEffect(() => {
    if (!areFiltersEmpty && tabContainerPositionY !== undefined) {
      setTabContainerHeight(windowHeight - tabContainerPositionY);
    }
  }, [windowHeight, tabContainerPositionY, areFiltersEmpty]);

  return (
    <SC.Container>
      <SC.Box pl={2} pr={2} pt={2}>
        <Breadcrumb />
        <DataByCriteriaFilters onChange={onChangeFilters} />
      </SC.Box>
      {areFiltersEmpty ? undefined : (
        <SC.ReportsBox>
          <SC.ListSubheader ref={listSubHeaderRef}>
            <TabSubHeader tabs={tabsData} />
          </SC.ListSubheader>
          {tabContainerHeight === undefined ? undefined : (
            <SC.TabsContainer maxWidth={false} maxHeight={tabContainerHeight}>
              <Routes>
                {routes.map((route, index) =>
                  Array.isArray(route.path) ? (
                    route.path.map((p: string) => (
                      <Route
                        key={`${index}-${p}`}
                        path={p}
                        element={route.element}
                      />
                    ))
                  ) : (
                    <Route
                      key={index}
                      path={route.path}
                      element={route.element}
                    />
                  ),
                )}
                <Route path="*" element={<ContentNotFound />} />
              </Routes>
            </SC.TabsContainer>
          )}
        </SC.ReportsBox>
      )}
    </SC.Container>
  );
};

const ContentNotFound: React.FC = () => (
  <div>
    <h1>Content Not Found</h1>
  </div>
);

export default DataByCriteria;