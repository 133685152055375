import React from "react";

// GraphQL
import { Rtcs_Gm_Dev_Ph_Grc_Corporate } from "graphql/hasura/types-and-hooks";

// Components
import { FORMAT_VALUE_OPTIONS } from "components/formatted-value/formatted-value";
import {
  AccordionHeader,
  Header,
  Content,
} from "components/accordion-header/accordion-header.component";

export interface CorporateFields {
  label: string;
  accessorKey?: string;
  format?: keyof typeof FORMAT_VALUE_OPTIONS;
}

export const identifiers: CorporateFields[] = [
  { label: "Type", accessorKey: "Type" },
  { label: "Name", accessorKey: "Companyname" },
  {
    label: "Registration Date",
    accessorKey: "Dateofincorporation",
    format: "date",
  },
  { label: "Status", accessorKey: "Statusname" },
  { label: "Corporate ID", accessorKey: "Companyid" },
  { label: "Corporate Number", accessorKey: "Companynumber" },
  { label: "Tax ID", accessorKey: "Taxid" },
];
export const physicalAddress: CorporateFields[] = [
  { label: "Physical Address", accessorKey: "Principaladdress" },
  { label: "Address Line 1", accessorKey: "Principaladdressline1" },
  { label: "City", accessorKey: "Principaladdresscity" },
  { label: "District", accessorKey: "Principaladdressdistrict" },
  { label: "Region", accessorKey: "Principaladdressregion" },
  { label: "Country", accessorKey: "Principaladdresscountry" },
];

export const contactInfoAndRelated: CorporateFields[] = [
  { label: "Telephone", accessorKey: "Telephone" },
  { label: "Telephone 2", accessorKey: "Telephone2" },
  { label: "Email", accessorKey: "Companyemail" },
  { label: "Fax", accessorKey: "Fax" },
];

export interface CorporateTabHeaderProps {
  corporate: Rtcs_Gm_Dev_Ph_Grc_Corporate;
}

export const CorporateTabHeader: React.FC<CorporateTabHeaderProps> = ({
  corporate,
}) => {
  if (!corporate) {
    return null;
  }

  const header: Header[] = [
    {
      index: 0,
      label: `${corporate.Companyname}`,
      type: "bold",
    },
    { index: 1, label: "ID", type: "bold" },
    { index: 2, label: corporate.Companyid ?? "" },
  ];

  const mapFunction = (info: CorporateFields, index: number) => {
    const { accessorKey } = info;
    let value = "";

    if (accessorKey)
      value = corporate?.[accessorKey as keyof Rtcs_Gm_Dev_Ph_Grc_Corporate];

    return { ...info, index, value };
  };
  const content: Content[] = [
    {
      index: 0,
      title: "Names and Identifiers",
      data: identifiers.map(mapFunction),
    },
    {
      index: 1,
      title: "Physical Address",
      data: physicalAddress.map(mapFunction),
    },
    {
      index: 2,
      title: "Contact Info and Related",
      data: contactInfoAndRelated.map(mapFunction),
    },
  ];

  return (
    <AccordionHeader
      header={header}
      content={content}
      source="GRC"
      isLoading={false}
    />
  );
};

export default CorporateTabHeader;
