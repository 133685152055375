import React from "react";

// Components
import { TradeLicenseRegisterTable } from "pages/reports/kmc/trade-license-register-table/trade-license-register-table.component";

// Assets
import AccordionList from "components/accordion-list/accordion-list.component";

// Hooks
import useTablesLoaded from "hooks/useTablesLoaded";
import { PropertyRatesRegisterTable } from "pages/reports/kmc/property-rates-register-table/property-rates-register-table.component";

interface Filters {
  address: string;
  businessName: string;
  email: string;
  firstName: string;
  idNumber: string;
  phoneNumber: string;
  plusCode: string;
  surname: string;
}

interface KmcTabProps {
  defaultFilters?: Filters;
  initialRowsPerPage?: number;
  maxHeight?: number;
  noDataComponent?: JSX.Element;
}

export const KmcTab: React.FC<KmcTabProps> = React.memo(
  ({ defaultFilters, initialRowsPerPage, maxHeight, noDataComponent }) => {
    const { tablesLoaded, renderTable } = useTablesLoaded({
      tradeLicenseRegister: false,
    });
    const reportSections = [
      {
        title: "Kanifing Municipal Council",
        accordions: [
          {
            content: (
              <PropertyRatesRegisterTable
                defaultFilters={defaultFilters}
                initialRowsPerPage={initialRowsPerPage}
                maxHeight={maxHeight}
                noDataComponent={noDataComponent}
                persistenceId="d6da010e-9ef9-4149-98e8-c92375c9208b"
              />
            ),
            subtitle: "Property Rates Register",
          },
          {
            // shouldRenderBody: tablesLoaded.tradeLicenseRegister,
            content: (
              <TradeLicenseRegisterTable
                defaultFilters={defaultFilters}
                initialRowsPerPage={initialRowsPerPage}
                maxHeight={maxHeight}
                noDataComponent={noDataComponent}
                persistenceId="d243153f-2192-436d-9baa-4da06ef37ddd"
              />
            ),
            subtitle: "Trade License Register",
          },
        ],
      },
    ];

    return <AccordionList defaultExpanded sections={reportSections} />;
  },
);
