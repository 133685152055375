export default [
  {
    title: "MDA Reports",
    reports: [
      {
        title: "All MDA Search",
        subtitles: [
          {
            label: "All MDA Search",
            url: "MDA/all-MDA-search",
          },
        ],
      },
      {
        title: "Gambia Revenue Authority",
        subtitles: [
          {
            label: "Corporate Tax Payer",
            url: "MDA/GRA/corporate-tax-payer",
          },
          {
            label: "Commercial-Residential Tax",
            url: "MDA/GRA/commercial-residential-tax",
          },
          {
            label: "Corporate Return",
            url: "MDA/GRA/corporate-return",
          },
          {
            label: "Corporate Return Director",
            url: "MDA/GRA/corporate-return-director",
          },
          {
            label: "Corporate Return Exemption",
            url: "MDA/GRA/corporate-return-exemption",
          },
          {
            label: "Corporate Return Loss",
            url: "MDA/GRA/corporate-return-loss",
          },
          {
            label: "Corporate Return Partnership",
            url: "MDA/GRA/corporate-return-partnership",
          },
          {
            label: "Corporate Return Property Rent",
            url: "MDA/GRA/corporate-return-property-rent",
          },
          {
            label: "Corporate Return Rent Received",
            url: "MDA/GRA/corporate-return-rent-received",
          },
          {
            label: "Individual Tax Payer",
            url: "MDA/GRA/individual-tax-payer",
          },
          {
            label: "Individual Return",
            url: "MDA/GRA/individual-return",
          },
          {
            label: "Individual Return Exemptions",
            url: "MDA/GRA/individual-return-exemptions",
          },
          {
            label: "Individual Return Loss",
            url: "MDA/GRA/individual-return-loss",
          },
          {
            label: "Individual Return Rent",
            url: "MDA/GRA/individual-return-rent",
          },
          {
            label: "Registered Associations",
            url: "MDA/GRA/registered-associations",
          },
          {
            label: "Payment Rental",
            url: "MDA/GRA/payment-rental",
          },
          {
            label: "Rental Office Data",
            url: "MDA/GRA/rental-office-data",
          },
        ],
      },
      {
        title: "Africa 360 View",
        subtitles: [
          {
            label: "Banjul Properties",
            url: "MDA/Africa-360-view/banjul-properties",
          },
          {
            label: "KMC Properties",
            url: "MDA/Africa-360-view/kanifing-properties",
          },
        ],
      },
      {
        title: "Banjul City Council",
        subtitles: [
          {
            label: "Property Rates Register",
            url: "MDA/BCC/property-rates-register",
          },
          {
            label: "Trade License Register",
            url: "MDA/BCC/trade-license-register",
          },
        ],
      },
      {
        title: "Kanifing Municipal Council",
        subtitles: [
          {
            label: "Property Rates Register",
            url: "MDA/KMC/property-rates-register",
          },
          {
            label: "Trade License Register",
            url: "MDA/KMC/trade-license-register",
          },
        ],
      },
      {
        title: "Gambia Registrar of Companies",
        subtitles: [
          {
            label: "Corporates to Owners/Officers",
            url: "MDA/GRC/coporates-to-owners-officers",
          },
        ],
      },
      {
        title: "National Water & Electricity Company",
        subtitles: [
          {
            label: "Water and Electricity Customers",
            url: "MDA/NAWEC/water-and-electricity-customers",
          },
        ],
      },
      {
        title: "Gambia Registry of Companies",
        subtitles: [
          {
            label: "Business Registrations",
            url: "MDA/GROC/business-registrations",
          },
        ],
      },
      {
        title: "Gambia Bureau of Statistics",
        subtitles: [
          {
            label: "2024 Population And Housing Census",
            url: "MDA/GBOS/2024-population-and-housing-census",
          },
          {
            label: "2022 Commercial Rental Rates Survey",
            url: "MDA/GBOS/2022-commercial-rental-rates-survey",
          },
          {
            label: "2024 Gambia Social Registry",
            url: "MDA/GBOS/2024-gambia-social-registry",
          },
        ],
      },
      {
        title: "Gambia Immigration Department",
        subtitles: [
          {
            label: "National ID and Residential Permit Registrations",
            url: "MDA/GID/national-id-and-residential-permit-registrations",
          },
        ],
      },
      {
        title: "Public Utilities Regulatory Authority",
        subtitles: [
          {
            label: "Mobile Carrier Subscriber Registrations",
            url: "MDA/PURA/mobile-carrier-subscriber-registrations",
          },
        ],
      },
    ],
  },
  {
    title: "Analytic Reports",
    reports: [],
  },
];
