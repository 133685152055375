import React from "react";
import { ApolloError, FetchResult } from "@apollo/client";
import _ from "lodash";

// GraphQL
import {
  DagsterPipelineRunStatus,
  Job,
  RunJobMutation,
  useGetJobStatusLazyQuery,
  useRunJobMutation,
} from "graphql/types-and-hooks";

const TIMER_TIMEOUT = 15000; // 15s

export type JobStatus = [
  () => Promise<
    FetchResult<
      RunJobMutation,
      Record<string, unknown>,
      Record<string, unknown>
    >
  >,
  {
    jobStatus: DagsterPipelineRunStatus | undefined;
    jobMessage: string;
    jobStatusLoading: boolean;
    jobStatusError: ApolloError | undefined;
    runJobLoading: boolean;
    jobRunning: boolean | undefined;
    runJobError: ApolloError | undefined;
  },
];

export const useJobStatus = (job: Job): JobStatus => {
  const [jobStatus, setJobStatus] = React.useState<
    DagsterPipelineRunStatus | undefined
  >();
  const [jobMessage, setJobMessage] = React.useState("");
  const [
    getJobStatus,
    { data: jobStatusData, loading: jobStatusLoading, error: jobStatusError },
  ] = useGetJobStatusLazyQuery({
    fetchPolicy: "no-cache",
    variables: { job },
  });
  const [runJob, { loading: runJobLoading, error: runJobError }] =
    useRunJobMutation({
      variables: { job },
    });

  React.useEffect(() => {
    const timer = window.setInterval(() => getJobStatus(), TIMER_TIMEOUT);
    getJobStatus();
    return () => window.clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (jobStatusData?.GetJobStatus) {
      const { status, message } = jobStatusData.GetJobStatus;

      setJobStatus(status);

      switch (status) {
        case DagsterPipelineRunStatus.Canceled:
        case DagsterPipelineRunStatus.NotStarted:
        case DagsterPipelineRunStatus.Success:
          setJobMessage(status);
          break;

        default:
          setJobMessage(
            `${status}${_.isEmpty(message) ? "" : ` - ${message}`}`,
          );
          break;
      }
    }
  }, [jobStatusData]);

  const runJobWrapper = () => {
    setJobStatus(undefined);
    return runJob();
  };

  const jobRunning =
    jobStatus &&
    !(
      jobStatus === DagsterPipelineRunStatus.Canceled ||
      jobStatus === DagsterPipelineRunStatus.Failure ||
      jobStatus === DagsterPipelineRunStatus.NotStarted ||
      jobStatus === DagsterPipelineRunStatus.Success
    );

  return [
    runJobWrapper,
    {
      jobStatus,
      jobMessage,
      jobStatusLoading,
      jobStatusError,
      runJobLoading,
      runJobError,
      jobRunning,
    },
  ];
};

export default useJobStatus;
