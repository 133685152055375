import { TableColumnDef } from "components/table/table.component";
import { AccessorFn } from "@tanstack/react-table";
import _ from "lodash";
import {
  Gbos2022CommercialRentalRatesSurveyFragment,
  Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey,
} from "graphql/hasura/types-and-hooks";
import { FilterAccessorValue } from "pages/reports/utils";

export type Gbos2022CommercialRentalRatesSurveySchema = Omit<
  Gbos2022CommercialRentalRatesSurveyFragment,
  "__typename"
>;

export interface Gbos2022CommercialRentalRatesSurveyTableFilters {
  address: string;
  businessName: string;
  firstName: string;
  idNumber: string;
  phoneNumber: string;
  surname: string;
}

export const DEFAULT_FILTER_ACCESSOR: Record<
  keyof Gbos2022CommercialRentalRatesSurveyTableFilters,
  FilterAccessorValue<Rtcs_Gm_Dev_Ph_Gbos_2022CommercialRentalRatesSurvey>
> = {
  address: ["Address", "AddressOfLandlordagency", "AddressOfLandlordagent"],
  businessName: [
    "NameOfBusiness",
    "NameOfLandlordagent",
    "NameOfLandlordagentForOtherBusinessPropertyElsewhere",
    "PropertyOwneradministrator",
  ],
  firstName: [
    "NameOfLandlordagent",
    "NameOfLandlordagentForOtherBusinessPropertyElsewhere",
    "PropertyOwneradministrator",
  ],
  idNumber: ["IndividualId", "TinOfLandlordagent"],
  phoneNumber: [
    "BusinessTelephone",
    "ContactOfLandlordagent",
    "ContactPersonsTel",
    "SecondContact",
    "SecondTelOfContactPerson",
    "ThirdContact",
    "TelOfLandlordagent",
  ],
  surname: [
    "NameOfLandlordagent",
    "NameOfLandlordagentForOtherBusinessPropertyElsewhere",
    "PropertyOwneradministrator",
  ],
};

export const COMMERCIAL_RENTAL_RATES_SURVEY_SCHEMA_MAP: Record<
  keyof Gbos2022CommercialRentalRatesSurveySchema,
  "string" | "number" | "Date"
> = {
  Address: "string",
  AddressOfLandlordagency: "string",
  AddressOfLandlordagent: "string",
  AmountPaid: "number",
  AmountPaidPerPeriod: "number",
  AnyMore: "string",
  BusinessActivitytype: "string",
  BusinessTelephone: "string",
  ContactOfLandlordagent: "string",
  ContactPersonsTel: "string",
  CurrencyUnit: "string",
  DurationInMonths: "number",
  FrequencyOfPayment: "string",
  IndividualId: "string",
  Lga: "string",
  NameOfBusiness: "string",
  NameOfLandlordagent: "string",
  NameOfLandlordagentForOtherBusinessPropertyElsewhere: "string",
  OtherBusinessPropertyRentedElsewhere: "number",
  OtherBusinessPropertyRentedElsewhereStatus: "string",
  OtherSpecifiedFrequencyPaymentArrangement: "string",
  OwnerOccupiedEstimatedValueOfRent: "string",
  PaymentFrequency: "string",
  PositionOfRespondentcontactPerson: "string",
  PropertyOwneradministrator: "string",
  RentPeriodArrangement: "string",
  SecondContact: "string",
  SecondTelOfContactPerson: "string",
  TelOfLandlordagent: "string",
  ThirdContact: "string",
  TinOfLandlordagent: "string",
  Uuid: "string",
};

export const getAccessorType = (
  accessor:
    | keyof Gbos2022CommercialRentalRatesSurveySchema
    | AccessorFn<Gbos2022CommercialRentalRatesSurveySchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;
  return COMMERCIAL_RENTAL_RATES_SURVEY_SCHEMA_MAP[accessor];
};

export const tableColumns: TableColumnDef<Gbos2022CommercialRentalRatesSurveySchema>[] =
  [
    { header: "UUID", accessorKey: "Uuid", align: "left", format: "string" },
    {
      header: "Individual Identifier",
      accessorKey: "IndividualId",
      align: "left",
      format: "string",
    },
    { header: "LGA", accessorKey: "Lga", align: "left", format: "string" },
    {
      header: "Business Name",
      accessorKey: "NameOfBusiness",
      align: "left",
      format: "string",
    },
    {
      header: "Business Phone",
      accessorKey: "BusinessTelephone",
      align: "left",
      format: "string",
    },
    {
      header: "Business Address",
      accessorKey: "Address",
      align: "left",
      format: "string",
    },
    {
      header: "Business Type/Activity",
      accessorKey: "BusinessActivitytype",
      align: "left",
      format: "string",
    },
    {
      header: "Property Owner/Administrator Position",
      accessorKey: "PropertyOwneradministrator",
      align: "left",
      format: "string",
    },
    {
      header: "Landlord/Agent TIN",
      accessorKey: "TinOfLandlordagent",
      align: "left",
      format: "string",
    },
    {
      header: "Landlord/Agent Name",
      accessorKey: "NameOfLandlordagent",
      align: "left",
      format: "string",
    },
    {
      header: "Landlord/Agent Address",
      accessorKey: "AddressOfLandlordagency",
      align: "left",
      format: "string",
    },
    {
      header: "Landlord/Agent Phone 1",
      accessorKey: "ContactOfLandlordagent",
      align: "left",
      format: "string",
    },
    {
      header: "Landlord/Agent Phone 2",
      accessorKey: "SecondContact",
      align: "left",
      format: "string",
    },
    {
      header: "Landlord/Agent Phone 3",
      accessorKey: "ThirdContact",
      align: "left",
      format: "string",
    },
    {
      header: "Rent Paid Per Period",
      accessorKey: "AmountPaidPerPeriod",
      align: "right",
      format: "number",
    },
    {
      header: "Rent Currency Unit",
      accessorKey: "CurrencyUnit",
      align: "left",
      format: "string",
    },
    {
      header: "Rent Period",
      accessorKey: "RentPeriodArrangement",
      align: "left",
      format: "string",
    },
    {
      header: "Rent Payment Frequency",
      accessorKey: "FrequencyOfPayment",
      align: "left",
      format: "string",
    },
    {
      header: "Rent Payment Frequency - Other",
      accessorKey: "OtherSpecifiedFrequencyPaymentArrangement",
      align: "left",
      format: "string",
    },
    {
      header: "Owner Occupied Estimated Rent Value",
      accessorKey: "OwnerOccupiedEstimatedValueOfRent",
      align: "left",
      format: "string",
    },
    {
      header: "Other Business Property Rented Elsewhere?",
      accessorKey: "OtherBusinessPropertyRentedElsewhereStatus",
      align: "left",
      format: "string",
    },
    {
      header: "Count of Other Business Properties",
      accessorKey: "OtherBusinessPropertyRentedElsewhere",
      align: "right",
      format: "number",
    },
    {
      header: "Other Business Property Landlord/Agent Name",
      accessorKey: "NameOfLandlordagentForOtherBusinessPropertyElsewhere",
      align: "left",
      format: "string",
    },
    {
      header: "Other Business Property Landlord/Agent Address",
      accessorKey: "AddressOfLandlordagent",
      align: "left",
      format: "string",
    },
    {
      header: "Other Business Property Landlord/Agent Phone",
      accessorKey: "TelOfLandlordagent",
      align: "left",
      format: "string",
    },
    {
      header: "Other Business Property Rent Paid Per Period",
      accessorKey: "AmountPaid",
      align: "right",
      format: "number",
    },
    {
      header: "Other Business Property Rent Payment Frequency", 
      accessorKey: "PaymentFrequency", 
      align: "left", 
      format: "string" 
    },
    {
      header: "Occupancy Duration In Months",
      accessorKey: "DurationInMonths",
      align: "right",
      format: "number",
    },
    {
      header: "Any More",
      accessorKey: "AnyMore",
      align: "left",
      format: "string",
    },
    {
      header: "Contact Person Position",
      accessorKey: "PositionOfRespondentcontactPerson",
      align: "left",
      format: "string",
    },
    {
      header: "Contact Person Phone 1",
      accessorKey: "ContactPersonsTel",
      align: "left",
      format: "string",
    },
    {
      header: "Contact Person Phone 2",
      accessorKey: "SecondTelOfContactPerson",
      align: "left",
      format: "string",
    },
  ];
