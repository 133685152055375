/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
import React, { PropsWithChildren } from "react";
import {
  Container as ContainerBase,
  ListSubheader as ListSubheaderBase,
  Box,
  ContainerProps,
  Button,
  FormControl,
  Grid,
  Typography,
} from "@mui/material";

import { styled } from "@mui/material/styles";

interface CustomContainerProps extends PropsWithChildren<ContainerProps> {
  maxHeight?: number;
}

const ContainerStyled = styled(ContainerBase, {
  shouldForwardProp: (prop) => prop !== "maxHeight",
})<CustomContainerProps>(({ maxHeight }) => ({
  overflowX: "auto",
  maxHeight: maxHeight ? `${maxHeight}px` : "inherit",
  minHeight: maxHeight ? `${maxHeight}px` : "inherit",
}));

const TabsContainer = React.forwardRef<HTMLDivElement, CustomContainerProps>(
  (props, ref) => <ContainerStyled {...props} ref={ref} />,
);

const Container = styled(Box)(() => ({
  padding: 0,
  margin: 0,
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
}));

const ListSubheader = styled(ListSubheaderBase)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  margin: 0,
  padingTop: theme.spacing(0),
  padingRight: theme.spacing(2),
  padingLeft: theme.spacing(2),
  padingBottom: theme.spacing(0),
}));

const FilterButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.background.default,
  textTransform: "none",
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  textAlign: "center",
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
  },
  "&:disabled": {
    backgroundColor: "gray",
    color: theme.palette.background.default,
  },
}));

const FilterBox = styled(Box)(({ theme }) => ({
  alignItems: "center",
  backgroundColor: theme.accordionBody.backgroundColor,
  borderRadius: "5px",
  display: "flex",
  height: "auto",
  justifyContent: "center",
  marginTop: theme.spacing(2),
  marginRight: theme.spacing(2),
  marginLeft: theme.spacing(2),
  marginBottom: theme.spacing(1),
  width: "auto",
}));

const ReportsBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.accordionBody.backgroundColor,
  width: "100%",
  flexGrow: 1,
}));

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: theme.table.toolbar.fontWeight,
  fontSize: theme.table.toolbar.fontSize,
  margin: theme.spacing(0),
  opacity: 0.8,
  textAlign: "left",
}));

export default {
  Box,
  FilterBox,
  ReportsBox,
  Title,
  FilterButton,
  FormControl,
  Grid,
  Typography,
  Container,
  ListSubheader,
  TabsContainer,
};
