/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import { useTheme } from "@mui/material";
import React from "react";

import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";

// Assets
import SC from "./table-pagination-actions.styles";

interface CustomTablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

const TablePaginationActions: React.FC<CustomTablePaginationActionsProps> = ({
  count,
  rowsPerPage,
  page,
  onPageChange,
}) => {
  const theme: any = useTheme();
  const [pageIndex, setPageIndex] = React.useState<number>(page);

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setPageIndex(0);
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    const pageIndexx = pageIndex - 1;
    setPageIndex(pageIndexx);
    onPageChange(event, pageIndexx);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    const pageIndexx = pageIndex + 1;
    setPageIndex(pageIndexx);
    onPageChange(event, pageIndexx);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    const lastPageIndex = Math.max(0, Math.ceil(count / rowsPerPage) - 1);
    setPageIndex(lastPageIndex);
    onPageChange(event, lastPageIndex);
  };

  return (
    <SC.TablePaginationAction>
      <SC.IconButton
        onClick={handleFirstPageButtonClick}
        disabled={pageIndex === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </SC.IconButton>
      <SC.IconButton
        onClick={handleBackButtonClick}
        disabled={pageIndex === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </SC.IconButton>
      <SC.IconButton
        onClick={handleNextButtonClick}
        disabled={pageIndex >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </SC.IconButton>
      <SC.IconButton
        onClick={handleLastPageButtonClick}
        disabled={pageIndex >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </SC.IconButton>
    </SC.TablePaginationAction>
  );
};

export default TablePaginationActions;
