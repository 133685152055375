// Dependencies
import React from "react";

// Components
import Breadcrumb from "components/breadcrumb/breadcrumb.component";
import { Gbos2024GambiaSocialRegistryTable } from "../2024-gambia-social-registry-table/2024-gambia-social-registry-table.component";

// Assets
import SC from "./2024-gambia-social-registry.styles";

const Gbos2024GambiaSocialRegistry: React.FC = () => {
  return (
    <SC.Box pl={2} pr={2} pt={2}>
      <Breadcrumb />
      <Gbos2024GambiaSocialRegistryTable displayHeader displayTitle />
    </SC.Box>
  );
};

export default Gbos2024GambiaSocialRegistry;
