import React from "react";

// Components
import { IndividualTaxPayerTable } from "pages/reports/gra/individual-tax-payer-table/individual-tax-payer-table.component";
import { CorporateTaxPayerTable } from "pages/reports/gra/corporate-tax-payer-table/corporate-tax-payer-table.component";
import { RentalOfficeTable } from "pages/reports/gra/rental-office-table/rental-office-table.component";

// Assets
import AccordionList from "components/accordion-list/accordion-list.component";

// Hooks
import useTablesLoaded from "hooks/useTablesLoaded";

interface Filters {
  address: string;
  businessName: string;
  email: string;
  firstName: string;
  idNumber: string;
  phoneNumber: string;
  plusCode: string;
  surname: string;
}

interface GraTabProps {
  defaultFilters?: Filters;
  initialRowsPerPage?: number;
  maxHeight?: number;
  noDataComponent?: JSX.Element;
}

export const GraTab: React.FC<GraTabProps> = React.memo(
  ({ defaultFilters, initialRowsPerPage, maxHeight, noDataComponent }) => {
    const { tablesLoaded, renderTable } = useTablesLoaded({
      corporateTaxPayer: false,
      rentalOffice: false,
    });
    const reportSections = [
      {
        title: "Gambia Revenue Authority",
        accordions: [
          {
            content: (
              <IndividualTaxPayerTable
                defaultFilters={defaultFilters}
                initialRowsPerPage={initialRowsPerPage}
                maxHeight={maxHeight}
                noDataComponent={noDataComponent}
                //onLoaded={renderTable.corporateTaxPayer}
                persistenceId="4e781f3b-5c9a-49d5-8d12-a6bef8e7a9c2"
              />
            ),
            subtitle: "Individual Tax Payer",
          },
          {
            //shouldRenderBody: tablesLoaded.corporateTaxPayer,
            content: (
              <CorporateTaxPayerTable
                defaultFilters={defaultFilters}
                initialRowsPerPage={initialRowsPerPage}
                maxHeight={maxHeight}
                noDataComponent={noDataComponent}
                //onLoaded={renderTable.rentalOffice}
                persistenceId="6f42bd10-a0ac-47e5-9bfd-03a5e76c8d31"
              />
            ),
            subtitle: "Corporate Tax Payer",
          },
          {
            //shouldRenderBody: tablesLoaded.rentalOffice,
            content: (
              <RentalOfficeTable
                defaultFilters={defaultFilters}
                initialRowsPerPage={initialRowsPerPage}
                maxHeight={maxHeight}
                noDataComponent={noDataComponent}
                persistenceId="e61d3f0b-f503-497d-884d-bb67dac6118d"
              />
            ),
            subtitle: "Rental Office Data",
          },
        ],
      },
    ];

    return <AccordionList defaultExpanded sections={reportSections} />;
  },
);
