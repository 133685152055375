import React from "react";

// Components
import { BanjulPropertiesTable } from "pages/reports/africa-360-view/banjul-properties-table/banjul-properties-table.component";
import { KanifingPropertiesTable } from "pages/reports/africa-360-view/kanifing-properties-table/kanifing-properties-table.component";

// Assets
import AccordionList from "components/accordion-list/accordion-list.component";

interface Filters {
  address: string;
  businessName: string;
  email: string;
  firstName: string;
  idNumber: string;
  phoneNumber: string;
  plusCode: string;
  surname: string;
}

interface Africa360ViewTabProps {
  defaultFilters?: Filters;
  initialRowsPerPage?: number;
  maxHeight?: number;
  noDataComponent?: JSX.Element;
}

export const Africa360ViewTab: React.FC<Africa360ViewTabProps> = React.memo(
  ({ defaultFilters, initialRowsPerPage, maxHeight, noDataComponent }) => {
    const reportSections = [
      {
        title: "Africa 360 View",
        accordions: [
          {
            content: (
              <BanjulPropertiesTable
                defaultFilters={defaultFilters}
                initialRowsPerPage={initialRowsPerPage}
                maxHeight={maxHeight}
                noDataComponent={noDataComponent}
                persistenceId="21a11e05-a76a-4853-b683-61a114356d36"
              />
            ),
            subtitle: "Banjul Properties",
          },
          {
            content: (
              <KanifingPropertiesTable
                defaultFilters={defaultFilters}
                initialRowsPerPage={initialRowsPerPage}
                maxHeight={maxHeight}
                noDataComponent={noDataComponent}
                persistenceId="c4f27a18-36b1-4f7e-b40f-a6a2d5e05c2d"
              />
            ),
            subtitle: "Kanifing Properties",
          },
        ],
      },
    ];

    return <AccordionList defaultExpanded sections={reportSections} />;
  },
);
