import { TableColumnDef } from "components/table/table.component";
import { AccessorFn } from "@tanstack/react-table";
import _ from "lodash";
import {
  MobileCarrierSubscriberRegistrationsFragment,
  Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations,
} from "graphql/hasura/types-and-hooks";
import { FilterAccessorValue } from "pages/reports/utils";

export type MobileCarrierSubscriberRegistrationsSchema = Omit<
  MobileCarrierSubscriberRegistrationsFragment,
  "__typename"
>;

export interface MobileCarrierSubscriberRegistrationsTableFilters {
  businessName: string;
  firstName: string;
  idNumber: string;
  phoneNumber: string;
  surname: string;
}

export const DEFAULT_FILTER_ACCESSOR: Record<
  keyof MobileCarrierSubscriberRegistrationsTableFilters,
  FilterAccessorValue<Rtcs_Gm_Dev_Ph_Pura_MobileCarrierSubscriberRegistrations>
> = {
  firstName: ["Name"],
  businessName: ["Name", "Lastname"],
  idNumber: ["Idno"],
  phoneNumber: ["Mobile"],
  surname: ["Lastname"],
};

export const MOBILE_CARRIER_SUBSCRIBER_REGISTRATIONS_SCHEMA_MAP: Record<
  keyof MobileCarrierSubscriberRegistrationsSchema,
  "string" | "number"
> = {
  Idno: "string",
  Idtype: "string",
  Name: "string",
  Lastname: "string",
  Dob: "string",
  Gender: "string",
  Mobile: "string",
  Carrier: "string",
  Registrationdate: "string",
};

export const getAccessorType = (
  accessor:
    | keyof MobileCarrierSubscriberRegistrationsSchema
    | AccessorFn<MobileCarrierSubscriberRegistrationsSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return MOBILE_CARRIER_SUBSCRIBER_REGISTRATIONS_SCHEMA_MAP[accessor];
};

export const tableColumns: TableColumnDef<MobileCarrierSubscriberRegistrationsSchema>[] =
  [
    {
      header: "Carrier",
      accessorKey: "Carrier",
      align: "left",
      format: "string",
    },
    {
      header: "Mobile Number",
      accessorKey: "Mobile",
      align: "left",
      format: "string",
    },
    {
      header: "First Name",
      accessorKey: "Name",
      align: "left",
      format: "string",
    },
    {
      header: "Last Name",
      accessorKey: "Lastname",
      align: "left",
      format: "string",
    },
    {
      header: "ID Number",
      accessorKey: "Idno",
      align: "left",
      format: "string",
    },
    {
      header: "ID Type",
      accessorKey: "Idtype",
      align: "left",
      format: "string",
    },
    {
      header: "Gender",
      accessorKey: "Gender",
      align: "left",
      format: "string",
    },
    {
      header: "Date Of Birth",
      accessorKey: "Dob",
      align: "left",
      format: "date",
    },
    {
      header: "Registration Date",
      accessorKey: "Registrationdate",
      align: "left",
      format: "date",
    },
  ];
