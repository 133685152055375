// Dependencies
import Resizer from "react-image-file-resizer";

export const resizeImage = (
  imgFile: Blob,
  maxWidth: number,
  maxHeight: number,
  callback: (value: string | Blob | File | ProgressEvent<FileReader>) => void,
): void => {
  Resizer.imageFileResizer(
    imgFile,
    maxWidth, // Max width
    maxHeight, // Max height
    "JPEG", // Compress format
    100, // Quality
    0, // Rotation
    callback, // Callback that process the reduced image
    "base64", // Output type
  );
};

export default resizeImage;
