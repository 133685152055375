/* eslint-disable no-alert */
// Dependencies
import React, { useMemo, useEffect } from "react";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Order_By,
  Query_RootRtcs_Gm_Dev_Ph_Grc_OfficerOwnerArgs,
  useGrcOfficerOwnerPaginatedQuery,
  Rtcs_Gm_Dev_Grc_OfficerOwner_Select_Column,
} from "graphql/hasura/types-and-hooks";
import generatePersistentId from "../../../../../utils/packageJson";
import useExportData from "exports/useExportData";
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";

// Components
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import NoRecordsStatement from "components/table-no-records-statement/table-no-records-statement.component";
import {
  FilterCondition,
  TableFilter,
} from "components/table-filter/table-filter.component";
import Table, {
  INITIAL_ROWS_PER_PAGE,
  TableFetchDataFunction,
} from "components/table/table.component";
import {
  ColumnsProps,
  UniversalFilterResponse,
} from "components/universal-filter/universal-filter.component";

import {
  getConditionsFromDefaultFilters,
  getHasUnhandledDefaultFilter,
  mergeWithDefaultConditions,
} from "pages/reports/utils";

// Utils
import { useAppErrorHandler } from "errors/app.errors";

// Schema
import {
  columns as TableColumns,
  getAccessorType,
  DEFAULT_FILTER_ACCESSOR,
  TabTableFilters,
} from "./officers-owners-table.schema";

// Assets
import SC from "./officers-owners-table.styles";

const TABLE_NAME = "rtcs_gm_dev_Fngrc_OfficerOwner";

interface TableProps {
  defaultFilters?: TabTableFilters;
  displayHeader?: boolean;
  displayTitle?: boolean;
  initialRowsPerPage?: number;
  maxHeight?: number;
  noDataComponent?: JSX.Element;
  onLoaded?: () => void;
  persistenceId?: string;
}

export const OfficersOwnersTable: React.FC<TableProps> = React.memo(
  ({
    defaultFilters,
    displayHeader,
    displayTitle,
    initialRowsPerPage = INITIAL_ROWS_PER_PAGE.REPORTS,
    maxHeight,
    onLoaded,
    persistenceId = "6ad84a3f-3e6d-47ca-88a0-54e03b844e10",
  }) => {
    const defaultFilterConditions = useMemo(
      () =>
        getConditionsFromDefaultFilters(
          defaultFilters ?? {},
          DEFAULT_FILTER_ACCESSOR,
        ),
      [defaultFilters],
    );

    const hasUnhandledDefaultFilter = useMemo(
      () =>
        getHasUnhandledDefaultFilter(
          defaultFilters ?? {},
          DEFAULT_FILTER_ACCESSOR,
        ),
      [defaultFilters],
    );

    const [filterConditions, setFilterConditions] = React.useState<
      FilterCondition[]
    >([] as FilterCondition[]);

    const [queryVariables, setQueryVariables] = React.useState<
      Omit<Query_RootRtcs_Gm_Dev_Ph_Grc_OfficerOwnerArgs, "args">
    >({
      where: mergeWithDefaultConditions(
        defaultFilterConditions,
        filterConditions,
      ),
    });

    const [pageIndexResetSignal, setPageIndexResetSignal] =
      React.useState(false);

    const {
      data: responseData,
      loading,
      error,
    } = useGrcOfficerOwnerPaginatedQuery({
      context: { clientName: CLIENT_NAME.HASURA },
      skip: queryVariables.offset === undefined || hasUnhandledDefaultFilter,
      variables: {
        params: jsonToStringQueryVariables(queryVariables ?? {}),
        aggregateParams: jsonToStringQueryVariables({
          where: queryVariables?.where,
        }),
      },
      fetchPolicy: defaultFilters === undefined ? undefined : "no-cache",
    });

    const [exportDataFn, { loading: exportLoading, error: exportError }] =
      useExportData(TABLE_NAME, queryVariables);

    useAppErrorHandler(error || exportError);

    const data = React.useMemo(
      () => responseData?.rtcs_gm_dev_Fngrc_OfficerOwner ?? [],
      [responseData],
    );

    const columns = useMemo(() => TableColumns, []);

    const universalColumns: ColumnsProps[] = React.useMemo(() => {
      return columns.map((column, i) => {
        return {
          index: i,
          label: `${column.header}`,
          value: `${column.accessorKey}`,
          type: `${getAccessorType(column.accessorKey)}`,
        };
      });
    }, [columns]);

    const onUniversalFilterer = ({ condition }: UniversalFilterResponse) => {
      const columnObject = condition[Object.keys(condition)[0]];
      if (columnObject[Object.keys(columnObject)[0]] === "") {
        // eslint-disable-next-line no-param-reassign
        condition = {};
      }
      // setQueryVariables((oldQueryVariables) => ({
      //   ...oldQueryVariables,
      //   where: condition,
      // }));
      setFilterConditions([condition]);
      setPageIndexResetSignal((previousSignal) => !previousSignal);
    };

    const handlePaginatedFetchData: TableFetchDataFunction = React.useCallback(
      (params) => {
        const { pageIndex, pageSize, sortBy } = params;
        // This default column is also set in the table columns with the property
        // "sort: true"
        const defaultSortColumn = {
          id: Rtcs_Gm_Dev_Grc_OfficerOwner_Select_Column.Id,
          desc: true,
        };
        // Support just for one sort column at once
        const sortByColumn = sortBy.length > 0 ? sortBy[0] : defaultSortColumn;

        setQueryVariables((oldVariables) => ({
          ...oldVariables,
          orderBy: {
            [sortByColumn.id]: sortByColumn.desc ? Order_By.Desc : Order_By.Asc,
          },
          limit: pageSize,
          offset: pageIndex * pageSize,
        }));
      },
      [],
    );

    const totalRowsCount = parseInt(
      responseData?.rtcs_gm_dev_Fngrc_OfficerOwner_aggregatecm[0].value ?? "-1",
      10,
    );

    const paginationControlled = React.useMemo(
      () => ({
        fetchData: handlePaginatedFetchData,
        loading,
        totalRowsCount,
      }),
      [totalRowsCount, loading, handlePaginatedFetchData],
    );

    const onTableFilterApply = React.useCallback(
      (conditions: FilterCondition[]) => {
        // setQueryVariables((oldQueryVariables) => ({
        //   ...oldQueryVariables,
        //   where: {
        //     _and: conditions.length > 0 ? conditions : undefined,
        //   },
        // }));
        setFilterConditions(conditions);
        setPageIndexResetSignal((previousSignal) => !previousSignal);
      },
      [],
    );

    useEffect(() => {
      setQueryVariables((oldQueryVariables) => ({
        ...oldQueryVariables,
        where: mergeWithDefaultConditions(
          defaultFilterConditions,
          filterConditions,
        ),
      }));
    }, [defaultFilterConditions, filterConditions]);
    useEffect(() => {
      if (!loading && queryVariables.offset !== undefined) onLoaded?.();
    }, [loading, onLoaded, queryVariables]);

    return (
      <SC.Box>
        <ProgressIndicator open={loading || exportLoading} />
        <Table
          actionsOnRight={[
            "fullscreen-expand",
            "hide/show-columns",
            "filter-results",
            "export-to-excel-sheet/csv",
          ]}
          title={displayTitle ? "Individuals" : undefined}
          columns={columns}
          data={data}
          // eslint-disable-next-line no-alert
          onAction={() => alert("under construction")}
          headerPanel={
            displayHeader ? (
              <SC.Title>MDA - Gambia Registrar of Companies</SC.Title>
            ) : undefined
          }
          leftPanel={
            <TableFilter
              onFilterApply={onTableFilterApply}
              universalFilterColumns={universalColumns}
              onUniversalFilterSubmit={onUniversalFilterer}
            />
          }
          initialRowsPerPage={initialRowsPerPage}
          paginationControlled={paginationControlled}
          persistenceId={generatePersistentId(persistenceId)}
          maxHeight={maxHeight}
          noDataComponent={<NoRecordsStatement />}
          stickyHeader
          pageIndexResetSignal={pageIndexResetSignal}
          exportData={exportDataFn}
        />
      </SC.Box>
    );
  },
);

export default OfficersOwnersTable;
