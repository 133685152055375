import { TableColumnDef } from "components/table/table.component";
import {
  Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister,
  TradeLicenseRegisterFragment,
} from "graphql/hasura/types-and-hooks";
import _ from "lodash";
import { AccessorFn } from "@tanstack/react-table";
import { FilterAccessorValue } from "pages/reports/utils";

export type TradeLicenseRegisterSchema = Omit<
  TradeLicenseRegisterFragment,
  "__typename"
>;
export interface TradeLicenseRegisterTableFilters {
  address: string;
  businessName: string;
  firstName: string;
  surname: string;
}

export const DEFAULT_FILTER_ACCESSOR: Record<
  keyof TradeLicenseRegisterTableFilters,
  FilterAccessorValue<Rtcs_Gm_Dev_Ph_Bcc_TradeLicenseRegister>
> = {
  address: ["Address"],
  businessName: ["Name"],
  firstName: ["Name"],
  surname: ["Name"],
};

export const TradeLicenseRegisterSchemaMap: Record<
  keyof TradeLicenseRegisterSchema,
  "string" | "number"
> = {
  Uuid: "string",
  Address: "string",
  Licencetype: "string",
  Name: "string",
  Year: "string",
};

export const getAccessorType = (
  accessor:
    | keyof TradeLicenseRegisterSchema
    | AccessorFn<TradeLicenseRegisterSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return TradeLicenseRegisterSchemaMap[accessor];
};

export const columns: TableColumnDef<TradeLicenseRegisterSchema>[] = [
  {
    header: "UUID",
    accessorKey: "Uuid",
    align: "left",
    format: "string",
  },
  {
    header: "Year",
    accessorKey: "Year",
    align: "left",
    format: "string",
  },
  {
    header: "Trade Licence Type",
    accessorKey: "Licencetype",
    align: "left",
    format: "string",
  },
  {
    header: "Name",
    accessorKey: "Name",
    align: "left",
    format: "string",
  },
  {
    header: "Address",
    accessorKey: "Address",
    align: "left",
    format: "string",
  },
];
