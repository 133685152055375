/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from "react";
import {
  Routes,
  Route,
  useLocation,
  useParams,
  useMatch,
} from "react-router-dom";
import { useWindowHeight } from "@react-hook/window-size";
import _ from "lodash";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Query_RootRtcs_Gm_Dev_Grc_OfficerOwnerArgs,
  useGrcOfficerOwnerQuery,
} from "graphql/hasura/types-and-hooks";
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";

// Utils
import { useAppErrorHandler, UIError, UIErrorCodes } from "errors/app.errors";

// Components
import Breadcrumb from "components/breadcrumb/breadcrumb.component";
import TabSubHeader from "components/tab-sub-header/tab-sub-header.component";
import {
  APP_PERMISSION,
  useAppPermissionValidator,
} from "components/app-permission-validator/app-permission-validator.component";
import OfficerOwnerTabHeader from "./officer-owner-tab-header-grc.component";
import Corporate from "../corporate/corporate.component";

// Styles
import SC from "./individuals-tab.styles";

export interface OfficerOwnerTabRouteParams {
  personid: string;
}

const IndividualTabPage: React.FC = () => {
  const { personid } = useParams<{ personid: string }>();
  const match = useMatch("*");
  const path = match?.pathname ?? "";
  const pathArr = path.split("/");
  pathArr.pop();

  const location = useLocation();
  const { pathname } = location;
  const pathSplit = pathname.split("/").slice(1);
  const appPermissionValidator = useAppPermissionValidator();

  const queryVariablesGrcOfficerOwner: Query_RootRtcs_Gm_Dev_Grc_OfficerOwnerArgs =
    {
      where: { Personid: { _eq: personid?.toLowerCase() } },
    };
  const { data, error } = useGrcOfficerOwnerQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    variables: {
      params: jsonToStringQueryVariables(queryVariablesGrcOfficerOwner),
    },
  });

  const ownerOfficer = data?.rtcs_gm_dev_Fngrc_OfficerOwner[0];

  const mainContainerRef = React.useRef<HTMLDivElement>(null);
  const mainContainerPosition =
    mainContainerRef.current?.getBoundingClientRect();

  const mainContainerPositionY = mainContainerPosition?.y ?? 0;

  const windowHeight = useWindowHeight();
  const containerHeight = windowHeight - mainContainerPositionY;

  const handleError = useAppErrorHandler(error);

  React.useEffect(() => {
    if (data && data.rtcs_gm_dev_Fngrc_OfficerOwner.length === 0) {
      handleError(
        new UIError(
          UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
          `Selection doesn't exist.`,
        ),
      );
    }
  }, [data, handleError]);

  const personID = ownerOfficer?.Personid ?? "";

  const tabsData = [{ label: "Businesses", path: "corporate", value: 0 }];

  interface RouteConfig {
    path: string | string[];
    element: JSX.Element;
  }

  const routes: RouteConfig[] = [
    {
      path: "corporate",
      element: <Corporate personID={personID} />,
    },
  ];

  routes.push({
    path: "*",
    element: <>Content Not Found</>,
  });

  const firstname = ownerOfficer?.Firstname ?? "";
  const middlename = ownerOfficer?.Middlename ?? "";
  const lastname = ownerOfficer?.Lastname ?? "";
  const headerComponent = ownerOfficer ? (
    <OfficerOwnerTabHeader ownerOfficer={ownerOfficer} />
  ) : null;
  let breadcrumbComponent = (
    <Breadcrumb label={`${firstname} ${middlename} ${lastname}`} />
  );

  return (
    <SC.Container disableGutters>
      {breadcrumbComponent}
      <SC.ListSubheader>
        {headerComponent}
        <TabSubHeader tabs={tabsData} />
      </SC.ListSubheader>
      <SC.TabsContainer
        maxWidth={false}
        ref={mainContainerRef}
        maxHeight={containerHeight}
      >
        <Routes>
          {routes.map((route, index) =>
            Array.isArray(route.path) ? (
              route.path.map((p) => (
                <Route key={`${index}-${p}`} path={p} element={route.element} />
              ))
            ) : (
              <Route key={index} path={route.path} element={route.element} />
            ),
          )}
        </Routes>
      </SC.TabsContainer>
    </SC.Container>
  );
};

export default IndividualTabPage;
