import styled from "styled-components";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

const Title = styled(Typography)`
  font-size: 20px;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.text.primary};
  line-height: 28px;
  margin-bottom: 16px;
`;

export default {
  Box,
  Title,
}; 