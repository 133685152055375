import { FilterCondition } from "components/table-filter/table-filter.component";
import _ from "lodash";

export interface FilterAccessor<T> {
  accessorName: keyof T;
  type: "string" | "number";
}
export type FilterAccessorValue<T> =
  | keyof T
  | (keyof T)[]
  | FilterAccessor<T>
  | FilterAccessor<T>[];

/* eslint-disable @typescript-eslint/no-explicit-any */
export const getConditionsFromDefaultFilters = <
  T extends Record<string, any>,
  F,
>(
  defaultFilters: T,
  filterAccessor: Record<keyof T, FilterAccessorValue<F>>,
): (
  | { [x: string]: { _eq: number } }
  | { [x: string]: { _ilike: string } }
  | {
      _or: (
        | { [x: string]: { _eq: number } }
        | { [x: string]: { _ilike: string } }
      )[];
    }
)[] => {
  return Object.entries(defaultFilters)
    .filter(
      ([filterName, filterValue]) =>
        filterValue !== "" && filterName in filterAccessor,
    )
    .map(
      ([columnName, columnValue]):
        | { [x: string]: { _eq: number } }
        | { [x: string]: { _ilike: string } }
        | {
            _or: (
              | { [x: string]: { _eq: number } }
              | { [x: string]: { _ilike: string } }
            )[];
          } => {
        const formatCondition = (accessor: keyof F | FilterAccessor<F>) => {
          if (_.isObject(accessor)) {
            if (accessor.type === "number") {
              return {
                [accessor.accessorName]: {
                  _eq: _.toNumber(_.replace(columnValue, "%", "")),
                },
              };
            }
            return {
              [accessor.accessorName]: {
                _ilike: `%${columnValue}%`,
              },
            };
          }
          return {
            [accessor]: {
              _ilike: `%${columnValue}%`,
            },
          };
        };
        const accessor: FilterAccessorValue<F> =
          filterAccessor[columnName as unknown as keyof T];
        if (Array.isArray(accessor)) {
          const x = {
            _or: accessor.map(formatCondition),
          };
          return x;
        }
        return formatCondition(accessor);
      },
    );
};
export const getHasUnhandledDefaultFilter = <T extends Record<string, any>, F>(
  defaultFilters: T,
  filterAccessors: Record<keyof T, FilterAccessorValue<F>>,
): boolean => {
  const isInvalidNumberFilterValue = (
    value: keyof F | FilterAccessor<F>,
    filterValue: string,
  ) =>
    _.isObject(value) &&
    value.type === "number" &&
    _.isNaN(_.toNumber(filterValue));
  return Object.entries(defaultFilters).some(([filterName, filterValue]) => {
    const filterAccessor: FilterAccessorValue<F> = _.get(
      filterAccessors,
      filterName,
    );
    const hasUnhandledNumberValue =
      (_.isArray(filterAccessor) &&
        filterAccessor.some((value: keyof F | FilterAccessor<F>) =>
          isInvalidNumberFilterValue(value, filterValue),
        )) ||
      (!_.isArray(filterAccessor) &&
        isInvalidNumberFilterValue(filterAccessor, filterValue));

    return (
      (filterValue && !(filterName in filterAccessors)) ||
      hasUnhandledNumberValue
    );
  });
};

export const mergeWithDefaultConditions = (
  defaultFilterConditions: FilterCondition[],
  filterConditions: FilterCondition[],
): { _and: FilterCondition[] | undefined } => {
  const conditions = defaultFilterConditions.concat(filterConditions);

  return {
    _and: conditions.length > 0 ? conditions : undefined,
  };
};
