import { TableColumnDef } from "components/table/table.component";
import { AccessorFn } from "@tanstack/react-table";
import _ from "lodash";
import {
  KmcPropertyRatesRegisterWithPayerFragment,
  Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegisterWithPayer,
} from "graphql/hasura/types-and-hooks";
import { FilterAccessorValue } from "pages/reports/utils";

export type PropertyRatesRegisterSchema = Omit<
  KmcPropertyRatesRegisterWithPayerFragment,
  "__typename"
>;

export interface PropertyRatesRegisterTableFilters {
  address: string;
  businessName: string;
  firstName: string;
  phoneNumber: string;
  plusCode: string;
  surname: string;
}

export const DEFAULT_FILTER_ACCESSOR: Record<
  keyof PropertyRatesRegisterTableFilters,
  FilterAccessorValue<Rtcs_Gm_Dev_Ph_Kmc_PropertyRatesRegisterWithPayer>
> = {
  address: ["Address"],
  businessName: ["Owner"],
  firstName: ["Owner"],
  phoneNumber: ["Telno"],
  plusCode: ["PlusCode"],
  surname: ["Owner"],
};

export const PROPERTY_RATES_REGISTER_WITH_PAYERS_SCHEMA_MAP: Record<
  keyof PropertyRatesRegisterSchema,
  "string" | "number" | "Date"
> = {
  Address: "string",
  Areaname: "string",
  Owner: "string",
  PlusCode: "string",
  Propertyno: "string",
  Propertyvalue: "number",
  Ratename: "string",
  Subareaname: "string",
  Telno: "string",
  Uuid: "string",
};

export const getAccessorType = (
  accessor:
    | keyof PropertyRatesRegisterSchema
    | AccessorFn<PropertyRatesRegisterSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return PROPERTY_RATES_REGISTER_WITH_PAYERS_SCHEMA_MAP[accessor];
};

export const tableColumns: TableColumnDef<PropertyRatesRegisterSchema>[] = [
  {
    header: "UUID",
    accessorKey: "Uuid",
    align: "left",
    format: "string",
  },
  {
    header: "Property Number",
    accessorKey: "Propertyno",
    align: "left",
    format: "string",
  },
  {
    header: "Property Owner",
    accessorKey: "Owner",
    align: "left",
    format: "string",
  },
  {
    header: "Rates Payer Phone Number",
    accessorKey: "Telno",
    align: "left",
    format: "string",
  },
  {
    header: "Plus Code",
    accessorKey: "PlusCode",
    align: "left",
    format: "string",
  },
  {
    header: "Address Line 1",
    accessorKey: "Address",
    align: "left",
    format: "string",
  },
  {
    header: "Area",
    accessorKey: "Subareaname",
    align: "left",
    format: "string",
  },
  {
    header: "Sub Area",
    accessorKey: "Areaname",
    align: "left",
    format: "string",
  },
  {
    header: "Classification",
    accessorKey: "Ratename",
    align: "left",
    format: "string",
  },
  {
    header: "Property Value",
    accessorKey: "Propertyvalue",
    align: "right",
    format: "number",
  },
];
