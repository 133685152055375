import React from "react";

// GraphQL
import { Rtcs_Gm_Dev_Ph_Grc_OfficerOwner } from "graphql/hasura/types-and-hooks";

// Components
import { FORMAT_VALUE_OPTIONS } from "components/formatted-value/formatted-value";
import {
  AccordionHeader,
  Header,
  Content,
} from "components/accordion-header/accordion-header.component";

export interface OwnersOfficersFields {
  label: string;
  accessorKey?: string;
  format?: keyof typeof FORMAT_VALUE_OPTIONS;
}

export const identifiers: OwnersOfficersFields[] = [
  { label: "First Name", accessorKey: "Firstname" },
  { label: "Middle Name", accessorKey: "Middlename" },
  { label: "Last Name", accessorKey: "Lastname" },
  { label: "Date of Birth", accessorKey: "Dateofbirth", format: "date" },
  { label: "Gender", accessorKey: "Sex" },
  { label: "Nationality", accessorKey: "Nationality" },
  { label: "Profession", accessorKey: "Profession" },
  { label: "Person ID", accessorKey: "Personid" },
  { label: "Tax ID", accessorKey: "Taxid" },
];
export const residentialAddress: OwnersOfficersFields[] = [
  { label: "Address Line 1", accessorKey: "Residentialaddressline1" },
  { label: "City", accessorKey: "Residentialaddresscity" },
  { label: "District", accessorKey: "Residentialaddressdistrict" },
  { label: "Region", accessorKey: "Residentialaddressregion" },
  { label: "Country", accessorKey: "Residentialaddresscountry" },
];
export const postalAddress: OwnersOfficersFields[] = [
  { label: "Address Line 1", accessorKey: "Postaladdressline1" },
  { label: "City", accessorKey: "Postaladdresscity" },
  { label: "District", accessorKey: "Postaladdressdistrict" },
  { label: "Region", accessorKey: "Postaladdressregion" },
  { label: "Country", accessorKey: "Postaladdresscountry" },
  // { label: "" }, // create separator
];

export const contactInfoAndRelated: OwnersOfficersFields[] = [
  { label: "Mobile", accessorKey: "Phonemobile" },
  { label: "Phone 1", accessorKey: "Phonenumber" },
  { label: "Phone 2", accessorKey: "Phonenumber2" },
  { label: "Fax", accessorKey: "Fax" },
  { label: "Email", accessorKey: "Email" },
  { label: "Created On", accessorKey: "Createdon", format: "date" },
];

export interface OfficerOwnerTabHeaderProps {
  ownerOfficer: Rtcs_Gm_Dev_Ph_Grc_OfficerOwner;
}

export const OfficerOwnerTabHeader: React.FC<OfficerOwnerTabHeaderProps> = ({
  ownerOfficer,
}) => {
  if (!ownerOfficer) {
    return null;
  }

  const header: Header[] = [
    {
      index: 0,
      label: `${ownerOfficer.Firstname ?? ""} ${ownerOfficer.Lastname ?? ""}`,
      type: "bold",
    },
    { index: 1, label: "ID", type: "bold" },
    { index: 2, label: ownerOfficer.Personid ?? "" },
  ];

  const mapFunction = (info: OwnersOfficersFields, index: number) => {
    const { accessorKey } = info;
    let value = "";

    if (accessorKey)
      value =
        ownerOfficer?.[accessorKey as keyof Rtcs_Gm_Dev_Ph_Grc_OfficerOwner];

    return { ...info, index, value };
  };
  const content: Content[] = [
    {
      index: 0,
      title: "Names and Identifiers",
      data: identifiers.map(mapFunction),
    },
    {
      index: 1,
      title: "Residential Address",
      data: residentialAddress.map(mapFunction),
    },
    {
      index: 2,
      title: "Postal Address",
      data: postalAddress.map(mapFunction),
    },
    {
      index: 3,
      title: "Contact Info and Related",
      data: contactInfoAndRelated.map(mapFunction),
    },
  ];

  return (
    <AccordionHeader
      header={header}
      content={content}
      source="GRC"
      isLoading={false}
    />
  );
};

export default OfficerOwnerTabHeader;
