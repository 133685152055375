// Dependencies
import React from "react";

// Components
import Breadcrumb from "components/breadcrumb/breadcrumb.component";
import MobileCarrierSubscriberRegistrationsTable from "../mobile-carrier-subscriber-registrations-table/mobile-carrier-subscriber-registrations-table.component";

// Assets
import SC from "./mobile-carrier-subscriber-registrations.styles";

const MobileCarrierSubscriberRegistrations: React.FC = () => {
  return (
    <SC.Box pl={2} pr={2} pt={2}>
      <Breadcrumb />
      <MobileCarrierSubscriberRegistrationsTable displayHeader displayTitle />
    </SC.Box>
  );
};

export default MobileCarrierSubscriberRegistrations;
