// Dependencies
import React from "react";

// Components
import Breadcrumb from "components/breadcrumb/breadcrumb.component";
import { IndividualReturnRentTable } from "../individual-return-rent-table/individual-return-rent-table.component";

// Assets
import SC from "./individual-return-rent.styles";

const IndividualReturnRent: React.FC = () => {
  return (
    <SC.Box pl={2} pr={2} pt={2}>
      <Breadcrumb />
      <IndividualReturnRentTable displayHeader displayTitle />
    </SC.Box>
  );
};

export default IndividualReturnRent;
