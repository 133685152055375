/* eslint-disable no-alert */
// Dependencies
import React, { useEffect, useMemo } from "react";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Query_RootRtcs_Gm_Dev_Africa360view_Africa360ViewBanjul_AggregateArgs,
  Order_By,
  useGetPaginatedAfrica360ViewBanjulPropertiesQuery,
  Rtcs_Gm_Dev_Ph_Africa360view_Africa360ViewBanjul_Select_Column,
} from "graphql/hasura/types-and-hooks";

import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";

// Components
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import Table, {
  TableFetchDataFunction,
  INITIAL_ROWS_PER_PAGE,
} from "components/table/table.component";
import {
  UniversalFilterResponse,
  ColumnsProps,
} from "components/universal-filter/universal-filter.component";

// Utils
import { useAppErrorHandler } from "errors/app.errors";
import {
  FilterCondition,
  TableFilter,
} from "components/table-filter/table-filter.component";
import generatePersistentId from "utils/packageJson";
import useExportData from "exports/useExportData";

// Schema
import {
  tableColumns,
  getAccessorType,
  BanjulPropertiesTableFilters,
  DEFAULT_FILTER_ACCESSOR,
} from "./banjul-properties-table.schema";

// Assets
import SC from "./banjul-properties-table.styles";
import NoRecordsStatement from "components/table-no-records-statement/table-no-records-statement.component";
import {
  getConditionsFromDefaultFilters,
  getHasUnhandledDefaultFilter,
  mergeWithDefaultConditions,
} from "pages/reports/utils";

interface BanjulPropertiesTableProps {
  defaultFilters?: BanjulPropertiesTableFilters;
  displayHeader?: boolean;
  displayTitle?: boolean;
  initialRowsPerPage?: number;
  maxHeight?: number;
  noDataComponent?: JSX.Element;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDataPerPageChange?: (data: any) => void;
  onLoaded?: () => void;
  persistenceId?: string;
}

const TABLE_NAME = "rtcs_gm_dev_Fnafrica360view_Africa360ViewBanjul";

export const BanjulPropertiesTable: React.FC<BanjulPropertiesTableProps> =
  React.memo(
    ({
      defaultFilters,
      displayHeader,
      displayTitle,
      initialRowsPerPage = INITIAL_ROWS_PER_PAGE.REPORTS,
      maxHeight,
      onDataPerPageChange,
      onLoaded,
      persistenceId = "2f5a3cef-7c8d-4fd2-b592-6a3045910423",
    }) => {
      const defaultFilterConditions = useMemo(
        () =>
          getConditionsFromDefaultFilters(
            defaultFilters ?? {},
            DEFAULT_FILTER_ACCESSOR,
          ),
        [defaultFilters],
      );
      const hasUnhandledDefaultFilter = useMemo(
        () =>
          getHasUnhandledDefaultFilter(
            defaultFilters ?? {},
            DEFAULT_FILTER_ACCESSOR,
          ),
        [defaultFilters],
      );
      const [filterConditions, setFilterConditions] = React.useState<
        FilterCondition[]
      >([] as FilterCondition[]);
      const [queryVariables, setQueryVariables] = React.useState<
        Omit<
        Query_RootRtcs_Gm_Dev_Africa360view_Africa360ViewBanjul_AggregateArgs,
          "args"
        >
      >({
        where: mergeWithDefaultConditions(
          defaultFilterConditions,
          filterConditions,
        ),
      });

      const [pageIndexResetSignal, setPageIndexResetSignal] =
        React.useState(false);

      const {
        data: responseData,
        loading,
        error,
      } = useGetPaginatedAfrica360ViewBanjulPropertiesQuery({
        context: { clientName: CLIENT_NAME.HASURA },
        skip: queryVariables.offset === undefined || hasUnhandledDefaultFilter,
        variables: {
          params: jsonToStringQueryVariables(queryVariables ?? {}),
          aggregateParams: jsonToStringQueryVariables({
            where: queryVariables?.where,
          }),
        },
        fetchPolicy: defaultFilters === undefined ? undefined : "no-cache",
      });

      const [exportDataFn, { loading: exportLoading, error: exportError }] =
        useExportData(TABLE_NAME, queryVariables);

      useAppErrorHandler(error || exportError);

      const data = React.useMemo(
        () =>
          responseData?.rtcs_gm_dev_Fnafrica360view_Africa360ViewBanjul ?? [],
        [responseData],
      );

      const columns = useMemo(() => tableColumns, []);

      const universalColumns: ColumnsProps[] = useMemo(() => {
        return columns.map((column, i) => {
          return {
            index: i,
            label: `${column.header}`,
            value: `${column.accessorKey}`,
            type: `${getAccessorType(column.accessorKey)}`,
          };
        });
      }, [columns]);

      const onUniversalFilterer = ({ condition }: UniversalFilterResponse) => {
        const columnObject = condition[Object.keys(condition)[0]];
        if (columnObject[Object.keys(columnObject)[0]] === "") {
          // eslint-disable-next-line no-param-reassign
          condition = {};
        }
        setFilterConditions([condition]);
        setPageIndexResetSignal((previousSignal) => !previousSignal);
      };

      const handlePaginatedFetchData: TableFetchDataFunction =
        React.useCallback(({ pageIndex, pageSize, sortBy }) => {
          const defaultSortColumn = {
            id: Rtcs_Gm_Dev_Ph_Africa360view_Africa360ViewBanjul_Select_Column.Uuid,
            desc: true,
          };
          const sortByColumn =
            sortBy.length > 0 ? sortBy[0] : defaultSortColumn;
          setQueryVariables((oldVariables) => ({
            ...oldVariables,
            orderBy: {
              [sortByColumn.id]: sortByColumn.desc
                ? Order_By.Desc
                : Order_By.Asc,
            },
            limit: pageSize,
            offset: pageIndex * pageSize,
          }));
        }, []);

      const totalRowsCount = parseInt(
        responseData
          ?.rtcs_gm_dev_Fnafrica360view_Africa360ViewBanjul_aggregatecm[0]
          .value ?? "-1",
        10,
      );

      const paginationControlled = React.useMemo(
        () => ({
          fetchData: handlePaginatedFetchData,
          loading,
          totalRowsCount,
        }),
        [totalRowsCount, loading, handlePaginatedFetchData],
      );

      const onTableFilterApply = React.useCallback(
        (conditions: FilterCondition[]) => {
          setFilterConditions(conditions);
          setPageIndexResetSignal((previousSignal) => !previousSignal);
        },
        [],
      );
      useEffect(() => {
        setQueryVariables((oldQueryVariables) => ({
          ...oldQueryVariables,
          where: mergeWithDefaultConditions(
            defaultFilterConditions,
            filterConditions,
          ),
        }));
      }, [defaultFilterConditions, filterConditions]);
      useEffect(() => {
        if (!loading && queryVariables.offset !== undefined) onLoaded?.();
      }, [loading, onLoaded, queryVariables]);

      return (
        <>
          <ProgressIndicator open={loading || exportLoading} />
          <Table
            actionsOnRight={[
              "fullscreen-expand",
              "hide/show-columns",
              "filter-results",
              "export-to-excel-sheet/csv",
            ]}
            title={displayTitle ? "Banjul Africa Properties" : undefined}
            columns={columns}
            data={data}
            onAction={() => alert("under construction")}
            headerPanel={
              displayHeader ? (
                <SC.Title>MDA - Africa 360 View</SC.Title>
              ) : undefined
            }
            leftPanel={
              <TableFilter
                onFilterApply={onTableFilterApply}
                universalFilterColumns={universalColumns}
                onUniversalFilterSubmit={onUniversalFilterer}
              />
            }
            paginationControlled={paginationControlled}
            persistenceId={generatePersistentId(persistenceId)}
            stickyHeader
            pageIndexResetSignal={pageIndexResetSignal}
            exportData={exportDataFn}
            initialRowsPerPage={initialRowsPerPage}
            maxHeight={maxHeight}
            noDataComponent={<NoRecordsStatement />}
            onDataPerPageChange={onDataPerPageChange}
          />
        </>
      );
    },
  );
