/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import {
  Routes,
  Route,
  useLocation,
  useParams,
  useMatch,
} from "react-router-dom";
import { useWindowHeight } from "@react-hook/window-size";
import _ from "lodash";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Query_RootRtcs_Gm_Dev_Grc_CorporateArgs,
  useGrcCorporateQuery,
} from "graphql/hasura/types-and-hooks";
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";

// Utils
import { useAppErrorHandler, UIError, UIErrorCodes } from "errors/app.errors";

// Components
import Breadcrumb from "components/breadcrumb/breadcrumb.component";
import TabSubHeader from "components/tab-sub-header/tab-sub-header.component";
import { useAppPermissionValidator } from "components/app-permission-validator/app-permission-validator.component";
// import BusinessTab from "../businesses-tab/businesses-tab.component";

// Styles
import SC from "./corporate-tab.styles";
import CorporateTabHeader from "./corporate-tab-header-grc.component";
import OwnersOfficersTab from "../officers-owners-tab/officers-owners.component";

export interface OfficerOwnerTabRouteParams {
  corporate: string;
}

const CorporateTabPage: React.FC = () => {
  const { corporate } = useParams<{ corporate: string }>();
  const match = useMatch("*");
  const path = match?.pathname ?? "";
  const pathArr = path.split("/");
  pathArr.pop();

  const location = useLocation();
  const { pathname } = location;
  const appPermissionValidator = useAppPermissionValidator();

  const queryVariablesGrcCorporate: Query_RootRtcs_Gm_Dev_Grc_CorporateArgs = {
    where: { Companyid: { _eq: corporate?.toUpperCase() } },
  };
  const { data, error } = useGrcCorporateQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    variables: {
      params: jsonToStringQueryVariables(queryVariablesGrcCorporate),
    },
  });

  const corporateData = data?.rtcs_gm_dev_Fngrc_Corporate[0];

  const mainContainerRef = React.useRef<HTMLDivElement>(null);
  const mainContainerPosition =
    mainContainerRef.current?.getBoundingClientRect();

  const mainContainerPositionY = mainContainerPosition?.y ?? 0;

  const windowHeight = useWindowHeight();
  const containerHeight = windowHeight - mainContainerPositionY;

  const handleError = useAppErrorHandler(error);

  React.useEffect(() => {
    if (data && data.rtcs_gm_dev_Fngrc_Corporate.length === 0) {
      handleError(
        new UIError(
          UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
          `Selection doesn't exist.`,
        ),
      );
    }
  }, [data, handleError]);

  const id = corporateData?.Companyid ?? "";

  const tabsData = [
    { label: "Officers/Owners", path: "officers-owners", value: 0 },
  ];

  interface RouteConfig {
    path: string | string[];
    element: JSX.Element;
  }

  const routes: RouteConfig[] = [
    {
      path: "officers-owners",
      element: <OwnersOfficersTab corporateID={id} />,
    },
  ];

  routes.push({
    path: "*",
    element: <>Content Not Found</>,
  });

  const headerComponent = corporateData ? (
    <CorporateTabHeader corporate={corporateData} />
  ) : null;
  const breadcrumbComponent = (
    <Breadcrumb label={`${corporateData?.Companyname ?? "..."}`} />
  );

  return (
    <SC.Container disableGutters>
      {breadcrumbComponent}
      <SC.ListSubheader>
        {headerComponent}
        <TabSubHeader tabs={tabsData} />
      </SC.ListSubheader>
      <SC.TabsContainer
        maxWidth={false}
        ref={mainContainerRef}
        maxHeight={containerHeight}
      >
        <Routes>
          {routes.map((route, index) =>
            Array.isArray(route.path) ? (
              route.path.map((p) => (
                <Route key={`${index}-${p}`} path={p} element={route.element} />
              ))
            ) : (
              <Route key={index} path={route.path} element={route.element} />
            ),
          )}
        </Routes>
      </SC.TabsContainer>
    </SC.Container>
  );
};

export default CorporateTabPage;
