// Dependencies
import React from "react";

// Components
import Breadcrumb from "components/breadcrumb/breadcrumb.component";
import { NationalIdAndResidentialPermitRegistrationsTable } from "../national-id-and-residential-permit-registrations-table/national-id-and-residential-permit-registrations-table.component";

// Assets
import SC from "./national-id-and-residential-permit-registrations.styles";

const NationalIdAndResidentialPermitRegistrations: React.FC = () => {
  return (
    <SC.Box pl={2} pr={2} pt={2}>
      <Breadcrumb />
      <NationalIdAndResidentialPermitRegistrationsTable
        displayHeader
        displayTitle
      />
    </SC.Box>
  );
};

export default NationalIdAndResidentialPermitRegistrations;
