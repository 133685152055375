/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TableColumnDef } from "components/table/table.component";
import { GrcOfficerOwnerFragment } from "graphql/hasura/types-and-hooks";
import _ from "lodash";
import { Link } from "react-router-dom";
import { AccessorFn } from "@tanstack/react-table";

export type GRCOfficerOwnerSchema = Omit<
  GrcOfficerOwnerFragment,
  | "__typename"
  | "Corptype"
  | "Corpcompanynumber"
  | "Corpcompanytaxid"
  | "Corpcompanyname"
  | "Corpprincipaladdress"
  | "Corpprincipaladdressline1"
  | "Corpprincipaladdresscity"
  | "Corpprincipaladdressdistrict"
  | "Corpprincipaladdressregion"
  | "Corpprincipaladdresscountry"
  | "Corptelephone"
  | "Corptelephone2"
  | "Corpcompanyemail"
  | "Corpfax"
  | "Corpstatusname"
  | "Corpdateofincorporation"
  | "Corptypeofbusinessother"
>;

export const GRCOfficerOwnerSchemaMap: Record<
  keyof GRCOfficerOwnerSchema,
  "string" | "number"
> = {
  Id: "string",
  Relationship: "string",
  Personid: "string",
  Companyid: "string",
  Taxid: "string",
  Fullname: "string",
  Firstname: "string",
  Middlename: "string",
  Lastname: "string",
  Phonemobile: "string",
  Phonenumber: "string",
  Phonenumber2: "string",
  Fax: "string",
  Email: "string",
  Residentialaddress: "string",
  Residentialaddressline1: "string",
  Residentialaddresscity: "string",
  Residentialaddressdistrict: "string",
  Residentialaddressregion: "string",
  Residentialaddresscountry: "string",
  Dateofbirth: "string",
  Sex: "string",
  Nationality: "string",
  Profession: "string",
  Postaladdress: "string",
  Postaladdressline1: "string",
  Postaladdresscity: "string",
  Postaladdressdistrict: "string",
  Postaladdressregion: "string",
  Postaladdresscountry: "string",
  Createdon: "string",
  Numberallottedprocent: "number",
  Particularsofsharesnumber: "number",
  Currencyid: "string",
  Dateofappointment: "string",
};

export const getAccessorType = (
  accessor:
    | keyof GRCOfficerOwnerSchema
    | AccessorFn<GRCOfficerOwnerSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return GRCOfficerOwnerSchemaMap[accessor];
};

export const columns: TableColumnDef<GRCOfficerOwnerSchema>[] = [
  {
    header: "Relationship",
    accessorKey: "Relationship",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Position Id",
    accessorKey: "Id",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Tax ID",
    accessorKey: "Taxid",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Full Name",
    accessorKey: "Fullname",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Physical Address",
    accessorKey: "Residentialaddress",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Postal Address",
    accessorKey: "Postaladdress",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Phone 1",
    accessorKey: "Phonenumber",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Phone 2",
    accessorKey: "Phonenumber2",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Fax",
    accessorKey: "Fax",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Email",
    accessorKey: "Email",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Date of Birth",
    accessorKey: "Dateofbirth",
    align: "left",
    meta: { sort: false },
    format: "date",
  },
  {
    header: "Gender",
    accessorKey: "Sex",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Nationality",
    accessorKey: "Nationality",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Profession",
    accessorKey: "Profession",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Created Date",
    accessorKey: "Createdon",
    align: "left",
    meta: { sort: false },
    format: "date",
  },
  {
    header: "First Name",
    accessorKey: "Firstname",
    align: "left",
    meta: { sort: false },
    format: "string",
    hiddenColumn: true,
  },
  {
    header: "Person ID",
    accessorKey: "Personid",
    align: "left",
    meta: { sort: false },
    format: "string",
    hiddenColumn: true,
  },
  {
    header: "Corporate ID",
    accessorKey: "Companyid",
    align: "left",
    meta: { sort: false },
    format: "string",
    hiddenColumn: true,
  },
  {
    header: "Last Name",
    accessorKey: "Lastname",
    align: "left",
    meta: { sort: false },
    format: "string",
    hiddenColumn: true,
  },
  {
    header: "Mobile",
    accessorKey: "Phonemobile",
    align: "left",
    meta: { sort: false },
    format: "string",
    hiddenColumn: true,
  },
  {
    header: "Physical Address Line 1",
    accessorKey: "Residentialaddressline1",
    align: "left",
    meta: { sort: false },
    format: "string",
    hiddenColumn: true,
  },
  {
    header: "City",
    accessorKey: "Residentialaddresscity",
    align: "left",
    meta: { sort: false },
    format: "string",
    hiddenColumn: true,
  },
  {
    header: "District",
    accessorKey: "Residentialaddressdistrict",
    align: "left",
    meta: { sort: false },
    format: "string",
    hiddenColumn: true,
  },
  {
    header: "Region",
    accessorKey: "Residentialaddressregion",
    align: "left",
    meta: { sort: false },
    format: "string",
    hiddenColumn: true,
  },
  {
    header: "Country",
    accessorKey: "Residentialaddresscountry",
    align: "left",
    meta: { sort: false },
    format: "string",
    hiddenColumn: true,
  },
  {
    header: "Postal Address Line 1",
    accessorKey: "Postaladdressline1",
    align: "left",
    meta: { sort: false },
    format: "string",
    hiddenColumn: true,
  },
  {
    header: "Postal Address City",
    accessorKey: "Postaladdresscity",
    align: "left",
    meta: { sort: false },
    format: "string",
    hiddenColumn: true,
  },
  {
    header: "Postal Address District",
    accessorKey: "Postaladdressdistrict",
    align: "left",
    meta: { sort: false },
    format: "string",
    hiddenColumn: true,
  },
  {
    header: "Postal Address Region",
    accessorKey: "Postaladdressregion",
    align: "left",
    meta: { sort: false },
    format: "string",
    hiddenColumn: true,
  },
  {
    header: "Postal Address Country",
    accessorKey: "Postaladdresscountry",
    align: "left",
    meta: { sort: false },
    format: "string",
    hiddenColumn: true,
  },
  {
    header: "Percentage of Shares Allocated",
    accessorKey: "Numberallottedprocent",
    align: "right",
    meta: { sort: false },
    format: "number",
    hiddenColumn: true,
  },
  {
    header: "Value of Shares Allocated",
    accessorKey: "Particularsofsharesnumber",
    align: "right",
    meta: { sort: false },
    format: "number",
    hiddenColumn: true,
  },
  {
    header: "Currency",
    accessorKey: "Currencyid",
    align: "left",
    meta: { sort: false },
    format: "string",
    hiddenColumn: true,
  },
  {
    header: "Date of Appointment",
    accessorKey: "Dateofappointment",
    align: "left",
    meta: { sort: false },
    format: "date",
    hiddenColumn: true,
  },
  {
    header: "Middle Name",
    accessorKey: "Middlename",
    align: "left",
    meta: { sort: false },
    format: "string",
    hiddenColumn: true,
  },
];
