import React from "react";

// Components
import { Gbos2022CommercialRentalRatesSurveyTable } from "pages/reports/gbos/2022-commercial-rental-rates-survey-table/2022-commercial-rental-rates-survey-table.component";
import { Gbos2024PopulationAndHousingCensusTable } from "pages/reports/gbos/2024-population-and-housing-census-table/2024-population-and-housing-census-table.component";
import { Gbos2024GambiaSocialRegistryTable } from "pages/reports/gbos/2024-gambia-social-registry-table/2024-gambia-social-registry-table.component";

// Assets
import AccordionList from "components/accordion-list/accordion-list.component";

// Hooks
import useTablesLoaded from "hooks/useTablesLoaded";

interface Filters {
  address: string;
  businessName: string;
  email: string;
  firstName: string;
  idNumber: string;
  phoneNumber: string;
  plusCode: string;
  surname: string;
}

interface GbosTabProps {
  defaultFilters?: Filters;
  initialRowsPerPage?: number;
  maxHeight?: number;
  noDataComponent?: JSX.Element;
}

export const GbosTab: React.FC<GbosTabProps> = React.memo(
  ({ defaultFilters, initialRowsPerPage, maxHeight, noDataComponent }) => {
    const { tablesLoaded, renderTable } = useTablesLoaded({
      commercialRentalRatesSurvey: false,
    });
    const reportSections = [
      {
        title: "Gambia Bureau of Statistics",
        accordions: [
          {
            content: (
              <Gbos2024PopulationAndHousingCensusTable
                defaultFilters={defaultFilters}
                initialRowsPerPage={initialRowsPerPage}
                maxHeight={maxHeight}
                noDataComponent={noDataComponent}
                persistenceId="69a14bd1-0336-495f-8d04-90064c117f0f_v1"
              />
            ),
            subtitle: "2024 Population and Housing Census",
          },
          {
            // shouldRenderBody: tablesLoaded.commercialRentalRatesSurvey,
            content: (
              <Gbos2022CommercialRentalRatesSurveyTable
                defaultFilters={defaultFilters}
                initialRowsPerPage={initialRowsPerPage}
                maxHeight={maxHeight}
                noDataComponent={noDataComponent}
                persistenceId="0d948075-809f-42a4-bb39-116a78998fd1_v1"
              />
            ),
            subtitle: "2022 Commercial Rental Rates Survey",
          },
          {
            content: (
              <Gbos2024GambiaSocialRegistryTable
                defaultFilters={defaultFilters}
                initialRowsPerPage={initialRowsPerPage}
                maxHeight={maxHeight}
                noDataComponent={noDataComponent}
                persistenceId="f6052079-06e1-4910-ab71-f5e0bd86768c"
              />
            ),
            subtitle: "2024 Gambia Social Registry",
          },
        ],
      },
    ];

    return <AccordionList defaultExpanded sections={reportSections} />;
  },
);
