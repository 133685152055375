// Dependencies
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '1.25rem',
  textAlign: "left",
  marginTop: theme.spacing(2),
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(3),
  opacity: 0.8,
}));

export default {
  Title,
};