import React from "react";
import _ from "lodash";

// GraphQL
// import { Note as NoteData } from "graphql/rtcs.types";
import { NoteFragmentFragment, UserData } from "graphql/types-and-hooks";

// Components
import FormattedValue from "components/formatted-value/formatted-value";
import NoteAttachment from "components/note-attachment/note-attachment.component";

// Assets
import SC from "./note.styles";

const renderComment = (comment: string) => {
  let formatedComment = `<p>${comment}</p>`;
  formatedComment = formatedComment.replace(/\n/g, "</p><p>");
  formatedComment = formatedComment.replaceAll("<p></p>", "");

  return formatedComment;
};

export interface NoteProps {
  note: NoteFragmentFragment;
}

const Note: React.FC<NoteProps> = ({
  note: { id, comment, attachments, user, creationDate },
}) => (
  <SC.NoteContainer>
    <SC.NoteContent>
      <SC.NoteToolbar id={id ?? ""} />

      {attachments && attachments.length > 0 && (
        <SC.AttachmentIconButton>
          <SC.AttachFile />
        </SC.AttachmentIconButton>
      )}

      {attachments?.map(
        (attachment, index) =>
          attachment && (
            <NoteAttachment
              key={attachment?.filename ?? ""}
              attachment={attachment}
            >
              {index > 0 ? ", " : ""}
              {attachment?.name}
            </NoteAttachment>
          ),
      )}

      {!_.isEmpty(user) && <SC.NoteAuthor author={user as UserData} />}

      <SC.NoteTimestamp variant="subtitle2">
        <FormattedValue format="dateTime" value={creationDate ?? ""} />
      </SC.NoteTimestamp>

      <SC.NoteBody
        component="div"
        dangerouslySetInnerHTML={{ __html: renderComment(comment ?? "") }}
      />
    </SC.NoteContent>
  </SC.NoteContainer>
);

export default Note;
