// Dependencies
import React from "react";

// Components
import Breadcrumb from "components/breadcrumb/breadcrumb.component";
import { RentalOfficeTable } from "../rental-office-table/rental-office-table.component";

// Assets
import SC from "./rental-office.styles";

const RentalOffice: React.FC = () => {
  return (
    <SC.Box pl={2} pr={2} pt={2}>
      <Breadcrumb />
      <RentalOfficeTable displayHeader displayTitle />
    </SC.Box>
  );
};

export default RentalOffice;
