import { TableColumnDef } from "components/table/table.component";
import { IndividualReturnLossFragment } from "graphql/hasura/types-and-hooks";
import _ from "lodash";
import { AccessorFn } from "@tanstack/react-table";
import { FilterAccessorValue } from "pages/reports/utils";

export type IndividualReturnLossTableSchema = Omit<
  IndividualReturnLossFragment,
  "__typename"
>;

export interface IndividualReturnLossTableFilters {}

export const DEFAULT_FILTER_ACCESSOR: Record<
  keyof IndividualReturnLossTableFilters,
  FilterAccessorValue<IndividualReturnLossFragment>
> = {};

export const IndividualReturnLossTableSchemaMap: Record<
  keyof IndividualReturnLossTableSchema,
  "string"
> = {
  Lossid: "string",
  Returnid: "string",
  Lossyear: "string",
  Lossmade: "string",
  Losssetoff: "string",
  Lossoutstanding: "string",
  Yearleft: "string",
};

export const getAccessorType = (
  accessor:
    | keyof IndividualReturnLossTableSchema
    | AccessorFn<IndividualReturnLossTableSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return IndividualReturnLossTableSchemaMap[accessor];
};

export const columns: TableColumnDef<IndividualReturnLossTableSchema>[] = [
  {
    header: "Loss ID",
    accessorKey: "Lossid",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Return ID",
    accessorKey: "Returnid",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Loss Year",
    accessorKey: "Lossyear",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Loss Made",
    accessorKey: "Lossmade",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Loss Setoff",
    accessorKey: "Losssetoff",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Loss Outstanding",
    accessorKey: "Lossoutstanding",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Year Left",
    accessorKey: "Yearleft",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
];
