/* eslint-disable react/jsx-props-no-spreading */
import React, { PropsWithChildren } from "react";
import {
  Grid as GridBase,
  Container as ContainerBase,
  ContainerProps,
} from "@mui/material";

import { styled } from "@mui/material/styles";

import LabelKeyValueBase from "components/label-key-value/label-key-value.component";

const Grid = GridBase;

interface CustomContainerProps extends PropsWithChildren<ContainerProps> {
  maxHeight?: number;
}

const ContainerStyled = styled(ContainerBase, {
  shouldForwardProp: (prop) => prop !== "maxHeight",
})<CustomContainerProps>(({ maxHeight }) => ({
  overflowX: "auto",
  maxHeight: maxHeight ? `${maxHeight}px` : "inherit",
  height: maxHeight ? `${maxHeight}px` : "inherit",
}));

const Container = React.forwardRef<HTMLDivElement, CustomContainerProps>(
  (props, ref) => <ContainerStyled {...props} ref={ref} />,
);

const LabelKeyValue = LabelKeyValueBase;

export default {
  Grid,
  Container,
  LabelKeyValue,
};
