/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TableColumnDef } from "components/table/table.component";
import { GrcCorporateFragment } from "graphql/hasura/types-and-hooks";
import _ from "lodash";
import { Link } from "react-router-dom";
import { AccessorFn } from "@tanstack/react-table";
import { FilterAccessorValue } from "pages/reports/utils";

export type GRCCorporateSchema = Omit<GrcCorporateFragment, "__typename">;

export interface TabTableFilters {
  address: string;
  businessName: string;
  email: string;
  idNumber: string;
  phoneNumber: string;
}

export const DEFAULT_FILTER_ACCESSOR: Record<
  keyof TabTableFilters,
  FilterAccessorValue<GrcCorporateFragment>
> = {
  phoneNumber: ["Telephone", "Telephone2"],
  email: ["Companyemail"],
  businessName: ["Companyname"],
  address: ["Principaladdress"],
  idNumber: ["Companyid", "Companynumber", "Taxid"],
};

export const GRCCorporateSchemaMap: Record<keyof GRCCorporateSchema, "string"> =
  {
    Type: "string",
    Companyid: "string",
    Companynumber: "string",
    Taxid: "string",
    Companyname: "string",
    Principaladdress: "string",
    Principaladdressline1: "string",
    Principaladdresscity: "string",
    Principaladdressdistrict: "string",
    Principaladdressregion: "string",
    Principaladdresscountry: "string",
    Telephone: "string",
    Telephone2: "string",
    Companyemail: "string",
    Fax: "string",
    Statusname: "string",
    Dateofincorporation: "string",
    Typeofbusinessother: "string",
  };

export const getAccessorType = (
  accessor:
    | keyof GRCCorporateSchema
    | AccessorFn<GRCCorporateSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return GRCCorporateSchemaMap[accessor];
};

export const columns: TableColumnDef<GRCCorporateSchema>[] = [
  {
    header: "Type",
    accessorKey: "Type",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Corporate ID",
    accessorKey: "Companyid",
    align: "left",
    meta: { sort: false },
    cell: ({ getValue }) => {
      const value = getValue();
      return (
        <Link to={`/corporates/${value}/officers-owners`} color="inherit">
          <>{value}</>
        </Link>
      );
    },
  },
  {
    header: "Corporate Number",
    accessorKey: "Companynumber",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Tax ID",
    accessorKey: "Taxid",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Name",
    accessorKey: "Companyname",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Physical Address",
    accessorKey: "Principaladdress",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Address Line 1",
    accessorKey: "Principaladdressline1",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "City",
    accessorKey: "Principaladdresscity",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "District",
    accessorKey: "Principaladdressdistrict",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Region",
    accessorKey: "Principaladdressregion",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Country",
    accessorKey: "Principaladdresscountry",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Phone",
    accessorKey: "Telephone",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Phone 2",
    accessorKey: "Telephone2",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Email",
    accessorKey: "Companyemail",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Fax",
    accessorKey: "Fax",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Status",
    accessorKey: "Statusname",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Registration Date",
    accessorKey: "Dateofincorporation",
    align: "left",
    meta: { sort: false },
    format: "date",
  },
  {
    header: "Business Type",
    accessorKey: "Typeofbusinessother",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
];
