/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TableColumnDef } from "components/table/table.component";
import { GrcOfficerOwnerFragment } from "graphql/hasura/types-and-hooks";
import _ from "lodash";
import { Link } from "react-router-dom";
import { AccessorFn } from "@tanstack/react-table";
import { FilterAccessorValue } from "pages/reports/utils";

export type GRCOfficerOwnerSchema = Omit<
  GrcOfficerOwnerFragment,
  | "__typename"
  | "Corpcompanynumber"
  | "Corpprincipaladdress"
  | "Corpstatusname"
  | "Corpdateofincorporation"
  | "Corptypeofbusinessother"
  | "Firstname"
  | "Middlename"
  | "Lastname"
  | "Residentialaddress"
  | "Residentialaddressline1"
  | "Residentialaddresscity"
  | "Residentialaddressdistrict"
  | "Residentialaddressregion"
  | "Residentialaddresscountry"
  | "Dateofbirth"
  | "Nationality"
  | "Profession"
  | "Postaladdress"
  | "Postaladdressline1"
  | "Postaladdresscity"
  | "Postaladdressdistrict"
  | "Postaladdressregion"
  | "Postaladdresscountry"
  | "Createdon"
  | "Numberallottedprocent"
  | "Particularsofsharesnumber"
  | "Currencyid"
  | "Dateofappointment"
>;

export interface TabTableFilters {
  address: string;
  businessName: string;
  email: string;
  firstName: string;
  idNumber: string;
  phoneNumber: string;
  plusCode: string;
  surname: string;
}

export const DEFAULT_FILTER_ACCESSOR: Record<
  keyof TabTableFilters,
  FilterAccessorValue<GrcOfficerOwnerFragment>
> = {
  phoneNumber: ["Phonemobile", "Phonenumber", "Phonenumber2", "Fax"],
  email: ["Email"],
  surname: ["Lastname"],
  firstName: ["Firstname", "Middlename"],
  businessName: ["Corpcompanyname"],
  plusCode: ["Postaladdress", "Residentialaddress"],
  address: ["Residentialaddress"],
  idNumber: ["Personid", "Taxid", "Companyid", "Id"],
};

export const GRCOfficerOwnerSchemaMap: Record<
  keyof GRCOfficerOwnerSchema,
  "string" | "number"
> = {
  Id: "string",
  Relationship: "string",
  Personid: "string",
  Companyid: "string",
  Taxid: "string",
  Fullname: "string",
  Phonemobile: "string",
  Phonenumber: "string",
  Phonenumber2: "string",
  Fax: "string",
  Email: "string",
  Corptype: "string",
  Corpcompanyname: "string",
  Corpcompanytaxid: "string",
  Corpcompanyemail: "string",
  Corpfax: "string",
  Corpprincipaladdressline1: "string",
  Corpprincipaladdresscity: "string",
  Corpprincipaladdressdistrict: "string",
  Corpprincipaladdressregion: "string",
  Corpprincipaladdresscountry: "string",
  Corptelephone: "string",
  Corptelephone2: "string",
  Sex: "string",
};

export const getAccessorType = (
  accessor:
    | keyof GRCOfficerOwnerSchema
    | AccessorFn<GRCOfficerOwnerSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return GRCOfficerOwnerSchemaMap[accessor];
};

export const columns: TableColumnDef<GRCOfficerOwnerSchema>[] = [
  {
    header: "Corporate ID",
    accessorKey: "Companyid",
    align: "left",
    meta: { sort: false },
    cell: ({ getValue }) => {
      const value = getValue();
      return (
        <Link to={`/corporates/${value}/officers-owners`} color="inherit">
          <>{value}</>
        </Link>
      );
    },
  },
  {
    header: "Owner/Officer ID",
    accessorKey: "Id",
    align: "left",
    meta: { sort: false },
    cell: ({ getValue, row }) => {
      const value = row.original?.Personid;
      return (
        <Link to={`/individuals/${value}/corporate`} color="inherit">
          <>{value}</>
        </Link>
      );
    },
  },
  {
    header: "Type",
    accessorKey: "Corptype",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Corporate Name",
    accessorKey: "Corpcompanyname",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Relationship",
    accessorKey: "Relationship",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Owner/Officer Name",
    accessorKey: "Fullname",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Owner/Officer Tax ID",
    accessorKey: "Taxid",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Corporate Tax ID",
    accessorKey: "Corpcompanytaxid",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Corporate Phone 1",
    accessorKey: "Corptelephone",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Corporate Phone 2",
    accessorKey: "Corptelephone2",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Corporate Email",
    accessorKey: "Corpcompanyemail",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Corporate Fax",
    accessorKey: "Corpfax",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Corporate Address Line 1",
    accessorKey: "Corpprincipaladdressline1",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Corporate City",
    accessorKey: "Corpprincipaladdresscity",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Corporate District",
    accessorKey: "Corpprincipaladdressdistrict",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Corporate Region",
    accessorKey: "Corpprincipaladdressregion",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Corporate Country",
    accessorKey: "Corpprincipaladdresscountry",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Owner/Officer Mobile",
    accessorKey: "Phonemobile",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Owner/Officer Phone 1",
    accessorKey: "Phonenumber",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Owner/Officer Phone 2",
    accessorKey: "Phonenumber2",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Owner/Officer Fax",
    accessorKey: "Fax",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Owner/Officer Email",
    accessorKey: "Email",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
];
