/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
// Dependencies
import { toDate, format } from "date-fns-tz";
import { addYears, subYears, parse } from "date-fns";

import { CustomFormatConfig, FormatNumberOptions } from "react-intl";

export const DEFAULT_TIME_ZONE = "Africa/Banjul";
// TODO: change to this format when all the date fields have been updated to this format
// "yyyy-MM-ddTHH:mm:ss.SSS+-HH:mm"
export const DEFAULT_HASURA_DATETIME_FORMAT = "yyyy-MM-dd HH:mm:ssXXX";

export type RtcsFormattedNumberProps = FormatNumberOptions &
  CustomFormatConfig & {
    value: number | bigint;
  };

export const createDate = (
  y: number | string,
  m: number | string,
  d: number | string,
  hh = 0,
  mm = 0,
  ss = 0,
) => {
  if (typeof y === "string") y = parseInt(y, 10);
  if (typeof m === "string") m = parseInt(m, 10);
  if (typeof d === "string") d = parseInt(d, 10);

  // TODO: Review that this code create dates to the right
  // timezone.
  const ugdate = toDate(new Date(y, m, d, hh, mm, ss));
  return format(ugdate, DEFAULT_HASURA_DATETIME_FORMAT, {
    timeZone: DEFAULT_TIME_ZONE,
  });
};

export const getMonth = (date: string) => {
  return date
    ? format(toDate(date), "MMM", { timeZone: DEFAULT_TIME_ZONE })
    : "";
};

export const createDateTimeString = (date: string): string => {
  const dateStr = format(toDate(date), "dd MMMM, yyyy", {
    timeZone: DEFAULT_TIME_ZONE,
  });

  const formatter = new Intl.DateTimeFormat("en-UG", {
    timeZone: "Africa/Banjul",
    timeZoneName: "short",
  });

  const timeZoneName = formatter
    .formatToParts(toDate(date))
    .filter((part) => part.type === "timeZoneName")[0].value;

  const timeStr = format(toDate(date), "h:mm a", {
    timeZone: DEFAULT_TIME_ZONE,
  });

  return `${dateStr} at ${timeStr} ${timeZoneName}`;
};

export const getFormattedDate = (date: string): string => {
  return format(toDate(date), "yyyy-MM-dd", {
    timeZone: DEFAULT_TIME_ZONE,
  });
};

export const getYear = (date: string) => {
  return date
    ? format(toDate(date), "yyyy", { timeZone: DEFAULT_TIME_ZONE })
    : "";
};

export const addYear = (date: Date, count: number) => addYears(date, count);

export const subtractYear = (iso: string, count: number): string => {
  return format(subYears(toDate(iso), count), DEFAULT_HASURA_DATETIME_FORMAT);
};

export const createDateFromISO = (iso: string): string =>
  format(toDate(iso || new Date()), DEFAULT_HASURA_DATETIME_FORMAT, {
    timeZone: DEFAULT_TIME_ZONE,
  });

export const getMonthDigit = (date: string) => {
  return date ? format(toDate(date), "M", { timeZone: DEFAULT_TIME_ZONE }) : 0;
};

/**
 * Converts a local date string in multiple common formats to a UTC date string.
 * Supported formats: "dd-MM-yyyy," "dd/MM/yyyy," "MM/dd/yyyy," "yyyy-MM-dd," "yyyy/MM/dd," "dd mm, yyyy," "mm dd, yyyy," "MMM dd, yyyy," "MMMM dd, yyyy"
 * @param {string} localDate - The local date string to convert.
 * @returns {string} The UTC date string in ISO 8601 format.
 * @throws {Error} If the input date string is invalid or cannot be parsed.
 */
export const convertLocalDateStringToUTC = (localDate: string): string => {
  const supportedDateFormats: string[] = [
    "dd-MM-yyyy",
    "dd/MM/yyyy",
    "MM/dd/yyyy",
    "yyyy-MM-dd",
    "yyyy/MM/dd",
    "MMM dd, yyyy",
    "MMMM dd, yyyy",
  ];

  // Attempt to parse the input local date string into a JavaScript Date object
  const parsedDate = supportedDateFormats
    .map((dateFormat) => parse(localDate, dateFormat, new Date()))
    .find((date) => !Number.isNaN(date.getTime()));

  if (parsedDate) {
    return parsedDate.toISOString();
  }

  // If the input date string cannot be parsed, throw an error indicating an invalid date string
  throw new Error("Invalid date string");
};
