import { Location } from 'react-router-dom';

export const ROUTE_PATTERNS = {
  AFRICA_360: {
    KANIFING_PROPERTIES: '/reports/MDA/Africa-360-view/kanifing-properties',
    BANJUL_PROPERTIES: '/reports/MDA/Africa-360-view/banjul-properties',
    ALL_MDA_SEARCH: '/reports/MDA/all-MDA-search/africa-360-view',
  }
}; 

// Define route patterns and their corresponding parent paths
const routePatterns = {
  'kanifing-properties': ROUTE_PATTERNS.AFRICA_360.KANIFING_PROPERTIES,
  'banjul-properties': ROUTE_PATTERNS.AFRICA_360.BANJUL_PROPERTIES,
  'all-MDA-search': ROUTE_PATTERNS.AFRICA_360.ALL_MDA_SEARCH,
};

type RoutePattern = keyof typeof routePatterns;

/**
 * Gets the source path based on the current location
 * @param location - The current location from useLocation()
 * @returns The source path to return to
 */
export const getSourcePath = (location: Location): string => {
  const currentPath = location.pathname;

  // Find the matching route pattern in the current path
  const matchingPattern = Object.keys(routePatterns).find(pattern => 
    currentPath.includes(pattern)
  ) as RoutePattern | undefined;

  // Get the corresponding parent path or use the default one
  return matchingPattern 
    ? routePatterns[matchingPattern]
    : ROUTE_PATTERNS.AFRICA_360.KANIFING_PROPERTIES;
};
