import { TableColumnDef } from "components/table/table.component";
import { IndividualTaxPayerFragment } from "graphql/hasura/types-and-hooks";
import _ from "lodash";
import { AccessorFn } from "@tanstack/react-table";
import { FilterAccessorValue } from "pages/reports/utils";

export type IndividualTaxPayerTableSchema = Omit<
  IndividualTaxPayerFragment,
  "__typename"
>;

export interface IndividualTaxPayerTableFilters {
  address: string;
  businessName: string;
  email: string;
  firstName: string;
  idNumber: string;
  phoneNumber: string;
  surname: string;
}

export const DEFAULT_FILTER_ACCESSOR: Record<
  keyof IndividualTaxPayerTableFilters,
  FilterAccessorValue<IndividualTaxPayerFragment>
> = {
  phoneNumber: ["Phoneno", "Phoneno2", "Faxno"],
  email: ["Email"],
  surname: ["Lname"],
  firstName: ["Fname", "Mname", "Declarant"],
  businessName: ["Tradingname"],
  address: ["Blockname", "Physplotno", "Physstreet", "Phystown", "Physarea"],
  idNumber: ["Tin", "Nin", "Passportno"],
};

export const IndividualTaxPayerTableSchemaMap: Record<
  keyof IndividualTaxPayerTableSchema,
  "string" | "date"
> = {
  Regtypecode: "string",
  Tin: "string",
  Fname: "string",
  Mname: "string",
  Lname: "string",
  Declarant: "string",
  Deccapacity: "string",
  Phoneno: "string",
  Phoneno2: "string",
  Faxno: "string",
  Email: "string",
  Birthdate: "date",
  Gender: "string",
  Nin: "string",
  Remark: "string",
  Passportno: "string",
  Blockname: "string",
  Physplotno: "string",
  Physstreet: "string",
  Phystown: "string",
  Physarea: "string",
  Physprov: "string",
  Country: "string",
  Chkcitizen: "string",
  Mfname: "string",
  Mlname: "string",
  Regdate: "date",
  Postalbox: "string",
  Postaltown: "string",
  Postalprov: "string",
  Emptin: "string",
  Empaddress: "string",
  Empphoneno1: "string",
  Empphoneno2: "string",
  Empmobile: "string",
  Empemail: "string",
  Agname: "string",
  Agtin: "string",
  Agaddress: "string",
  Agphoneno: "string",
  Chktaxexempt: "string",
  Largtaxpayer: "string",
  Largetaxpayerstatus: "string",
  Approveddate: "date",
  Createddate: "date",
  Tradingname: "string",
};

export const getAccessorType = (
  accessor:
    | keyof IndividualTaxPayerTableSchema
    | AccessorFn<IndividualTaxPayerTableSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return IndividualTaxPayerTableSchemaMap[accessor];
};

export const columns: TableColumnDef<IndividualTaxPayerTableSchema>[] = [
  {
    header: "Taxpayer Type",
    accessorKey: "Regtypecode",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "TIN",
    accessorKey: "Tin",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "First Name",
    accessorKey: "Fname",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Middle Name",
    accessorKey: "Mname",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Last Name",
    accessorKey: "Lname",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Declarant Name",
    accessorKey: "Declarant",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Declarant Capacity",
    accessorKey: "Deccapacity",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Phone 1",
    accessorKey: "Phoneno",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Phone 2",
    accessorKey: "Phoneno2",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Fax Number",
    accessorKey: "Faxno",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Email",
    accessorKey: "Email",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Date of Birth",
    accessorKey: "Birthdate",
    align: "left",
    meta: { sort: false },
    format: "date",
  },
  {
    header: "Gender",
    accessorKey: "Gender",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "NIN",
    accessorKey: "Nin",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "ID Remark",
    accessorKey: "Remark",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Passport Number",
    accessorKey: "Passportno",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Block Name",
    accessorKey: "Blockname",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Street Number",
    accessorKey: "Physplotno",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Street",
    accessorKey: "Physstreet",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Town",
    accessorKey: "Phystown",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Area",
    accessorKey: "Physarea",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Province",
    accessorKey: "Physprov",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Country",
    accessorKey: "Country",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Citizen Check",
    accessorKey: "Chkcitizen",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Mother's First Name",
    accessorKey: "Mfname",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Mother's Last Name",
    accessorKey: "Mlname",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Registration Date",
    accessorKey: "Regdate",
    align: "left",
    meta: { sort: false },
    format: "date",
  },
  {
    header: "Postal Box",
    accessorKey: "Postalbox",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Postal Town",
    accessorKey: "Postaltown",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Postal Province",
    accessorKey: "Postalprov",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Employer TIN",
    accessorKey: "Emptin",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Employer Address",
    accessorKey: "Empaddress",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Employer Phone 1",
    accessorKey: "Empphoneno1",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Employer Phone 2",
    accessorKey: "Empphoneno2",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Employer Mobile",
    accessorKey: "Empmobile",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Employer Email",
    accessorKey: "Empemail",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Tax Agent Name",
    accessorKey: "Agname",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Tax Agent TIN",
    accessorKey: "Agtin",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Tax Agent Address",
    accessorKey: "Agaddress",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Tax Agent Phone",
    accessorKey: "Agphoneno",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Tax Exempt",
    accessorKey: "Chktaxexempt",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Large Tax Payer",
    accessorKey: "Largtaxpayer",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Large Tax Payer Status",
    accessorKey: "Largetaxpayerstatus",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Approved Date",
    accessorKey: "Approveddate",
    align: "left",
    meta: { sort: false },
    format: "date",
  },
  {
    header: "Created Date",
    accessorKey: "Createddate",
    align: "left",
    meta: { sort: false },
    format: "date",
  },
  {
    header: "Trading Name",
    accessorKey: "Tradingname",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
];
