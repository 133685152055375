import { TableColumnDef } from "components/table/table.component";
import { AccessorFn } from "@tanstack/react-table";
import _ from "lodash";
import {
  Gbos2024PopulationAndHousingCensusFragment,
  Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus,
} from "graphql/hasura/types-and-hooks";
import { FilterAccessorValue } from "pages/reports/utils";

export type Gbos2024PopulationAndHousingCensusSchema = Omit<
  Gbos2024PopulationAndHousingCensusFragment,
  "__typename"
>;

export interface Gbos2024PopulationAndHousingCensusTableFilters {
  address: string;
  businessName: string;
  firstName: string;
  phoneNumber: string;
  surname: string;
}

export const DEFAULT_FILTER_ACCESSOR: Record<
  keyof Gbos2024PopulationAndHousingCensusTableFilters,
  FilterAccessorValue<Rtcs_Gm_Dev_Ph_Gbos_2024PopulationAndHousingCensus>
> = {
  address: ["Districtname", "Wardname", "Lganame", "Settlementname"],
  businessName: ["Compoundowner", "Headofcompound", "HHhHeadName"],
  firstName: ["Compoundowner", "Headofcompound", "HHhHeadName"],
  phoneNumber: ["HHeadPhone"],
  surname: ["Compoundowner", "Headofcompound", "HHhHeadName"],
};
export const COMMERCIAL_RENTAL_RATES_SURVEY_SCHEMA_MAP: Record<
  keyof Gbos2024PopulationAndHousingCensusSchema,
  "string" | "number" | "Date"
> = {
  Compoundowner: "string",
  CookingFuel: "string",
  Districtname: "string",
  FloorMaterial: "string",
  FreqSolidWasteCollection: "string",
  HHeadPhone: "string",
  HHhHeadName: "string",
  HHhNumber: "number",
  Headofcompound: "string",
  Latitude: "string",
  Lganame: "string",
  LiqWasteFromEconomic: "string",
  LiqWasteFromHhchores: "string",
  LiqWasteFromHumanConve: "string",
  Longitude: "string",
  NumOfFemale: "number",
  NumOfMale: "number",
  OccupancyStatus: "string",
  PayCompoundRate: "string",
  PlaceForBathing: "string",
  PlaceForCooking: "string",
  RoofMaterial: "string",
  Settlementname: "string",
  SolidWasteDisposal: "string",
  SourceDrinkingWater: "string",
  SourceLighting: "string",
  SuitableForResidence: "string",
  TitleDeed: "string",
  ToiletFacility: "string",
  TotalNum: "number",
  Uuid: "string",
  WallsMaterial: "string",
  Wardname: "string",
};

export const getAccessorType = (
  accessor:
    | keyof Gbos2024PopulationAndHousingCensusSchema
    | AccessorFn<Gbos2024PopulationAndHousingCensusSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return COMMERCIAL_RENTAL_RATES_SURVEY_SCHEMA_MAP[accessor];
};

export const tableColumns: TableColumnDef<Gbos2024PopulationAndHousingCensusSchema>[] =
  [
    {
      header: "Property Owner",
      accessorKey: "Compoundowner",
      align: "left",
      format: "string",
    },
    {
      header: "Ward",
      accessorKey: "Wardname",
      align: "left",
      format: "string",
    },
    {
      header: "District",
      accessorKey: "Districtname",
      align: "left",
      format: "string",
    },
    {
      header: "Settlement",
      accessorKey: "Settlementname",
      align: "left",
      format: "string",
    },
    { header: "LGA", accessorKey: "Lganame", align: "left", format: "string" },
    {
      header: "Occupancy Status",
      accessorKey: "OccupancyStatus",
      align: "left",
      format: "string",
    },
    {
      header: "Property Manager",
      accessorKey: "Headofcompound",
      align: "left",
      format: "string",
    },
    {
      header: "Principal Resident",
      accessorKey: "HHhHeadName",
      align: "left",
      format: "string",
    },
    {
      header: "Principal Resident Phone",
      accessorKey: "HHeadPhone",
      align: "left",
      format: "string",
    },
    {
      header: "Resident Has Title Deed For The Property",
      accessorKey: "TitleDeed",
      align: "left",
      format: "string",
    },
    {
      header: "Resident Pays Compound Yard Rate/Rent",
      accessorKey: "PayCompoundRate",
      align: "left",
      format: "string",
    },
    {
      header: "Number Of Households In Compound",
      accessorKey: "HHhNumber",
      align: "right",
      format: "number",
    },
    {
      header: "Number Of Males In Household",
      accessorKey: "NumOfMale",
      align: "right",
      format: "number",
    },
    {
      header: "Number Of Females In Household",
      accessorKey: "NumOfFemale",
      align: "right",
      format: "number",
    },
    {
      header: "Total Number Of Persons In Household",
      accessorKey: "TotalNum",
      align: "right",
      format: "number",
    },
    {
      header: "Lighting Source",
      accessorKey: "SourceLighting",
      align: "left",
      format: "string",
    },
    {
      header: "Cooking Facilities",
      accessorKey: "PlaceForCooking",
      align: "left",
      format: "string",
    },
    {
      header: "Cooking Fuel",
      accessorKey: "CookingFuel",
      align: "left",
      format: "string",
    },
    {
      header: "Bathing Facilities",
      accessorKey: "PlaceForBathing",
      align: "left",
      format: "string",
    },
    {
      header: "Toilet Facilities",
      accessorKey: "ToiletFacility",
      align: "left",
      format: "string",
    },
    {
      header: "Source Of Drinking Water",
      accessorKey: "SourceDrinkingWater",
      align: "left",
      format: "string",
    },
    {
      header: "Solid Waste Disposal",
      accessorKey: "SolidWasteDisposal",
      align: "left",
      format: "string",
    },
    {
      header: "Frequency Of Solid Waste Collection",
      accessorKey: "FreqSolidWasteCollection",
      align: "left",
      format: "string",
    },
    {
      header: "Liquid Waste From Human Convenience",
      accessorKey: "LiqWasteFromHumanConve",
      align: "left",
      format: "string",
    },
    {
      header: "Liquid Waste From Household Chores",
      accessorKey: "LiqWasteFromHhchores",
      align: "left",
      format: "string",
    },
    {
      header: "Liquid Waste From Economic Activity",
      accessorKey: "LiqWasteFromEconomic",
      align: "left",
      format: "string",
    },
    {
      header: "Property Suitable For Residence",
      accessorKey: "SuitableForResidence",
      align: "left",
      format: "string",
    },
    {
      header: "Wall Material",
      accessorKey: "WallsMaterial",
      align: "left",
      format: "string",
    },
    {
      header: "Roof Material",
      accessorKey: "RoofMaterial",
      align: "left",
      format: "string",
    },
    {
      header: "Floor Material",
      accessorKey: "FloorMaterial",
      align: "left",
      format: "string",
    },
    {
      header: "Latitude",
      accessorKey: "Latitude",
      align: "left",
      format: "string",
    },
    {
      header: "Longitude",
      accessorKey: "Longitude",
      align: "left",
      format: "string",
    },
  ];
