import { TableColumnDef } from "components/table/table.component";
import { AccessorFn } from "@tanstack/react-table";
import _ from "lodash";
import {
  Gbos2024GambiaSocialRegistryFragment,
  Rtcs_Gm_Dev_Ph_Gbos_2024GambiaSocialRegistry,
} from "graphql/hasura/types-and-hooks";
import { FilterAccessorValue } from "pages/reports/utils";

export type Gbos2024GambiaSocialRegistrySchema = Omit<
  Gbos2024GambiaSocialRegistryFragment,
  "__typename" | "Uuid"
>;

export interface Gbos2024GambiaSocialRegistryTableFilters {
  address: string;
  idNumber: string;
}

export const DEFAULT_FILTER_ACCESSOR: Record<
  keyof Gbos2024GambiaSocialRegistryTableFilters,
  FilterAccessorValue<Rtcs_Gm_Dev_Ph_Gbos_2024GambiaSocialRegistry>
> = {
  address: ["Address", "Lga", "Settlement", "Latitude", "Longitude"],
  idNumber: ["Compound"],
};

export const GAMBIA_SOCIAL_REGISTRY_SCHEMA_MAP: Record<
  keyof Gbos2024GambiaSocialRegistrySchema,
  "string" | "number" | "Date"
> = {
  Address: "string",
  Compound: "string",
  District: "string",
  ExteriorWall: "string",
  FlooringMaterial: "string",
  Latitude: "string",
  Lga: "string",
  Longitude: "string",
  RoofingMaterial: "string",
  Settlement: "string",
  Ward: "string",
};

export const getAccessorType = (
  accessor:
    | keyof Gbos2024GambiaSocialRegistrySchema
    | AccessorFn<Gbos2024GambiaSocialRegistrySchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return GAMBIA_SOCIAL_REGISTRY_SCHEMA_MAP[accessor];
};

export const tableColumns: TableColumnDef<Gbos2024GambiaSocialRegistrySchema>[] =
  [
    {
      header: "Compound ID",
      accessorKey: "Compound",
      align: "left",
      format: "string",
    },
    {
      header: "LGA",
      accessorKey: "Lga",
      align: "left",
      format: "string",
    },
    {
      header: "Address",
      accessorKey: "Address",
      align: "left",
      format: "string",
    },
    {
      header: "Settlement",
      accessorKey: "Settlement",
      align: "left",
      format: "string",
    },
    {
      header: "Ward",
      accessorKey: "Ward",
      align: "left",
      format: "string",
    },
    {
      header: "District",
      accessorKey: "District",
      align: "left",
      format: "string",
    },
    {
      header: "Wall Material",
      accessorKey: "ExteriorWall",
      align: "left",
      format: "string",
    },
    {
      header: "Roof Material",
      accessorKey: "RoofingMaterial",
      align: "left",
      format: "string",
    },
    {
      header: "Floor Material",
      accessorKey: "FlooringMaterial",
      align: "left",
      format: "string",
    },
    {
      header: "Latitude",
      accessorKey: "Latitude",
      align: "left",
      format: "string",
    },
    {
      header: "Longitude",
      accessorKey: "Longitude",
      align: "left",
      format: "string",
    },
  ];
