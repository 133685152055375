/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TableColumnDef } from "components/table/table.component";
import { GrcOfficerOwnerFragment } from "graphql/hasura/types-and-hooks";
import _ from "lodash";
import { Link } from "react-router-dom";
import { AccessorFn } from "@tanstack/react-table";

export type GRCCorporateSchema = Omit<
  GrcOfficerOwnerFragment,
  | "__typename"
  | "Id"
  | "Personid"
  | "Fullname"
  | "Firstname"
  | "Middlename"
  | "Lastname"
  | "Phonemobile"
  | "Phonenumber"
  | "Phonenumber2"
  | "Email"
  | "Residentialaddress"
  | "Residentialaddressline1"
  | "Residentialaddresscity"
  | "Residentialaddressdistrict"
  | "Residentialaddressregion"
  | "Residentialaddresscountry"
  | "Dateofbirth"
  | "Sex"
  | "Nationality"
  | "Profession"
  | "Postaladdress"
  | "Postaladdressline1"
  | "Postaladdresscity"
  | "Postaladdressdistrict"
  | "Postaladdressregion"
  | "Postaladdresscountry"
  | "Createdon"
  | "Numberallottedprocent"
  | "Particularsofsharesnumber"
  | "Currencyid"
  | "Dateofappointment"
  | "Taxid"
  | "Fax"
>;

export const GRCCorporateSchemaMap: Record<
  keyof GRCCorporateSchema,
  "string" | "number"
> = {
  Corptype: "string",
  Companyid: "string",
  Corpcompanynumber: "string",
  Corpcompanytaxid: "string",
  Corpcompanyname: "string",
  Corpprincipaladdress: "string",
  Corpprincipaladdressline1: "string",
  Corpprincipaladdresscity: "string",
  Corpprincipaladdressdistrict: "string",
  Corpprincipaladdressregion: "string",
  Corpprincipaladdresscountry: "string",
  Corptelephone: "string",
  Corptelephone2: "string",
  Corpcompanyemail: "string",
  Corpfax: "string",
  Corpstatusname: "string",
  Corpdateofincorporation: "string",
  Corptypeofbusinessother: "string",
  Relationship: "string",
};

export const getAccessorType = (
  accessor:
    | keyof GRCCorporateSchema
    | AccessorFn<GRCCorporateSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return GRCCorporateSchemaMap[accessor];
};

export const columns: TableColumnDef<GRCCorporateSchema>[] = [
  {
    header: "Type",
    accessorKey: "Corptype",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Relationship",
    accessorKey: "Relationship",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Corporate ID",
    accessorKey: "Companyid",
    align: "left",
    meta: { sort: false },
    cell: ({ getValue }) => {
      const value = getValue();
      return (
        <Link to={`/corporates/${value}/officers-owners`} color="inherit">
          <>{value}</>
        </Link>
      );
    },
  },
  {
    header: "Corporate Number",
    accessorKey: "Corpcompanynumber",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Corporate Tax ID",
    accessorKey: "Corpcompanytaxid",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Corporate Name",
    accessorKey: "Corpcompanyname",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Corporate Physical Address",
    accessorKey: "Corpprincipaladdress",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Corporate Phone 1",
    accessorKey: "Corptelephone",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Corporate Phone 2",
    accessorKey: "Corptelephone2",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Corporate Fax",
    accessorKey: "Corpfax",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Corporate Email",
    accessorKey: "Corpcompanyemail",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Registration Date",
    accessorKey: "Corpdateofincorporation",
    align: "left",
    meta: { sort: false },
    format: "date",
  },
  {
    header: "Status",
    accessorKey: "Corpstatusname",
    align: "left",
    meta: { sort: false },
    format: "string",
    hiddenColumn: true,
  },
  {
    header: "Business Type",
    accessorKey: "Corptypeofbusinessother",
    align: "left",
    meta: { sort: false },
    format: "string",
    hiddenColumn: true,
  },
  {
    header: "Address Line 1",
    accessorKey: "Corpprincipaladdressline1",
    align: "left",
    meta: { sort: false },
    format: "string",
    hiddenColumn: true,
  },
  {
    header: "City",
    accessorKey: "Corpprincipaladdresscity",
    align: "left",
    meta: { sort: false },
    format: "string",
    hiddenColumn: true,
  },
  {
    header: "District",
    accessorKey: "Corpprincipaladdressdistrict",
    align: "left",
    meta: { sort: false },
    format: "string",
    hiddenColumn: true,
  },
  {
    header: "Region",
    accessorKey: "Corpprincipaladdressregion",
    align: "left",
    meta: { sort: false },
    format: "string",
    hiddenColumn: true,
  },
  {
    header: "Country",
    accessorKey: "Corpprincipaladdresscountry",
    align: "left",
    meta: { sort: false },
    format: "string",
    hiddenColumn: true,
  },
];
