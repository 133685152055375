// Dependencies
import React from "react";

// Components
import Breadcrumb from "components/breadcrumb/breadcrumb.component";
import { PropertyRatesRegisterTable } from "../property-rates-register-table/property-rates-register-table.component";

// Assets
import SC from "./property-rates-register.styles";

const KmcPropertyRatesRegister: React.FC = () => {
  return (
    <SC.Box pl={2} pr={2} pt={2}>
      <Breadcrumb />
      <PropertyRatesRegisterTable displayHeader displayTitle />
    </SC.Box>
  );
};

export default KmcPropertyRatesRegister;
