import { TableColumnDef } from "components/table/table.component";
import {
  Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister,
  TradeLicenseRegisterKmcFragment,
} from "graphql/hasura/types-and-hooks";
import _ from "lodash";
import { AccessorFn } from "@tanstack/react-table";
import { FilterAccessorValue } from "pages/reports/utils";

export type TradeLicenseRegisterSchema = Omit<
  TradeLicenseRegisterKmcFragment,
  "__typename"
>;

export interface TradeLicenseRegisterTableFilters {
  address: string;
  businessName: string;
  firstName: string;
  phoneNumber: string;
  surname: string;
}

export const DEFAULT_FILTER_ACCESSOR: Record<
  keyof TradeLicenseRegisterTableFilters,
  FilterAccessorValue<Rtcs_Gm_Dev_Ph_Kmc_TradeLicenseRegister>
> = {
  address: ["Address"],
  businessName: ["Nameofbusinessdatafielddescription"],
  firstName: ["Nameofbusinessdatafielddescription"],
  phoneNumber: ["Phoneno"],
  surname: ["Nameofbusinessdatafielddescription"],
};

export const TradeLicenseRegisterSchemaMap: Record<
  keyof TradeLicenseRegisterSchema,
  "string" | "number" | "date"
> = {
  Address: "string",
  Nameofbusinessdatafielddescription: "string",
  Phoneno: "string",
  Source: "string",
  Type: "string",
  Uuid: "string",
};

export const getAccessorType = (
  accessor:
    | keyof TradeLicenseRegisterSchema
    | AccessorFn<TradeLicenseRegisterSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;
  return TradeLicenseRegisterSchemaMap[accessor];
};

export const columns: TableColumnDef<TradeLicenseRegisterSchema>[] = [
  {
    header: "UUID",
    accessorKey: "Uuid",
    align: "left",
    format: "string",
  },
  {
    header: "Source",
    accessorKey: "Source",
    align: "left",
    format: "string",
  },
  {
    header: "Trade Licence Type",
    accessorKey: "Type",
    align: "left",
    format: "string",
  },
  {
    header: "Name",
    accessorKey: "Nameofbusinessdatafielddescription",
    align: "left",
    format: "string",
  },
  {
    header: "Address",
    accessorKey: "Address",
    align: "left",
    format: "string",
  },
  {
    header: "Phone",
    accessorKey: "Phoneno",
    align: "left",
    format: "string",
  },
];
