import { TableColumnDef } from "components/table/table.component";
import { CorporateTaxPayerFragment } from "graphql/hasura/types-and-hooks";
import _ from "lodash";
import { AccessorFn } from "@tanstack/react-table";
import { FilterAccessorValue } from "pages/reports/utils";

export type CorporateTaxPayerTableSchema = Omit<
  CorporateTaxPayerFragment,
  "__typename"
>;

export interface CorporateTaxPayerTableFilters {
  address: string;
  businessName: string;
  email: string;
  firstName: string;
  idNumber: string;
  phoneNumber: string;
  surname: string;
}

export const DEFAULT_FILTER_ACCESSOR: Record<
  keyof CorporateTaxPayerTableFilters,
  FilterAccessorValue<CorporateTaxPayerFragment>
> = {
  phoneNumber: ["Phoneno", "Phoneno2", "Faxno"],
  email: ["Email"],
  surname: ["Lname"],
  firstName: ["Fname", "Declarant"],
  businessName: ["Fname"],
  address: ["Blockname", "Physplotno", "Physstreet", "Phystown", "Physarea"],
  idNumber: ["Tin", "Nin"],
};

export const CorporateTaxPayerTableSchemaMap: Record<
  keyof CorporateTaxPayerTableSchema,
  "string" | "date"
> = {
  Regtypecode: "string",
  Tin: "string",
  Fname: "string",
  Lname: "string",
  Declarant: "string",
  Deccapacity: "string",
  Phoneno: "string",
  Phoneno2: "string",
  Faxno: "string",
  Email: "string",
  Birthdate: "date",
  Nin: "string",
  Blockname: "string",
  Physplotno: "string",
  Physstreet: "string",
  Phystown: "string",
  Physarea: "string",
  Physprov: "string",
  Country: "string",
  Chkcitizen: "string",
  Postalbox: "string",
  Postaltown: "string",
  Postalprov: "string",
  Busstartdate: "date",
  Emptin: "string",
  Empaddress: "string",
  Empphoneno1: "string",
  Empphoneno2: "string",
  Empmobile: "string",
  Agname: "string",
  Agtin: "string",
  Agaddress: "string",
  Agphoneno: "string",
  Ninincorpcert: "string",
  Chktaxexempt: "string",
  Largtaxpayer: "string",
  Largetaxpayerstatus: "string",
  Approveddate: "date",
  Createddate: "date",
  Tradingname: "string",
  Businesscode: "string",
};

export const getAccessorType = (
  accessor:
    | keyof CorporateTaxPayerTableSchema
    | AccessorFn<CorporateTaxPayerTableSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return CorporateTaxPayerTableSchemaMap[accessor];
};

export const columns: TableColumnDef<CorporateTaxPayerTableSchema>[] = [
  {
    header: "Taxpayer Type",
    accessorKey: "Regtypecode",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "TIN",
    accessorKey: "Tin",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Taxpayer Name 1",
    accessorKey: "Fname",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Taxpayer Name 2",
    accessorKey: "Lname",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Declarant Name",
    accessorKey: "Declarant",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Declarant Capacity",
    accessorKey: "Deccapacity",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Phone 1",
    accessorKey: "Phoneno",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Phone 2",
    accessorKey: "Phoneno2",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Fax Number",
    accessorKey: "Faxno",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Email",
    accessorKey: "Email",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Block Name",
    accessorKey: "Blockname",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Street Number",
    accessorKey: "Physplotno",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Street",
    accessorKey: "Physstreet",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Town",
    accessorKey: "Phystown",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Area",
    accessorKey: "Physarea",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Province",
    accessorKey: "Physprov",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Country",
    accessorKey: "Country",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "ID Number",
    accessorKey: "Nin",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Postal Box",
    accessorKey: "Postalbox",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Postal Town",
    accessorKey: "Postaltown",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Postal Province",
    accessorKey: "Postalprov",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Date of Registration",
    accessorKey: "Birthdate",
    align: "left",
    meta: { sort: false },
    format: "date",
  },
  {
    header: "Citizen Check",
    accessorKey: "Chkcitizen",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Business Start Date",
    accessorKey: "Busstartdate",
    align: "left",
    meta: { sort: false },
    format: "date",
  },
  {
    header: "Employer TIN",
    accessorKey: "Emptin",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Employer Address",
    accessorKey: "Empaddress",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Employer Phone 1",
    accessorKey: "Empphoneno1",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Employer Phone 2",
    accessorKey: "Empphoneno2",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Employer Mobile",
    accessorKey: "Empmobile",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Tax Agent TIN",
    accessorKey: "Agtin",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Tax Agent Name",
    accessorKey: "Agname",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Tax Agent Address",
    accessorKey: "Agaddress",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Tax Agent Phone",
    accessorKey: "Agphoneno",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "NIN / Incorp Certificate",
    accessorKey: "Ninincorpcert",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Tax Exempt",
    accessorKey: "Chktaxexempt",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Large Tax Payer",
    accessorKey: "Largtaxpayer",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Large Tax Payer Status",
    accessorKey: "Largetaxpayerstatus",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Approved Date",
    accessorKey: "Approveddate",
    align: "left",
    meta: { sort: false },
    format: "date",
  },
  {
    header: "Created Date",
    accessorKey: "Createddate",
    align: "left",
    meta: { sort: false },
    format: "date",
  },
  {
    header: "Trading Name",
    accessorKey: "Tradingname",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Business Code",
    accessorKey: "Businesscode",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
];
