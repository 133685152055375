/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-use-before-define */
// Utils
import { isFirstRender } from "../../shared/utils/data";

export function calculateBoundsForFeatures(features) {
  const reducer = (bounds, feature) => {
    const { lng, lat } = feature.position;
    const { ne, sw } = bounds;

    const ne_lat = lat > ne.lat ? lat : ne.lat;
    const ne_lng = lng > ne.lng ? lng : ne.lng;
    const sw_lat = lat < sw.lat ? lat : sw.lat;
    const sw_lng = lng < sw.lng ? lng : sw.lng;

    return {
      ne: {
        lat: ne_lat,
        lng: ne_lng,
      },
      nw: {
        lat: ne_lat,
        lng: sw_lng,
      },
      se: {
        lat: sw_lat,
        lng: ne_lng,
      },
      sw: {
        lat: sw_lat,
        lng: sw_lng,
      },
    };
  };

  if (!isFirstRender(features)) {
    const { lng, lat } = features[0].position;
    const initialBounds = {
      ne: { lat, lng },
      nw: { lat, lng },
      sw: { lat, lng },
      se: { lat, lng },
    };
    return features.reduce(reducer, initialBounds);
  }

  return undefined;
}

export function isValidBounds(bounds) {
  const { ne, nw, sw, se } = bounds;

  return !(
    (ne.lat === sw.lat && ne.lng === sw.lng) ||
    (nw.lat === se.lat && nw.lng === se.lng)
  );
}

export function calculateZoomByLatitudeAndMetersPerPixel(
  latitude,
  metersPerPixel,
) {
  // https://gis.stackexchange.com/questions/7430/what-ratio-scales-do-google-maps-zoom-levels-correspond-to
  return Math.log2(
    (156543.03392 * Math.cos((latitude * Math.PI) / 180)) / metersPerPixel,
  );
}

export function calculateZoomByLatitudeAndFeetPerInch(latitude, feetPerInch) {
  // 1 meter = 3.28084 feet, 1 inch = 96 pixels
  const metersPerPixel = feetPerInch / (3.28084 * 96);
  return calculateZoomByLatitudeAndMetersPerPixel(latitude, metersPerPixel);
}

export function calculateZoomByLatitudeAndMilesPerInch(latitude, milesPerInch) {
  // 1 meter = 0.000621371 miles, 1 inch = 96 pixels
  const metersPerPixel = milesPerInch / (0.000621371 * 96);
  return calculateZoomByLatitudeAndMetersPerPixel(latitude, metersPerPixel);
}

export function calculateZoomByLatitudeAndDesiredMeters(
  latitude,
  desiredMeters,
) {
  // Distance per pixel math https://wiki.openstreetmap.org/wiki/Zoom_levels
  const metersPerPixel = desiredMeters / 256; // 256px wide for tiles

  // https://gis.stackexchange.com/questions/7430/what-ratio-scales-do-google-maps-zoom-levels-correspond-to
  return Math.log2(
    (156543.03392 * Math.cos((latitude * Math.PI) / 180)) / metersPerPixel,
  );
}

export function metersToMiles(meters) {
  return meters * 0.000621371192;
}

/**
 * The LocationControl adds a control to the map that locates the user on
 * the map.
 * This constructor takes the control DIV as an argument.
 * @param {number} index position number into the controls property of the map
 * @param {function} onClick event function that triggers when the control is clicked
 * @constructor controlDiv
 */
export function LocationControl(index, onClick) {
  const controlDiv = document.createElement("div");

  controlDiv.index = index;

  // Set CSS for the control border.
  const controlUI = document.createElement("div");
  controlUI.style.backgroundColor = "#fff";
  controlUI.style.height = "30px";
  controlUI.style.alignItems = "center";
  controlUI.style.width = "30px";
  controlUI.style.borderRadius = "2px";
  controlUI.style.boxShadow = "0 0 5px 6px rgba(0,0,0,.08 )";
  controlUI.style.cursor = "pointer";
  controlUI.title = "Your location";
  controlDiv.appendChild(controlUI);

  // Set CSS for the control interior.
  const controlIcon = document.createElement("img");
  controlIcon.style.position = "absolute";
  controlIcon.style.width = "16px";
  controlIcon.style.height = "17px";
  controlIcon.style.textAlign = "center";
  controlIcon.src = "/assets/icons/crosshairs-gps.svg";
  // controlIcon.src = '/assets/icons/baseline-my_location-24px.svg';
  controlUI.appendChild(controlIcon);

  // Setup the click event listeners: simply set the map to Chicago.
  controlUI.addEventListener("click", onClick);

  return controlDiv;
}

export function ZoomControl(index, onZoomInClick, onZoomOutClick) {
  const controlDiv = document.createElement("div");

  controlDiv.index = index;

  // Set CSS for the control border.
  const controlUI = document.createElement("div");
  controlUI.className = "zoomControl";
  controlUI.style.backgroundColor = "#fff";
  controlUI.style.borderRadius = "2px";
  controlUI.style.boxShadow = "0 0 5px 6px rgba(0,0,0,.08 )";
  controlUI.style.cursor = "pointer";
  controlUI.style.marginRight = "10px";
  controlUI.style.marginBottom = "6px";
  controlDiv.appendChild(controlUI);

  controlUI.appendChild(ZoomInControl(index, onZoomInClick));
  controlUI.appendChild(Divider());
  controlUI.appendChild(ZoomOutControl(index, onZoomOutClick));

  return controlDiv;
}

export function Divider() {
  const controlDiv = document.createElement("div");
  controlDiv.className = "d-flex justify-content-center";

  // Set CSS for the control border.
  const controlUI = document.createElement("div");
  controlUI.style.backgroundColor = "#e6e6e6";
  controlUI.style.height = "1px";
  controlUI.style.width = "20px";
  controlDiv.appendChild(controlUI);

  return controlDiv;
}

/**
 * The ZoomInControl adds a control to the map that zooms in the map.
 * This constructor takes the control DIV as an argument.
 * @param {number} index position number into the controls property of the map
 * @param {function} onClick event function that triggers when the control is clicked
 * @constructor controlDiv
 */
export function ZoomInControl(index, onClick) {
  const controlDiv = document.createElement("div");

  controlDiv.index = index;

  // Set CSS for the control border.
  const controlUI = document.createElement("div");
  controlUI.style.backgroundColor = "#fff";
  controlUI.style.height = "30px";
  controlUI.style.width = "30px";
  // controlUI.style.borderRadius = '2px';
  // controlUI.style.boxShadow = '0 0 5px 6px rgba(0,0,0,.08 )';
  controlUI.style.cursor = "pointer";
  // controlUI.style.marginRight = '8px';
  // controlUI.style.marginBottom = '6px';
  controlUI.title = "Zoom In";
  controlDiv.appendChild(controlUI);

  // Set CSS for the control interior.
  const controlIcon = document.createElement("img");
  controlIcon.style.position = "absolute";
  controlIcon.style.left = 0;
  controlIcon.style.width = "14px";
  controlIcon.style.height = "15px";
  controlIcon.style.margin = "8px 8px";
  // controlIcon.src = '/assets/icons/plus.svg';
  // controlIcon.src = '/assets/icons/baseline-add-24px.svg';
  controlIcon.src = "/assets/icons/zoom-in.svg";
  controlUI.appendChild(controlIcon);

  // Setup the click event listeners: simply set the map to Chicago.
  controlUI.addEventListener("click", onClick);

  return controlDiv;
}

/**
 * The ZoomInControl adds a control to the map that zooms in the map.
 * This constructor takes the control DIV as an argument.
 * @param {number} index position number into the controls property of the map
 * @param {function} onClick event function that triggers when the control is clicked
 * @constructor controlDiv
 */
export function ZoomOutControl(index, onClick) {
  const controlDiv = document.createElement("div");

  controlDiv.index = index;

  // Set CSS for the control border.
  const controlUI = document.createElement("div");
  controlUI.style.backgroundColor = "#fff";
  controlUI.style.height = "30px";
  controlUI.style.width = "30px";
  // controlUI.style.borderRadius = '2px';
  // controlUI.style.boxShadow = '0 0 5px 6px rgba(0,0,0,.08 )';
  controlUI.style.cursor = "pointer";
  // controlUI.style.marginRight = '8px';
  // controlUI.style.marginBottom = '10px';
  controlUI.title = "Zoom Out";
  controlDiv.appendChild(controlUI);

  // Set CSS for the control interior.
  const controlIcon = document.createElement("img");
  controlIcon.style.position = "absolute";
  controlIcon.style.left = 0;
  controlIcon.style.width = "14px";
  controlIcon.style.height = "15px";
  controlIcon.style.margin = "8px 8px";
  // controlIcon.src = '/assets/icons/minus.svg';
  // controlIcon.src = '/assets/icons/baseline-remove-24px.svg';
  controlIcon.src = "/assets/icons/zoom-out.svg";
  controlUI.appendChild(controlIcon);

  // Setup the click event listeners: simply set the map to Chicago.
  controlUI.addEventListener("click", onClick);

  return controlDiv;
}
