// Dependencies
import { Box, Snackbar } from "@mui/material";

import MapTableContainerBase from "components/map-table-container/map-table-container.component";
import SwitchViewsBase from "components/switch-views/switch-views.component";
import MapContainerBase from "components/map-container/map-container.component";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";

const MapContainer = MapContainerBase;

const MapTableContainer = MapTableContainerBase;
const SwitchViews = SwitchViewsBase;

export default {
  Box,
  Snackbar,
  MapTableContainer,
  SwitchViews,
  MapContainer,
  ProgressIndicator,
};
