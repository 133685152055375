/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState, useEffect } from "react";
import SC from "./export-table.styles";

export const ExportFeature = ({ id, open, anchorEl, onButtonClick }: any) => {
  const [value, setValue] = useState("zip");
  const [exportBool, setExportBool] = useState(false);

  useEffect(() => {
    setExportBool(open);
  }, [open]);

  const options = [
    {
      key: "default",
      label: "Select File",
      value: "default",
    },
    {
      key: "zip",
      label: "Zip File Format",
      value: "zip",
    },
  ];

  const onSelectedValue = (event: any) => {
    if (event.target.value !== "default") {
      setValue(event.target.value);
    }
  };

  const onExportButton = () => {
    setExportBool(false);
    onButtonClick(value);
  };

  return (
    <SC.Popper
      id={id}
      open={exportBool}
      anchorEl={anchorEl}
      style={{ zIndex: 10 }}
      placement="top-start"
    >
      <SC.Paper>
        <SC.Label> Export </SC.Label>
        <SC.SelectField
          variant="standard"
          labelId="demo-customized-select-label"
          id="demo-customized-select2"
          value={value}
          onChange={onSelectedValue}
        >
          {options.map((item) => (
            <SC.MenuItem key={item.key} value={item.value}>
              <em>{item.label}</em>
            </SC.MenuItem>
          ))}
        </SC.SelectField>
        <SC.Button onClick={onExportButton}> Export </SC.Button>
      </SC.Paper>
    </SC.Popper>
  );
};

export default ExportFeature;
