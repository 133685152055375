import { TableColumnDef } from "components/table/table.component";
import { AccessorFn } from "@tanstack/react-table";
import _ from "lodash";
import { GraIndividualReturnExemptionFragment } from "graphql/hasura/types-and-hooks";

export type IndividualReturnExemptionSchema = Omit<
  GraIndividualReturnExemptionFragment,
  "__typename"
>;

export const INDIVIDUAL_RETURN_EXEMPTION_SCHEMA_MAP: Record<
  keyof IndividualReturnExemptionSchema,
  "string" | "number" | "Date"
> = {
  Amount: "number",
  Basis: "string",
  Exemptionid: "string",
  Incometype: "string",
  Returnid: "string",
  Returnsbasetin: "string",
  Taxliability: "string",
  Taxpayerfname: "string",
  Taxpayerlname: "string",
  Taxpayermname: "string",
  Taxpayertin: "number",
  Taxrate: "string",
};

export const getAccessorType = (
  accessor:
    | keyof IndividualReturnExemptionSchema
    | AccessorFn<IndividualReturnExemptionSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return INDIVIDUAL_RETURN_EXEMPTION_SCHEMA_MAP[accessor];
};

export const tableColumns: TableColumnDef<IndividualReturnExemptionSchema>[] = [
  {
    header: "Exemption ID",
    accessorKey: "Exemptionid",
    align: "left",
    format: "string",
  },
  {
    header: "Return ID",
    accessorKey: "Returnid",
    align: "left",
    format: "string",
  },
  {
    header: "Taxpayer First Name",
    accessorKey: "Taxpayerfname",
    align: "left",
    format: "string",
  },
  {
    header: "Taxpayer Last Name",
    accessorKey: "Taxpayerlname",
    align: "left",
    format: "string",
  },
  {
    header: "Taxpayer Middle Name",
    accessorKey: "Taxpayermname",
    align: "left",
    format: "string",
  },
  {
    header: "Taxpayer TIN",
    accessorKey: "Taxpayertin",
    align: "left",
    format: "string",
  },
  {
    header: "Income Type",
    accessorKey: "Incometype",
    align: "right",
    format: "number",
  },
  {
    header: "Amount",
    accessorKey: "Amount",
    align: "right",
    format: "number",
  },
  {
    header: "Tax Rate",
    accessorKey: "Taxrate",
    align: "right",
    format: "number",
  },
  {
    header: "Basis",
    accessorKey: "Basis",
    align: "right",
    format: "number",
  },
  {
    header: "Tax Liability",
    accessorKey: "Taxliability",
    align: "right",
    format: "number",
  },
];
