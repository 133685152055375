import React from "react";

// Components
import Breadcrumb from "components/breadcrumb/breadcrumb.component";
import { IndividualTaxPayerTable } from "../individual-tax-payer-table/individual-tax-payer-table.component";

// Assets
import SC from "./individual-tax-payer.styles";

const IndividualTaxPayer: React.FC = () => {
  return (
    <SC.Box pl={2} pr={2} pt={2}>
      <Breadcrumb />
      <IndividualTaxPayerTable
        displayHeader
        displayTitle
      />
    </SC.Box>
  );
};

export default IndividualTaxPayer;
