export type ConfigVariable =
  | "GraphQLServerURL"
  | "GraphQLServerPrivateURL"
  | "HasuraGraphQLServerURL"
  | "HasuraUser"
  | "HasuraAdminSecret"
  | "HasuraSecretSecret"
  | "AwsRegion"
  | "AwsAccessKeyId"
  | "AwsSecretAccessKey"
  | "AwsBucket";

const {
  REACT_APP_GRAPHQL_SERVER_URL,
  REACT_APP_GRAPHQL_SERVER_PRIVATE_URL,
  REACT_APP_HASURA_GRAPHQL_SERVER_URL,
  REACT_APP_HASURA_GRAPHQL_ADMIN_SECRET,
  REACT_APP_HASURA_SECRET_SECRET,
  REACT_APP_HASURA_GRAPHQL_USER_ID,
  REACT_APP_AWS_REGION,
  REACT_APP_AWS_S3_ACCESS_KEY_ID,
  REACT_APP_AWS_S3_SECRET_KEY,
  REACT_APP_AWS_S3_BUCKET_NAME,
} = process.env;

class GlobalConfig {
  private static variables: {
    [x in ConfigVariable]?: string;
  } = {};

  static get(variable: ConfigVariable): string | undefined {
    const { variables } = GlobalConfig;

    if (!variables[variable]) {
      switch (variable) {
        case "GraphQLServerURL":
          variables[variable] = REACT_APP_GRAPHQL_SERVER_URL;
          break;

        case "GraphQLServerPrivateURL":
          variables[variable] = REACT_APP_GRAPHQL_SERVER_PRIVATE_URL;
          break;

        case "HasuraGraphQLServerURL":
          variables[variable] = REACT_APP_HASURA_GRAPHQL_SERVER_URL;
          break;

        case "HasuraUser":
          variables[variable] = REACT_APP_HASURA_GRAPHQL_USER_ID;
          break;

        case "HasuraAdminSecret":
          variables[variable] = REACT_APP_HASURA_GRAPHQL_ADMIN_SECRET;
          break;
        case "HasuraSecretSecret":
          variables[variable] = REACT_APP_HASURA_SECRET_SECRET;
          break;

        case "AwsRegion":
          variables[variable] = REACT_APP_AWS_REGION;
          break;

        case "AwsAccessKeyId":
          variables[variable] = REACT_APP_AWS_S3_ACCESS_KEY_ID;
          break;

        case "AwsSecretAccessKey":
          variables[variable] = REACT_APP_AWS_S3_SECRET_KEY;
          break;

        case "AwsBucket":
          variables[variable] = REACT_APP_AWS_S3_BUCKET_NAME;
          break;

        default:
          throw new Error("Environment variable not implemented");
      }
    }

    return variables[variable];
  }
}

export default GlobalConfig;
