import { TableColumnDef } from "components/table/table.component";
import { AccessorFn } from "@tanstack/react-table";
import _ from "lodash";
import {
  GraRegAssociationFragment,
  Rtcs_Gm_Dev_Ph_Gra_RegAssociation,
} from "graphql/hasura/types-and-hooks";
import { FilterAccessorValue } from "pages/reports/utils";

export type GraRegisterAssociationSchema = Omit<
  GraRegAssociationFragment,
  "__typename"
>;

export interface RegisteredAssociationsTableFilters {}

export const DEFAULT_FILTER_ACCESSOR: Record<
  keyof RegisteredAssociationsTableFilters,
  FilterAccessorValue<Rtcs_Gm_Dev_Ph_Gra_RegAssociation>
> = {};

export const GRA_REGISTER_ASSOCIATION_SCHEMA_MAP: Record<
  keyof GraRegisterAssociationSchema,
  "string" | "number" | "Date"
> = {
  Associateaddress: "string",
  Associateid: "string",
  Associatename: "string",
  Associaterelation: "number",
  Assoctin: "string",
  Delflag: "string",
  Designation: "string",
  Din: "string",
  Noshare: "number",
  Profitshare: "number",
  Shareheld: "number",
  Taxpayerid: "string",
  Tin: "string",
};

export const getAccessorType = (
  accessor:
    | keyof GraRegisterAssociationSchema
    | AccessorFn<GraRegisterAssociationSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;
  return GRA_REGISTER_ASSOCIATION_SCHEMA_MAP[accessor];
};

export const tableColumns: TableColumnDef<GraRegisterAssociationSchema>[] = [
  {
    header: "Associate ID",
    accessorKey: "Associateid",
    align: "left",
    format: "string",
  },
  {
    header: "TaxPayer ID",
    accessorKey: "Taxpayerid",
    align: "left",
    format: "string",
  },
  {
    header: "TIN",
    accessorKey: "Tin",
    align: "left",
    format: "string",
  },
  {
    header: "DIN",
    accessorKey: "Din",
    align: "left",
    format: "string",
  },
  {
    header: "Associate Name",
    accessorKey: "Associatename",
    align: "left",
    format: "string",
  },
  {
    header: "Associate Relation",
    accessorKey: "Associaterelation",
    align: "right",
    format: "number",
  },
  {
    header: "Associate Address",
    accessorKey: "Associateaddress",
    align: "left",
    format: "string",
  },
  {
    header: "Number of Shares",
    accessorKey: "Noshare",
    align: "right",
    format: "number",
  },
  {
    header: "Shares Held",
    accessorKey: "Shareheld",
    align: "right",
    format: "number",
  },
  {
    header: "Associate TIN",
    accessorKey: "Assoctin",
    align: "left",
    format: "string",
  },
  {
    header: "Profit Share",
    accessorKey: "Profitshare",
    align: "right",
    format: "number",
  },
  {
    header: "Designation",
    accessorKey: "Designation",
    align: "left",
    format: "string",
  },
  {
    header: "Del Flag",
    accessorKey: "Delflag",
    align: "left",
    format: "string",
  },
];
