// Dependencies
import React from "react";
import { useLocation } from "react-router-dom";
import {
  Home as HomeIcon,
  PeopleAlt as PeopleAltIcon,
  Storefront as StoreFrontIcon,
  House as HouseIcon,
  Folder as FolderIcon,
} from "@mui/icons-material";

// Components
import {
  AppPermissionValidator,
  APP_PERMISSION,
} from "components/app-permission-validator/app-permission-validator.component";

// Assets
import SC from "./navigation.styles";

const Navigation: React.FC = () => {
  const location = useLocation();
  const urlLocation = location.pathname.split("/");

  return (
    <SC.Navigation position="static">
      <SC.Tabs value={0} aria-label="simple tabs example">
        <AppPermissionValidator appPermission={APP_PERMISSION.HOME_VIEW}>
          <SC.Tab
            isActive={urlLocation[1] === "home"}
            icon={<HomeIcon />}
            to="/home"
            label={<span>Home</span>}
          />
        </AppPermissionValidator>

        <AppPermissionValidator appPermission={APP_PERMISSION.INDIVIDUALS_VIEW}>
          <SC.Tab
            isActive={urlLocation[1] === "individuals"}
            icon={<PeopleAltIcon />}
            to="/individuals"
            label={<span>Individuals</span>}
          />
        </AppPermissionValidator>

        <AppPermissionValidator
          appPermission={APP_PERMISSION.NON_INDIVIDUALS_VIEW}
        >
          <SC.Tab
            isActive={urlLocation[1] === "corporates"}
            icon={<StoreFrontIcon />}
            to="/corporates"
            label={<span>Corporates</span>}
          />
        </AppPermissionValidator>

        {/* <AppPermissionValidator
          appPermission={APP_PERMISSION.NON_INDIVIDUALS_VIEW}
        >
          <SC.Tab
            isActive={urlLocation[1] === "non-individuals"}
            icon={<StoreFrontIcon />}
            to="/non-individuals"
            label={<span>Non-Individuals</span>}
          />
        </AppPermissionValidator>

        <AppPermissionValidator appPermission={APP_PERMISSION.PROPERTIES_VIEW}>
          <SC.Tab
            isActive={urlLocation[1] === "properties"}
            icon={<HouseIcon />}
            to="/properties"
            label={<span>Properties</span>}
          />
        </AppPermissionValidator> */}
        <AppPermissionValidator appPermission={APP_PERMISSION.REPORTS}>
          <SC.Tab
            isActive={urlLocation[1] === "reports"}
            icon={<FolderIcon />}
            to="/reports"
            label={<span>Reports</span>}
          />
        </AppPermissionValidator>
      </SC.Tabs>
    </SC.Navigation>
  );
};

export default Navigation;
