import React from "react";

// Components
import { TradeLicenseRegisterTable } from "pages/reports/bcc/trade-license-register-table/trade-license-register-table.component";
import { PropertyRatesRegisterTable } from "pages/reports/bcc/property-rates-register-table/property-rates-register-table.component";

// Assets
import AccordionList from "components/accordion-list/accordion-list.component";

// Hooks
import useTablesLoaded from "hooks/useTablesLoaded";

interface Filters {
  address: string;
  businessName: string;
  email: string;
  firstName: string;
  idNumber: string;
  phoneNumber: string;
  plusCode: string;
  surname: string;
}

interface BccTabProps {
  defaultFilters?: Filters;
  initialRowsPerPage?: number;
  maxHeight?: number;
  noDataComponent?: JSX.Element;
}

export const BccTab: React.FC<BccTabProps> = React.memo(
  ({ defaultFilters, initialRowsPerPage, maxHeight, noDataComponent }) => {
    const { tablesLoaded, renderTable } = useTablesLoaded({
      tradeLicenseRegister: false,
    });
    const reportSections = [
      {
        title: "Banjul City Council",
        accordions: [
          {
            content: (
              <PropertyRatesRegisterTable
                defaultFilters={defaultFilters}
                initialRowsPerPage={initialRowsPerPage}
                maxHeight={maxHeight}
                noDataComponent={noDataComponent}
                //onLoaded={renderTable.tradeLicenseRegister}
                persistenceId="5a0359f4-9c1d-44b9-ac4a-d13498be61c1"
              />
            ),
            subtitle: "BCC Property Rates Register",
          },
          {
            //shouldRenderBody: tablesLoaded.tradeLicenseRegister,
            content: (
              <TradeLicenseRegisterTable
                defaultFilters={defaultFilters}
                initialRowsPerPage={initialRowsPerPage}
                maxHeight={maxHeight}
                noDataComponent={noDataComponent}
                persistenceId="2cb81423-0ea4-497a-8814-c14b6c18d305"
              />
            ),
            subtitle: "Banjul Trade License Data",
          },
        ],
      },
    ];

    return <AccordionList defaultExpanded sections={reportSections} />;
  },
);
