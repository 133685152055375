import React from "react";

// Components
import { CorporatesTable } from "pages/reports/grc/corporates/table/corporates-table.component";

// Assets
import AccordionList from "components/accordion-list/accordion-list.component";

// Hooks
import useTablesLoaded from "hooks/useTablesLoaded";
import OfficersOwnersTable from "pages/reports/grc/officers-owners/table/officers-owners-table.component";

interface Filters {
  address: string;
  businessName: string;
  email: string;
  firstName: string;
  idNumber: string;
  phoneNumber: string;
  plusCode: string;
  surname: string;
}

interface GrcTabProps {
  defaultFilters?: Filters;
  initialRowsPerPage?: number;
  maxHeight?: number;
  noDataComponent?: JSX.Element;
}

export const GrcTab: React.FC<GrcTabProps> = React.memo(
  ({ defaultFilters, initialRowsPerPage, maxHeight, noDataComponent }) => {
    const { tablesLoaded, renderTable } = useTablesLoaded({
      Corporates: false,
      OfficersOwners: false,
    });
    const reportSections = [
      {
        title: "Gambia Registrar of Companies",
        accordions: [
          {
            content: (
              <CorporatesTable
                defaultFilters={defaultFilters}
                initialRowsPerPage={initialRowsPerPage}
                maxHeight={maxHeight}
                noDataComponent={noDataComponent}
                onLoaded={renderTable.Corporates}
                persistenceId="483d646c-e2bd-449c-829a-43c91dfef5fb"
              />
            ),
            subtitle: "Corporates",
          },
          {
            shouldRenderBody: tablesLoaded.Corporates,
            content: (
              <OfficersOwnersTable
                defaultFilters={defaultFilters}
                initialRowsPerPage={initialRowsPerPage}
                maxHeight={maxHeight}
                noDataComponent={noDataComponent}
                onLoaded={renderTable.OfficersOwners}
                persistenceId="bd8efad1-3fa5-419b-b29f-9be4990f0a89"
              />
            ),
            subtitle: "Officers/Owners",
          },
        ],
      },
    ];

    return <AccordionList defaultExpanded sections={reportSections} />;
  },
);
