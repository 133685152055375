// Dependencies
import React, { useCallback } from "react";
// GraphQL
import { ServerDataToLayer } from "rView/home/utils/layer";

// Components
import Breadcrumb from "components/breadcrumb/breadcrumb.component";
import { KanifingPropertiesTable } from "../kanifing-properties-table/kanifing-properties-table.component";

// Assets
import SC from "./kanifing-properties-view.styles";

export const KanifingPropertiesViews: React.FC = React.memo(() => {
  const [rowsPerPageData, setRowsPerPageData] = React.useState<[]>([]);
  const handleOnDataPerPageChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (data: any) => {
      setRowsPerPageData(data);
    },
    [setRowsPerPageData],
  );

  return (
    <>
      <SC.SwitchViews
        LeftView={
          <SC.MapTableContainer>
            <KanifingPropertiesTable
              displayTitle
              onDataPerPageChange={handleOnDataPerPageChange}
            />
          </SC.MapTableContainer>
        }
        leftViewName="Table"
        RightView={
          <SC.MapContainer properties={ServerDataToLayer(rowsPerPageData)} />
        }
        rightViewName="Map"
      />
    </>
  );
});

const KanifingProperties: React.FC = () => {
  return (
    <SC.Box pl={2} pr={2} pt={2}>
      <Breadcrumb />
      <KanifingPropertiesViews />
    </SC.Box>
  );
};

export default KanifingProperties;
